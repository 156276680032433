<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div> 
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
						
						<div class="radios-and-checks">
                                    <div class="heading-top">
                                        <h2>{{ 'INFORMATION ABOUT THE JOB' | translate }}</h2>
                                    </div>
									<div *ngFor="let option1 of filedList;">
										 
										<div class="radios-and-checks-box" *ngIf="static_features[option1].type_of_feature ==6">
											<div class="heading">
												<h2>{{static_features[option1].label}}</h2>
											</div>
											<div class="row">
												<div class="col-6" *ngFor="let opt of static_features[option1].options" >
													<div class="custom-control custom-radio" >
														<input type="radio" [value]="opt.id" id="{{static_features[option1].fld}}{{opt.id}}" name="{{static_features[option1].fld}}" class="form-check-input" formControlName="{{static_features[option1].fld}}" />
														<label class="form-check-label" for="{{static_features[option1].fld}}{{opt.id}}">{{opt.name}}</label>
													</div>
													
												</div>
													<div class="col-6" *ngIf="static_features[option1].doesnotmatter">
												<div class="custom-control custom-radio">
														<input type="radio" [value]="-1" id="{{static_features[option1].fld}}-1" name="{{static_features[option1].fld}}" class="form-check-input" formControlName="{{static_features[option1].fld}}" />
														<label class="form-check-label" for="{{static_features[option1].fld}}-1">{{static_features[option1].doesnotmatter}}</label>
													</div>
												</div>
												
												<div class="col-12" >
													
													<input type="hidden" [ngClass]="(submitted && ((errors && errors[static_features[option1].fld])  || af[static_features[option1].fld].errors )) ? 'is-invalid' : ''" />
													<div *ngIf="submitted && errors && errors[static_features[option1].fld]" class="invalid-feedback">{{errors[static_features[option1].fld][0]}}</div>
													<div *ngIf="submitted && af[static_features[option1].fld].errors"  class="invalid-feedback">
														<div *ngIf="af[static_features[option1].fld].errors.required">
														{{ static_features[option1].label + ' ist erforderlich.' | translate}}
														</div>
													</div>
												</div>
												
												
												
											</div>
                           
										</div>
										 <div class="radios-and-checks-box" *ngIf="static_features[option1].type_of_feature ==5">
											<div class="heading">
												<h2>{{static_features[option1].label}}</h2>
											</div>
											<div class="row">
												<div class="col-6"  *ngFor="let opt of static_features[option1].options">
													<div class="form-check">
														<input type="checkbox" class="form-check-input" [checked]="isChecked(opt.id,static_features[option1].fld)" [value]="opt.id" id="{{static_features[option1].fld}}{{opt.id}}"  class="form-check-input" (change)="onCheckboxChange($event, static_features[option1].fld)"  />
														<label class="form-check-label" for="{{static_features[option1].fld}}{{opt.id}}">{{opt.name}}</label>
													</div>
												</div>
												<div class="col-6"  *ngIf="static_features[option1].doesnotmatter">
													<div class="form-check">
														<input type="checkbox" class="form-check-input" [checked]="isChecked(-1,static_features[option1].fld)" [value]="-1" id="{{static_features[option1].fld}}-1}"  class="form-check-input" (change)="onCheckboxChange($event, static_features[option1].fld)"  />
														<label class="form-check-label" for="{{static_features[option1].fld}}-1">{{static_features[option1].doesnotmatter}}</label>
													</div>
												</div>
												<div class="col-12" >
													<input type="hidden" [ngClass]="(submitted && ((errors && errors[static_features[option1].fld])  || af[static_features[option1].fld].errors )) ? 'is-invalid' : ''" />
													<div *ngIf="submitted && errors && errors[static_features[option1].fld]" class="invalid-feedback">{{errors[static_features[option1].fld][0]}}</div>
													<div *ngIf="submitted && af[static_features[option1].fld].errors"  class="invalid-feedback">
														<div *ngIf="af[static_features[option1].fld].errors.required">{{ static_features[option1].label + ' ist erforderlich.' | translate}}</div>
													</div>
												</div>
											</div>
                           
										</div>
										 
										
									
                                    </div>
									
									
                                    

                                    <div class="theme-btn">
									 
									<button class="back-btn" (click)="goBack()">{{ 'Return' | translate }}</button>&nbsp;&nbsp;<button type="submit" class="btn">{{ 'Further' | translate }}</button>
                                    </div>
									

                            
                        </div>
						
                           
                           
                            
                            
                        </form>