import { NgModule } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserModule,Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CookieService  } from 'ngx-cookie-service'; 
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { NgSelect2Module } from 'ng-select2';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module,RECAPTCHA_BASE_URL  } from 'ng-recaptcha';

import { Ng2SearchPipeModule } from 'ng2-search-filter';

import {AgmCoreModule } from '@agm/core'; 


import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {  GoogleLoginProvider,  FacebookLoginProvider} from 'angularx-social-login';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



import { AuthGuard } from './_guards';
import { DataService } from './dataservice';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './component/header/header.component';
import { AdminHeaderComponent } from './component/admin-header/header.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomeComponent } from './component/home/home.component';
import { EmployerHomeComponent } from './component/ehome/ehome.component';

import { LoginComponent } from './component/login/login.component';
import { SignupComponent } from './component/signup/signup.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { ApplicantVerificationComponent } from './component/applicant-verification/applicant-verification.component';
import { EmployerVerificationComponent } from './component/employer-verification/employer-verification.component';
import { CreateSearchProfileComponent } from './component/create-search-profile/create-search-profile.component';
import { CreateProfileComponent } 		from './component/create-profile/create-profile.component';
import { SearchProfileStep1Component } 	from './component/search-profile-step-1/search-profile-step-1.component';
import { SearchProfileStep2Component } 	from './component/search-profile-step-2/search-profile-step-2.component';
import { SearchProfileStep3Component } 	from './component/search-profile-step-3/search-profile-step-3.component';
import { SearchProfileStep4Component } 	from './component/search-profile-step-4/search-profile-step-4.component';
import { SearchProfileStep5Component } 	from './component/search-profile-step-5/search-profile-step-5.component';
import { SearchProfileStep6Component } 	from './component/search-profile-step-6/search-profile-step-6.component';

import { CreateSearchOrderComponent } from './component/create-search-order/create-search-order.component';
import { SearchOrdersComponent 		} from './component/search-orders/search-orders.component';
import { SearchOrderStep1Component } from './component/search-order-step-1/search-order-step-1.component';
import { SearchOrderStep2Component } from './component/search-order-step-2/search-order-step-2.component';
import { SearchOrderStep3Component } from './component/search-order-step-3/search-order-step-3.component';
import { SearchOrderStep4Component } from './component/search-order-step-4/search-order-step-4.component';
import { SearchOrderStep5Component } from './component/search-order-step-5/search-order-step-5.component'; 
import { SearchOrderStep6Component } from './component/search-order-step-6/search-order-step-6.component'; 


import { DashboardComponent } from './component/dashboard/dashboard.component';
import { CmsComponent } from './component/cms/cms.component';
import { FaqComponent } from './component/faq/faq.component';

import { ContactComponent } from './component/contact/contact.component';
import { PrivacyComponent } from './component/privacy/privacy.component';
 
import { ProfilesComponent } from './component/profiles/profiles.component';


import { CompanyProfileComponent } from './component/company-profile/company-profile.component';
 
import { MatchesComponent } from './component/matches/matches.component';
import { MyProfileComponent } from './component/my-profile/my-profile.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { InterestComponent } from './component/interest/interest.component';
import { ChatComponent } from './component/chat/chat.component';
import { BillingComponent } from './component/billing/billing.component';
import { PaymentDetailComponent } from './component/payment-detail/payment-detail.component';
import { SubscriptionPlanComponent } from './component/subscription-plan/subscription-plan.component';
import { ListingComponent } from './component/listing/listing.component'; 

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import {ChatService} from 'src/app/services/chat.service';
import { AuthCookieService } from 'src/app/authcookie.service';
import * as globalConstant from './global_constant';

@Pipe({
  name: 'splitComma'
})
export class SplitCommaStringPipe implements PipeTransform {
  transform(val:string):any[] {
    var s =  val.split(',');
	var obj = [];
	for (var i = 0; i < s.length; i++) {
		obj.push({id:s[i].trim(),name:s[i].trim(),text:s[i].trim()});                  
	}
	//console.log(obj);
	return obj;
  }
}

 
 

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const config: SocketIoConfig = { url: globalConstant.CHAT_URI, options: {} };
const SocialProviders = [];
if(getCookie('socail') == '1'){
SocialProviders.push({
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '806588988671-ampm7n8n7jhvku1676no0hho3fe4iv4k.apps.googleusercontent.com'
            )
          });
SocialProviders.push({
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('401575841309282')
          });		  
  
}   
 
export class AppService {
  
  constructor() { }
  public load() {
    return (getCookie('accept_cookie')) ? globalConstant.DOMAIN:globalConstant.DOMAIN;
         
  }
} 
 
  
@NgModule({
  declarations: [
	AdminHeaderComponent,
	SidebarComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    EmployerHomeComponent,
     
    ForgotPasswordComponent,
    ResetPasswordComponent,
    
    ApplicantVerificationComponent,
    EmployerVerificationComponent,
    
    CreateProfileComponent,
    CreateSearchProfileComponent,
	SearchProfileStep1Component,
	SearchProfileStep2Component,
	SearchProfileStep3Component,
	SearchProfileStep4Component,
	SearchProfileStep5Component,
	SearchProfileStep6Component,
	CreateSearchOrderComponent ,		
	SearchOrdersComponent ,		
	SearchOrderStep1Component ,
	 SearchOrderStep2Component ,
	SearchOrderStep3Component ,
	SearchOrderStep4Component ,
	SearchOrderStep5Component ,
	SearchOrderStep6Component ,
	
 
	

	
	LoginComponent,
	SignupComponent,
	DashboardComponent,
	SplitCommaStringPipe,
	 CmsComponent,
    FaqComponent,
    ContactComponent,
    PrivacyComponent,
    
    ProfilesComponent,
	ConfirmationDialogComponent,
 
 CompanyProfileComponent,
 
 MatchesComponent,
 MyProfileComponent,
 ChangePasswordComponent,
 InterestComponent,
 ChatComponent,
 BillingComponent,
 PaymentDetailComponent,
 SubscriptionPlanComponent,
 ListingComponent,
	
	
	
	
	
	
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
	HttpClientModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    FormsModule,
	Ng2SearchPipeModule ,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
  	SocialLoginModule,
	TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
    }),
	
    AgmCoreModule.forRoot({  apiKey: 'AIzaSyAQBN61ULQ7xB1wYRHtw6kt2hD36UFVSsk'  ,libraries: ["places"]}), 
	  RecaptchaV3Module,
	  SocketIoModule.forRoot(config),
	  NgSelect2Module,
	   NgbModule

  ],
  providers: [  
	AuthCookieService,
	ChatService,
	CookieService ,
	ConfirmationDialogService,
	{ 
		provide: RECAPTCHA_V3_SITE_KEY, 
		useValue: '6LffXQgcAAAAAL7rhKctPs05hV0ZQmXwDZJcs9vv' ,
		//useFactory: (appService: AppService) => () => appService.load(),
		//deps: [AppService],
	},
	{ 
		provide: RECAPTCHA_BASE_URL, 
		//useValue: '6LffXQgcAAAAAL7rhKctPs05hV0ZQmXwDZJcs9vv' ,
		useFactory:RecaptchLoaderFactory,
		//deps: [AppService],
	},
	 
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: SocialProviders
      } as SocialAuthServiceConfig,
    },
	
	Title,
    AuthGuard,
    DataService,
	 
    DatePipe],
	entryComponents: [ ConfirmationDialogComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	
    return new TranslateHttpLoader(http,globalConstant.DOMAIN + 'lang/' ,"");
}
export function RecaptchLoaderFactory() {
	if(getCookie('recaptcha') == '1'){
		return '';
	} else {
		
    return globalConstant.DOMAIN  + 'gc' ;
	}
}
