import { Component, OnInit} from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
	APP_URI = globalConstant.APP_URI;
	loading = false;
	submitted = false;
	stepData:any;
	userData:any;
	constructor(private mainService: MainService,  private toastr: ToastrService,private router: Router, private confirmationDialogService: ConfirmationDialogService) { }
	cancelPlan(id){
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Möchten Sie diesen Plan wirklich stornieren?','Bestätigen','Später')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let mst =JSON.parse(localStorage.getItem('authUser')).mst;
						this.loading = true;
						this.mainService.cancel_plan(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.stepData	= response.data;
									var res = {};
									res['data'] 	= response.data;
									res['token'] 	= token;
									res['mst'] 		= mst;
									localStorage.setItem('authUser', JSON.stringify(res));
									this.toastr.success('Plan erfolgreich storniert');
									window.location.reload();
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
		
		
		
	}
	 
	downloadFile(data) {
		  const blob = new Blob([data], { type: 'application/pdf' });
		  const url= window.URL.createObjectURL(blob);
		  window.open(url);
	}
	view_invoice(f){	

	let token =JSON.parse(localStorage.getItem('authUser')).token;
	this.loading = true;
	this.mainService.view_invoice(token,f).subscribe(
			response => { 
			this.loading = false;
			if(response.status == 'success'){	
			
				const a = document.createElement('a')
				a.href = globalConstant.APP_URI + 'uploads/temp_invoices/' +  response.data.file_name + '.pdf'
				a.download = response.data.invoice_number + '.pdf';
				a.target = '_blank';
				a.click();
				//const objectUrl = window.URL.createObjectURL(globalConstant.APP_URI + 'uploads/temp_invoices/' + response.data.file_name + '.pdf')
				// window.URL.revokeObjectURL(objectUrl);
			
				 
			} else {
				this.toastr.error(response.msg); 
			}
			},
			(error) => {                              		    
				this.loading = false;
			});
	

		return false;
	//	return this.http.get(globalConstant.APP_URI + 'uploads/service_providers/' + f, { responseType: 'blob' });
		//const a = document.createElement('a')
       // const objectUrl = window.URL.createObjectURL(globalConstant.APP_URI + 'uploads/service_providers/' + f)
       // a.href = globalConstant.APP_URI + 'uploads/temp_invoices/' + f
       // a.download = 'document.pdf';
       // a.click();
      //  window.URL.revokeObjectURL(objectUrl);
   }
 
 ngOnInit(): void {
	this.userData = JSON.parse(localStorage.getItem('authUser')).data;
	let token =JSON.parse(localStorage.getItem('authUser')).token;
	this.loading = true;
	this.mainService.billing(token).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){	 
					this.stepData	= response.data;
					
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
		
		
  }

}
