<main class="dash-main">
    <!-- header setion start -->
<app-admin-header></app-admin-header>
<!-- header setion end -->
<!-- side navbaar section start-->

<app-sidebar></app-sidebar>
 <!-- side navbaar section end-->
<!-- min page box  start-->
 <div class="main-page-box">
    <div class="container-fluid">
	
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
	<section class="new-matches">
                    <div class="dashboard-box ">
                      <div class="form-steps">
                        <section class="signup-step-container">
                            <div class="container">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-12 p-0">
                                        <div class="wizard">
                                            <div class="wizard-inner">
                                                <div class="connecting-line"></div>
                                                <ul class="nav nav-tabs" role="tablist">
                                                    <li role="presentation" [ngClass]="(step > 1) ?  'disabled completed' : ((step==1) ? 'active':'disabled')" >
                                                        <a href="#step1" data-bs-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span class="round-tab"><span class="count">1</span>  <span><img class="checked" src="../assets/images/check-white.png" alt="check-white"></span></span> <i>{{"Step 1" | translate }}</i></a>
                                                    </li>
                                                    <li role="presentation" [ngClass]="(step > 2) ?  'disabled completed' : ((step==2) ? 'active':'disabled')">
                                                        <a href="#step2" data-bs-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span class="round-tab"><span class="count">2</span>  <span><img class="checked" src="../assets/images/check-white.png" alt="check-white"></span></span> <i>{{"Step 2" | translate }}</i></a>
                                                    </li>
                                                    <li role="presentation" [ngClass]="(step > 3) ?  'disabled completed' : ((step==3) ? 'active':'disabled')">
                                                        <a href="#step3" data-bs-toggle="tab" aria-controls="step3" role="tab"><span class="round-tab">3</span> <i>{{"Step 3" | translate }}</i></a>
                                                    </li>
                                                    <li role="presentation" [ngClass]="(step > 4) ?  'disabled completed' : ((step==4) ? 'active':'disabled')">
                                                        <a href="#step4" data-bs-toggle="tab" aria-controls="step4" role="tab"><span class="round-tab">4</span> <i>{{"Step 4" | translate }}</i></a>
                                                    </li>
                                                    <li role="presentation" [ngClass]="(step > 5) ?  'disabled completed' : ((step==5) ? 'active':'disabled')">
                                                        <a href="#step5" data-bs-toggle="tab" aria-controls="step5" role="tab"><span class="round-tab">5</span> <i>{{"Step 5" | translate }}</i></a>
                                                    </li> 
												<li role="presentation" [ngClass]="(step > 6) ?  'disabled completed' : ((step==6) ? 'active':'disabled')">
                                                        <a href="#step5" data-bs-toggle="tab" aria-controls="step5" role="tab"><span class="round-tab">6</span> <i>{{"Step 6" | translate }}</i></a>
                                                    </li> 													
                                                </ul>
                                                <div class="tab-content dash-bx-pad pt-0" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="step1" role="tabpanel" aria-labelledby="step1">


                                                        
                                                                                    <div class="login-top">
                                            <div class="login-form">
                                                <div class="form-field">
                                                <div class="d-flex justify-content-center spiner-top" *ngIf="loading">
                                                    <div class="loader-wrapper">
                                                        <div class="loader">
                                                            <img src="../../../assets/images/logo-loader.png" alt="" />
                                                        </div>

                                                    </div>
                                                </div>
<search-profile-step-1 [formData]="stepData"  (setStepData)='setStepData($event)' (setPid)='setPid($event)'  (setPStep)='setPStep($event)'  [pId]="pid" *ngIf="step==1 && !loading"></search-profile-step-1>
<search-profile-step-2 [formData]="stepData"  (setStepData)='setStepData($event)' (setPid)='setPid($event)'  (setPStep)='setPStep($event)'  [pId]="pid" *ngIf="step==2 && !loading"></search-profile-step-2>
<search-profile-step-3 [formData]="stepData"  (setStepData)='setStepData($event)' (setPid)='setPid($event)'  (setPStep)='setPStep($event)'  [pId]="pid" *ngIf="step==3 && !loading"></search-profile-step-3>
<search-profile-step-4 [formData]="stepData"  (setStepData)='setStepData($event)' (setPid)='setPid($event)'  (setPStep)='setPStep($event)'  [pId]="pid" *ngIf="step==4 && !loading"></search-profile-step-4>
<search-profile-step-5 [formData]="stepData"  (setStepData)='setStepData($event)' (setPid)='setPid($event)'  (setPStep)='setPStep($event)'  [pId]="pid" *ngIf="step==5 && !loading"></search-profile-step-5>
<search-profile-step-6 [formData]="stepData"  (setStepData)='setStepData($event)' (setPid)='setPid($event)'  (setPStep)='setPStep($event)'  [pId]="pid" *ngIf="step==6 && !loading"></search-profile-step-6>
   
                        
                    </div>
                </div>
            </div>
       
                                                    </div>
                                                     
												</div>
                                                  
                                                    
                                                 

                                                  
                                            </div>
                        
                                        </div>
                                    </div>
                                </div>
                             </div>
                            
                        </section>
                      </div>
                </div>
                </section>
				
				
	
 

</div>
</div>
<!-- min page box  end-->
</main>
