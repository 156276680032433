import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MainService } from 'src/app/main.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqContent:any;
  faqCat:any;
  faqCat1:any;
  actf=0;
  loading = true;
  constructor(
    private titleService:Title,
    private mainService: MainService, 
    private router: Router, 
  ) {
    this.titleService.setTitle("Faqs"); 
  }
  changeAct(ind){
	  this.actf=ind;
  }

  ngOnInit() {
    this.loading=true;  
    this.mainService.getFaqs().subscribe(res => {
      this.faqContent = res.data;   
      this.faqCat = res.categories;   
      this.faqCat1 = res.categories;   
      this.loading=false;  
    },
        (error) => {                               
           
          
		   this.loading = false;
    
           
        });
  }

}
