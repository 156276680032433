import { Component, OnInit,EventEmitter, Output} from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { CookieService  } from 'ngx-cookie-service'; 
import { AuthCookieService } from 'src/app/authcookie.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loading = false;
  checkCookie:any;
  @Output() setAcceptCookie = new EventEmitter();	
  constructor(private mainService: MainService,  private toastr: ToastrService,private router: Router, private confirmationDialogService: ConfirmationDialogService,private cookies: CookieService,private authCookieService:AuthCookieService) { }
  
  showPopup(slug){
	  this.loading=true;
	  let that = this;
	   this.mainService.getCmsContent(slug).subscribe(res => {
		   if(res.status == 'success'){
			that.confirmationDialogService.confirm(res.data.title, res.data.body,'Close','','lg');
		   }
			this.loading=false;  
    });
	return false;
	  
  }
  accept_cookie(){
	  
	  this.cookies.set('socail','1');
	  this.cookies.set('media','1');
	  this.cookies.set('map','1');
	  this.cookies.set('recaptcha','1');
	  this.cookies.set('accept_cookie','1');
	  this.checkCookie = true;
	  //this.setAcceptCookie.emit(3);
	  window.location.reload(true);
  }
	decline_cookie(){
	  //window.location.reload(true);
	  
	  this.cookies.set('accept_cookie','0');
	  this.checkCookie = true;
	  this.authCookieService.sendClickEvent();
	   
  }

  ngOnInit(): void {
	  this.checkCookie = this.cookies.get('accept_cookie');
	   if(this.cookies.check('socail') == false) this.cookies.set('socail','0');
	  if(this.cookies.check('media') == false) this.cookies.set('media','0');
	  if(this.cookies.check('map') == false) this.cookies.set('map','0');
	  if(this.cookies.check('recaptcha') == false) this.cookies.set('recaptcha','0');
	  
	  
	 
  }

}
