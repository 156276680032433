<!-- step1 -->
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="sign-auth-form" style="background-image: url(assets/images/appli-sign-up.jpg);" >
    <div class="row">
        <div class="col-lg-6">
            <div class="login-side-image">
                <div class="image-tag-top">
                    <div class="img-tag">
                        <h2>{{ 'Company profile' | translate }}</h2>
                        <p>{{ 'Create a company profile and find suitable candidates for your company today.' | translate }}</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="login-top">
                <div class="login-form steps-width">
                    <div class="form-field">
                        <div class="head reg-head">
                            <h2>{{ 'Get started now' | translate }}</h2>
                
                            <h4>{{ 'Enter your contact information.' | translate }}</h4>
                        </div>
                        
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">



                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="text"  [ngClass]="(submitted && ((errors && errors.first_name)  || af.first_name.errors )) ? 'form-control is-invalid' : 'form-control'"
                                           formControlName="first_name"   placeholder="{{ 'Company name' | translate }}">
										 
								<div *ngIf="submitted && errors && errors.first_name" class="invalid-feedback">{{errors.first_name[0]}}</div>
								<div *ngIf="submitted && af.first_name.errors"  class="invalid-feedback">
									 <div *ngIf="af.first_name.errors.required">{{ 'Company name is required!' | translate }}</div>
									  
								</div>
										
									</div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <textarea type="text" formControlName="description" placeholder="{{ 'Company letter' | translate }}"  [ngClass]="(submitted && ((errors && errors.description)  || af.description.errors )) ? 'form-control is-invalid' : 'form-control'"></textarea>
										 
								<div *ngIf="submitted && errors && errors.description" class="invalid-feedback">{{errors.description[0]}}</div>
								<div *ngIf="submitted && af.description.errors"  class="invalid-feedback">
									 <div *ngIf="af.description.errors.required">{{ 'Company description required!' | translate }}</div>
									  
								</div>
										
									</div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="upload-pdf">
									<input formcontrolname="logo" type="file" id="logo"  accept='image/*'  class="form-control" hidden=""  (change)="onFileChange($event,'logo')">
									<label for="logo">
                                        <svg aria-hidden="true" focusable="false" *ngIf="lg == false" data-prefix="far" data-icon="image" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-image fa-w-16 fa-2x"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z" class=""></path></svg>
										<img  [src]="imgURL"  width="120" style="max-height:69px;" *ngIf="lg">
										</label>
                                    </div>
                                <div class="upload-img">
    
    
    
                                    <label for="profile_pic">
                                        <a class="btn-theme " for="profile_pic1">
                                         {{'Upload Pdf Or video' | translate}}</a>
                                    </label>

                                    <input formcontrolname="image" type="file" id="profile_pic"  accept='.pdf,.mp4'  class="form-control" hidden=""  (change)="onFileChange($event,'video')">
                                </div>

</div>

                                </div>






                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <textarea type="text"  [ngClass]="(submitted && ((errors && errors.address)  || af.address.errors )) ? 'form-control is-invalid' : 'form-control'"   formControlName="address" placeholder="{{ 'address' | translate }}"></textarea>
											<div *ngIf="submitted && errors && errors.address" class="invalid-feedback">{{errors.address[0]}}</div>
										<div *ngIf="submitted && af.address.errors"  class="invalid-feedback">
											 <div *ngIf="af.address.errors.required">{{ 'Address is required.' | translate }} </div>
											  
										</div>
                                    </div>
                                </div>
                                
                                
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="number" class="form-control"  formControlName="phone_number" placeholder="{{ 'Phone number (optional)' | translate }}">
									 
                                    </div>
                                </div>   
								<div class="col-sm-6">
                                    <div class="form-group">
										<select formControlName="country_id"  [ngClass]="(submitted && ((errors && errors.country_id)  || af.country_id.errors )) ? 'form-control is-invalid' : 'form-control'" >
											<option *ngFor="let option of countriesList" value="{{option.id}}" >{{option.country_name}}</option>
										</select>
											<div *ngIf="submitted && errors && errors.country_id" class="invalid-feedback">{{errors.country_id[0]}}</div>
								<div *ngIf="submitted && af.country_id.errors"  class="invalid-feedback">
									 <div *ngIf="af.country_id.errors.required">{{ 'Land is required' | translate }}</div>
									 
								</div>
                                        
                                    </div>
                                </div>
								  <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="text"  [ngClass]="(submitted && ((errors && errors.city)  || af.city.errors )) ? 'form-control is-invalid' : 'form-control'" formControlName="city" placeholder="{{ 'city' | translate }}">
											<div *ngIf="submitted && errors && errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
											<div *ngIf="submitted && af.city.errors"  class="invalid-feedback">
												 <div *ngIf="af.city.errors.required">{{ 'City is required.' | translate }}</div>
												  
											</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="text"  [ngClass]="(submitted && ((errors && errors.zip_code)  || af.zip_code.errors )) ? 'form-control is-invalid' : 'form-control'" formControlName="zip_code" placeholder="{{ 'Postal code' | translate }}" >
											<div *ngIf="submitted && errors && errors.zip_code" class="invalid-feedback">{{errors.zip_code[0]}}</div>
								<div *ngIf="submitted && af.zip_code.errors"  class="invalid-feedback">
									 <div *ngIf="af.zip_code.errors.required">{{ 'Post code is required.' | translate }}</div>
									 
								</div>
                                    </div>
                                </div>
							
                            </div>
                           
                           
                            <div class="theme-btn">
                                <button type="submit" class="btn">{{ 'Further' | translate }}</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
