import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import * as globalConstant from 'src/app/global_constant'; 
declare var $:any;
@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
})
export class ProfilesComponent implements OnInit {
	APP_URI = globalConstant.APP_URI;
	loading = false;
	stepData:any;

	constructor(
		private mainService: MainService,  
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private router: Router,
		private confirmationDialogService: ConfirmationDialogService
	) {
		 
	}
	goProfile(id) {
		this.router.navigate(['/edit-search-profile'], { queryParams: { id: id } });
	}
	goInterest(id) {
		this.router.navigate(['/interest'], { queryParams: { id: id } });
	}
	addNewProfile() {
		this.router.navigate(['/add-search-profile']);
	}
	changeStatus(status,id) {
		let msg =  'Möchten Sie dieses Profil wirklich aktivieren?';
		if(status == 0) msg = 'Möchten Sie dieses Profil wirklich deaktivieren?';
		this.confirmationDialogService.confirm('Bitte bestätigen..', msg,'Bestätigen','Später')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.change_search_profile_status(token,status,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									let d = response.data;
									this.stepData	= response.data;
									 
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
			//.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}
	
	public deleteProfile(id) {
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Möchten Sie dieses Profil wirklich löschen?','Bestätigen','Später')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.delete_search_profile(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									let d = response.data;
									this.stepData	= response.data;
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
			//.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}
	public viewProfile(id) {
		
		
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		let authData= JSON.parse(localStorage.getItem('authUser')).data;
		
		this.loading = true;
		this.mainService.view_search_profile(token,id).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){
					 
					let filedList = [];
					let optval = '';
					let html = '';
					let df = [];
					let df1 = '';
					html = html + '<table class="table"><tbody>' +
        '<tr><td class="td-padding"><b>Was ist Dein Beruf?</b></td><td class="text-right">';
		
		response.data.occupations.forEach(function(item1,index){
				 if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
		});
		
		html = html + optval +'</td></tr>' ;
		
		 
		if(response.data.step > 2){
			filedList = ['graduation','education_qualification','work_experience','driving_license'];
			filedList.forEach(function(item,index){
				optval = '';
				if(response.data.static_features[item].type_of_feature ==6){
					response.data.static_features[item].options.forEach(function(item1,index1){
						if(item1.id == response.data[item]){
							optval = item1.name;
						}
					});
				}
				if(response.data.static_features[item].type_of_feature ==5){
					response.data.static_features[item].options.forEach(function(item1,index1){
						//if(item1.id.includes(response.data[item].toString())){
						//	console.log(response.data[item],item1.id, response.data[item].indexOf('"'+item1.id + '"'));
						if(response.data[item].indexOf('"'+item1.id + '"') >=0){
						 
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
					});
				}
				html = html + '<tr><td class="td-padding"><b>'+ response.data.static_features[item].label +'</b></td><td class="text-right">'+ optval  + '</td></tr>';
				
			});

		}
			if(response.data.step > 3){
			filedList = ['contract_type','working_hours','salary','vacation','weekend_work'];
			filedList.forEach(function(item,index){
				optval = '';
				if(response.data.static_features[item].type_of_feature ==6){
					response.data.static_features[item].options.forEach(function(item1,index1){
						if(item1.id == response.data[item]){
							optval = item1.name;
						}
					});
				}
				if(response.data.static_features[item].type_of_feature ==5){
					response.data.static_features[item].options.forEach(function(item1,index1){
						 
						if(response.data[item].indexOf('"'+item1.id + '"') >=0){
						 
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
					});
				}
				html = html + '<tr><td class="td-padding"><b>'+ response.data.static_features[item].label +'</b></td><td class="text-right">'+ optval  + '</td></tr>';
				
			});

		}
       
        	if(response.data.step > 4){
			filedList = ['radius_for_work','work_on_site','begin_job'];
			filedList.forEach(function(item,index){
				optval = '';
				if(response.data.static_features[item].type_of_feature ==6){
					response.data.static_features[item].options.forEach(function(item1,index1){
						if(item1.id == response.data[item]){
							optval = item1.name;
						}
					});
				}
				if(response.data.static_features[item].type_of_feature ==7){
					
							optval = response.data[item];
						
					
				}
				if(response.data.static_features[item].type_of_feature ==5){
					response.data.static_features[item].options.forEach(function(item1,index1){
						 
							console.log(response.data[item],item1.id, response.data[item].indexOf('"'+item1.id + '"'));
						if(response.data[item].indexOf('"'+item1.id + '"') >=0){
						 
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
					});
				}
				
				if(response.data.static_features[item].fld =='radius_for_work')
				html = html + '<tr><td class="td-padding"><b>'+ response.data.static_features['place_of_work'].label +'</b></td><td class="text-right">'+ response.data.place_of_work  + '</td></tr>';
				html = html + '<tr><td class="td-padding"><b>'+ response.data.static_features[item].label +'</b></td><td class="text-right">'+ optval  + '</td></tr>';
				if(response.data.static_features[item].fld =='work_on_site'){
					response.data.static_features['area_on_site_work'].options.forEach(function(item1,index1){
						 if(item1.id == response.data['area_on_site_work']){
							html = html + '<tr><td class="td-padding"><b>'+ response.data.static_features['area_on_site_work'].label +'</b></td><td class="text-right">'+ item1.name  + '</td></tr>';
						}
					});
				}
			});

			}
			
			if(response.data.step > 5){
				response.data.special_features.forEach(feature => {
				if(feature.type_of_feature== 4 || feature.type_of_feature== 5) {
					 
					if(response.data['spf'][feature.id]){
						df = JSON.parse(response.data['spf'][feature.id]);
					}else {
						df = [feature.default_value];
					}
					html = html + '<tr><td class="td-padding"><b>'+ feature.name +'</b></td><td class="text-right">'+ df.join(',')  + '</td></tr>';
					//group['feature' +feature.id] =  this.formBuilder.array(df, Validators.required);
				} else {
					
					if(response.data['spf'][feature.id]){
						df1 = response.data['spf'][feature.id];
					}
					else {
						df1 = feature.default_value;
					}
					html = html + '<tr><td class="td-padding"><b>'+ feature.name +'</b></td><td class="text-right">'+ df1  + '</td></tr>';
					 
				}
			})
				
			}	
			
			html = html + '</tbody></table>';
			if(response.data.step > 6){
				df = JSON.parse(response.data.cv);
				if(response.data.cv && response.data.cv.length > 0 ){
					
					 
					df1 = '';
					df.forEach(feature => {
						df1 = df1 + '<p><a href="'+ globalConstant.APP_URI + 'storage/app/cv/'+ id +'/' + feature.file +'" target="_blank">'+ feature.file_name +'</a></p>';
					})
					html =  html + '<p><b>Dateien</b></p>' +  df1  + '';
				
					
				}
				
			}
					 
					 
					 	this.confirmationDialogService.confirm('Profilübersicht', html,'Schließen',false,'lg')
			.then((confirmed) =>{ 
					 
			}
			);
					 
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    this.loading = false;
		
			   
			});
			
	
			//.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
	}
	ngOnInit(): void {
		 
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		let authData= JSON.parse(localStorage.getItem('authUser')).data;
		if(authData.icp != 2){
				this.router.navigate(['/create-search-profile']);
		}
		this.loading = true;
		this.mainService.search_profiles(token).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){
					let d = response.data;
					this.stepData	= response.data;

							
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    this.loading = false;
		
			   
			});
	
	}

}
