<app-header></app-header> 
 <!-- step1 -->
<app-applicant-verification [formData]="stepData"  *ngIf = "step == 1"></app-applicant-verification>
<app-employer-verification [formData]="stepData"  *ngIf = "step == 2"></app-employer-verification> 
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="sign-auth-form" [ngStyle]="{'background-image': (EmployerSignup) ? 'url(assets/images/login-side.jpg)' : 'url(assets/images/applicant-side.jpg)'}" *ngIf = "step == 0">
    <div class="row">
        <div class="col-lg-6">
            <div class="login-side-image">
                <div class="image-tag-top">
                    <div class="img-tag">
					
					 <h2 *ngIf="EmployerSignup == false">{{ 'One profile - hundreds' | translate }}<br/> {{ 'options' | translate }}</h2>
                       <p *ngIf="EmployerSignup == false">{{ 'Create searches, manage your matches and' | translate }}<br/> {{ 'chat with suitable candidates' | translate }}</p>
					
					  <h2 *ngIf="EmployerSignup">{{ 'One profile - hundreds' | translate }}<br/> {{ 'options' | translate }}</h2>
                        <p *ngIf="EmployerSignup">{{ 'Create searches, manage your matches and chat with suitable candidates' | translate }}</p>
					
                        

                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="login-top">
                <div class="login-form">
                    <div class="form-field">
                        <div class="head">
                            <h3>{{ 'Welcome back! log in' | translate }}</h3>
                        </div>
                        <div class="social-part" *ngIf="checkCookie == 1">
                            <div class="social">
                                <button class="img google" type="button" (click)="signInWithFB()">
                                    <img src="assets/images/fb-blue.png">
                                </button>
                                <button class="img google"  type="button"  (click)="signInWithGoogle()">
                                    <img src="assets/images/google.svg">
                                </button>
                            </div>
                            <div class="line-sec">
                                <p>{{ 'OR' | translate }}</p>
                            </div>
                        </div>
						
                      
                        <div class="login-card applicant-login">
                            
                            <div class="card-inner">
                                <div class="form-group allow_action text-center" *ngIf="checkCookie == 0">
													<label class="form-check-label">
                                                       {{'Google & Facebook Login is deactivated!' | translate }} 
                                                    </label>
                                                    <div>
													<button (click)="accept_cookie()" class="btn  btn-sm me-md-2 mr-2">{{'Allow' | translate }} </button>
													<button  class="btn btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button>
                                                    </div>
                                                </div>
                                  
                                    <div class="tab-content" id="myTabContent">
                                         <div class="tab-pane fade show active" id="profile" role="tabpanel"
                                            aria-labelledby="profile-tab">
                                            <div class="form-field">
                                                <form  [formGroup]="apllicantForm" (ngSubmit)="onSubmit()" autocomplete="off">

						
						
                                                    <div class="form-group" >
                                                        <input type="email" name="email"  [ngClass]="(submitted && ((errors && errors.email)  || af.email.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="email" aria-describedby="emailHelp" placeholder="E-Mail">
                                                        <div *ngIf="submitted && errors && errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
                                                        <div *ngIf="submitted && af.email.errors"  class="invalid-feedback">
                                                             <div *ngIf="af.email.errors.required">{{ 'Email is required.' | translate }}</div>
                                                             <div *ngIf="af.email.errors.email">{{ 'Please enter a valid email address.' | translate }}</div>
                                                        </div>
                                               
                                                            
                                                            
                                                            
                                                            
                                                        <!-- <small id="emailHelp" class="form-text text-muted">Wir werden Ihre E-Mail niemals an Dritte weitergeben.</small> -->
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" 
                                                        [ngClass]="(submitted && ((errors && errors.password)  || af.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                          autocomplete="new-password"  formControlName="password"
                                                            placeholder="{{ 'password' | translate }}">  
                                                            <div *ngIf="submitted && errors && errors.password" class="invalid-feedback">{{errors.password[0]}}</div>
                                                        <div *ngIf="submitted && af.password.errors"  class="invalid-feedback">
                                                             <div *ngIf="af.password.errors.required">{{ 'Password is required.' | translate }}</div>
                                                            
                                                        </div>
                                               
                                                             
                                                    </div>
                                                   
                                                     <div class="forgot-pw">
													<a routerLink="/forgot-password" routerLinkActive="active">{{ 'Forgot Password?' | translate  }}</a>
												</div>
												<div class="theme-btn">
													<button type="submit" class="btn">{{ 'log in' | translate }}</button>
												</div>
												<div class="dont-hv-ac">

													<p>{{ "Don't have an account yet?" | translate }} <a routerLink="/signup">{{ 'Join Now.' | translate }}</a></p>

												</div>
                        
                                                </form>
                                            </div>
                                        </div>
                        
                                    </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

