import { Component, OnInit,Input, EventEmitter, Output,ElementRef, ViewChild, NgZone} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { MapsAPILoader } from '@agm/core';
import {AgmCoreModule} from '@agm/core';
import { CookieService  } from 'ngx-cookie-service'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

declare var google: any;
 
@Component({
  selector: 'search-order-step-4',
  templateUrl: './search-order-step-4.component.html',
   styles: ['.theme-btn .back-btn { background-color: #003661; border-radius: 0; padding: 6px 20px 8px 20px; border-radius: 10px; font-size: 17px; color: #ffffff; font-weight: 500; width: auto; min-width: 140px; }']
})
export class SearchOrderStep4Component implements OnInit {
	@ViewChild('search',{ read: true, static: false })
	public searchElementRef: ElementRef;
	public zoom: number;
	public latitude: number;
	public longitude: number;
	public latlongs: any = [];
	public latlong: any=[];
	
	private geocoder: any;
	address = 'Jaipur';
	location: Location;
	loading: boolean;
	@Output() setPStep = new EventEmitter();	
	@Output() setPid = new EventEmitter();
	@Output() setStepData = new EventEmitter(); 
 
	@Input() formData;
	@Input() pId;
	form :FormGroup;
	filedList = ['radius_for_work','work_on_site','begin_job'];
	static_features:any; 
	submitted = false;
	errors:any;
	token:any;
	mst:any;
	isLoaded = false; 
	showMap = false; 
	checkCookie:any; 
	constructor(
	private mapLoader: MapsAPILoader,
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef,
	private ngZone: NgZone
	,private cookies: CookieService, private confirmationDialogService: ConfirmationDialogService
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
		if(this.checkCookie == 1){
			this.mapLoader.load().then(() => { 
				this.isLoaded = true;
			});
		}
		
		
  }
   accept_cookie(){
	  this.cookies.set('map','1');
	  this.checkCookie = 1;
	  this.mapLoader.load().then(() => { 
				this.isLoaded = true;
			});
	  //window.location.reload();
	  
	}
  	showPopup(slug){
	  this.loading=true;
	  let that = this;
	   this.mainService.getCmsContent(slug).subscribe(res => {
		   
		   that.confirmationDialogService.confirm(res.data.title, res.data.body,'Close','','lg');
			this.loading=false;  
    });
	return false;
	  
  }

   initialize(){
	  
	this.showMap = true;
	var map; 
	var latlng = new google.maps.LatLng(this.form.value.lat, this.form.value.lng);
	var radius = this.form.value.radius_for_work;
	// 5 => 12
	//10 => 11
	//15 => 10
	var mapOptions = {center: latlng, zoom: 18, mapTypeId: google.maps.MapTypeId.ROADMAP};
	var el=document.getElementById("map_canvas");
	map = new google.maps.Map(el, mapOptions);
	var marker = new google.maps.Marker({map: map,position: latlng});
	 
	
	

  }
 
  setUpAuto(){
	  
		if(this.isLoaded){
			var that = this;
		var originInput = document.getElementById('place_of_work');;
		var autoc = new google.maps.places.Autocomplete(originInput);
			autoc.setFields(['place_id','name','geometry']);
			autoc.addListener('place_changed', function() {
				var place = autoc.getPlace();
				if (!place.place_id ) {
					that.form.patchValue({place_of_work: null,lat: null, lng: null});
					that.toastr.error('Please select an option from the dropdown list.');
					return;
				}
				that.form.patchValue({place_of_work:place.name,lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
				that.submitted = false;
				that.initialize();
				
			});
			that.initialize();
		} else {
			var that = this;
			setTimeout(function (){
				that.setUpAuto();
			},500);
		}
	 
  }
 ngAfterViewInit(){
	   
	window.scrollTo(0,0);
	this.setUpAuto();     
   }
 
 
	ngOnInit(): void {
		this.checkCookie = this.cookies.get('map');
		if(this.checkCookie == '1'){
			 this.mapLoader.load().then(() => { 
				this.isLoaded = true;
			}); 
		}
	this.formData.work_on_site = (this.formData.work_on_site !='' && this.formData.work_on_site != null) ? parseInt(this.formData.work_on_site):'';	
	this.formData.area_on_site_work = (this.formData.area_on_site_work !='' && this.formData.area_on_site_work != null) ? parseInt(this.formData.area_on_site_work):'';	
	this.formData.begin_job = (this.formData.begin_job !='' && this.formData.begin_job != null) ? parseInt(this.formData.begin_job):'';	

		 
		this.form = this.formBuilder.group({
				   
				  place_of_work : [this.formData.place_of_work,Validators.required],
				 // radius_for_work : [this.formData.radius_for_work,Validators.required],
				  work_on_site : [this.formData.work_on_site,Validators.required],
				  area_on_site_work : [this.formData.area_on_site_work],
				  begin_job : [this.formData.begin_job,Validators.required],
				  lat : [this.formData.lat,Validators.required],
				  lng : [this.formData.lng],
				  step : [4],
				 
				});
				
		
		this.static_features = this.formData['static_features'];	
		
	}
	 

   
 get af() { return this.form.controls; }
 
onCheckboxChange(e,fld) {
	
    const checkboxFld: FormArray = this.form.get(fld) as FormArray;
    if (e.target.checked) {
		checkboxFld.push(new FormControl(e.target.value));
    } else {
       const index = checkboxFld.controls.findIndex(x => x.value === e.target.value);
       checkboxFld.removeAt(index);
    }
  }
  goBack (){
	  this.setPStep.emit(3);
  }
  isChecked(v,fld) {
	return this.form.get(fld).value.includes(v.toString());
	 
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
	if(firstInvalidControl){
		
		firstInvalidControl.focus(); //without smooth behavior
	}
  }
	frmsub(){
		
	  
		
	if(this.form.invalid){
	  this.submitted = true;
	  this.scrollToFirstInvalidControl();
      return
     }	 
	 this.errors = false;
	 this.submitted = false;
	  this.loading = true;
	 this.mainService.save_search_order(this.token,this.pId,this.form.value).subscribe(
		response => { 
			this.loading = false; 
			this.submitted = true;
			if(response.status == 'success'){
				  
					if(response.step == 5 && response.data.special_features.length==0){
						response.step = 6;
					}
				this.setPid.emit(response.data.id);
				this.setPStep.emit(response.step);
				this.setStepData.emit(response.data);
				
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
				this.scrollToFirstInvalidControl();

			}
		},
        (error) => {                              
            this.loading = false; 
		}
	); 
    
	  
  }
	onSubmit(){
		this.submitted = false;
		this.frmsub(); 
		/* 
		if(this.form.value.place_of_work!=''){
			this.geocoder = new google.maps.Geocoder();
			this.geocoder.geocode({address: this.form.value.place_of_work}, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) { 
					this.form.patchValue({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()});
				}  
				this.frmsub();
			});	
		} else {
			
		} */
		
	
		
	}

	
}