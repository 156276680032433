import { Component ,ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, Params, NavigationEnd, ActivatedRoute, NavigationStart} from '@angular/router';
import { CookieService  } from 'ngx-cookie-service'; 
import { HomeComponent } from "./component/home/home.component";
import {ChatService} from 'src/app/services/chat.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Your Job Craft';
  segment1: string = '';
  currenturl = '';
  @ViewChild(HomeComponent) home;

	constructor(
				private translate: TranslateService,
				private router: Router,
				private cookies: CookieService,
				private chatService : ChatService,
				public toastr : ToastrService
		) {
        translate.setDefaultLang('de');
		
		this.router.events.subscribe(event => {
		  if(event instanceof NavigationEnd) {
			var urlObj 		=	this.router.url.split('/');
			if(urlObj[1]){
				this.segment1	=	this.router.url.split('/')[1];
			}
			//console.log(this.segment1);
			if(urlObj[1] !== "experience-provider-add")
			{
			  localStorage.removeItem('exp-provider-add')
			}
			if(this.router.url.split('/')[1]){
				if(this.router.url.split('/')[1].split('?')[0]){
					this.currenturl =  this.router.url.split('/')[1].split('?')[0];
				}else{
					this.currenturl = this.router.url.split('/')[1];
				}
			}
		  }
		}); 
	}
	
	onActivate(event) {
		window.scroll(0,0);
		//or document.body.scrollTop = 0;
		//or document.querySelector('body').scrollTo(0,0)
	}  
	setAcceptCookie(event) {
		  let currentUrl = this.router.url;
		  console.log(this.router);
		  if(currentUrl == '/'){
			  this.home.check_cookie();
		  }
		//this.ngOnInit();
		//window.location.reload(true);
	   /*  window.scroll(0,0);
		  
		  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		  this.router.onSameUrlNavigation = 'reload';
		  this.router.navigate([currentUrl]);
		  this.ngOnInit(); */
		//or document.body.scrollTop = 0;
		//or document.querySelector('body').scrollTo(0,0)
	}
	ngOnInit(): void {
	 this.chatService.getMessage().subscribe((messageData: any) => {
			//var sender_name	=	'Mahi';
			var msg =   this.translate.instant('MessageFromUser', {sender_name : messageData.sender_name});
			if(this.currenturl != 'chat'){
				this.toastr.success(messageData.last_message.data.message, msg);
			}
	  });
	}
}
