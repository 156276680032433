 <header >
        <div class="container">
            <div class="col-sm-12 p-0 header-menu">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="top-menu-bar d-flex">
                            <div class="logo" (click)="gotoHome()" style="cursor:pointer;" >
                                <img class="logo-first" src="assets/images/logo.png" alt="Logo">
                                <img class="logo-second" src="assets/images/logo-white.png" alt="Logo">
                            </div>
                            <div class="toggle-top" [ngClass]="menuShow ? 'on' : ''" (click)="menuToggle()">
                                <div id="toggle">
                                    <div class="one"></div>
                                    <div class="two"></div>
                                    <div class="three"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 menu-links" [ngClass]="menuShow ? 'side-slide' : ''">
                        <div class="links">
                            <ul>
                                <li>
                                    <a routerLink="/for-companies" > {{'FOR COMPANIES' | translate }}</a>
                                </li>
                                <li>
                                    <a routerLink="/faqs">{{'FAQs' | translate }}</a>
                                </li>
                               
                                <li>
                                    <a routerLink="/contact">{{ 'CONTACT' | translate}}</a>
                                </li>
								<li *ngIf = "!loggedIn"  class="login-out">
                                <a  routerLink="/login"  class="user-sec">

                                    <span class="img">
                                        <img src="assets/images/man-user.svg" alt="" />
                                    </span>
                                    <span class="content">
                                        
                                        {{ 'LOG IN' | translate}}
                                    </span>

                                </a>
                            </li>
							
                                <li class="login-out" *ngIf = "loggedIn">
                                    <a href="#faq" class="user-sec">
											<ng-template #user_blank_image> <img src="/assets/images/user.gif"> </ng-template>
											
											<span class="img"  *ngIf="authData.uri == 2">
											<img src="{{APP_URI + 'uploads/users/' + authData.im}}" alt="" *ngIf="authData.im != null else user_blank_image"  />
											</span>
											<span class="img" *ngIf="authData.uri == 3">
											<img src="{{APP_URI + 'uploads/service_providers/' + authData.lo}}" alt="" *ngIf="authData.lo != null else user_blank_image"  />
											</span>
											
											
											
                                       
                                        <span class="content">
                                            {{authData.fn}}
                                        </span>

                                    </a>
                                    <ul>
                                        <li>
                                             <a href="javascript:void(0);" routerLink="/my-profile" *ngIf="authData.uri == 2">{{'Profile' | translate }}</a>
                                    <a href="javascript:void(0);" routerLink="/company-profile" *ngIf="authData.uri == 3">{{'Profile' | translate }}</a>
                                        </li>
                                        <li>
                                            <a   routerLink="/change-password">{{'Settings' | translate }}</a>
                                        </li>
                                        <li>
                                            <a href="#" (click)="logout()">{{'Logout' | translate }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <div class="theme-btn header-btn text-right">
                            <button class="btn" (click)="Registrierung()" *ngIf = "!loggedIn">{{'to register' | translate }}</button>
							 <button class="btn" (click)="logout()" *ngIf = "loggedIn">{{'Logout' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
