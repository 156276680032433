<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <div class="main-page-box" >
        <div class="container-fluid">
            <div class="row">
                


				  <div class="col-12 paddingSmall searchFlexGroup flex-display mb-4">
                    <div class="heading_new">{{'Company Profile' | translate}}</div>
                    <div class="searchOrderBtnGroup flex-frow-1">

                        <button class="newThemeBtn" routerLink="/change-password">
                            <i class="fas fa-key"></i>
                           {{'Change Password' | translate}}
                        </button>
                    </div>

                </div>
                <div class="col-12 paddingSmall">
				 <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="profile_box">
                        <div class="upload_compny_profile">
                            <div class="compny_profile_img" style="background-image: url(assets/images/user_blank_image.png);" *ngIf="stepData.logo== null else cProfile">
                                <img src="assets/images/user_blank_image.png" alt="" />
                            </div>
							<ng-template #cProfile> <div class="compny_profile_img" style="background-image: url({{APP_URI + 'uploads/service_providers/' + stepData.logo}});" >
                                <img src="{{APP_URI + 'uploads/service_providers/' + stepData.logo}}" alt="" />
                            </div> </ng-template>
                            <div class="compny_profile_content">
                                {{'Company Logo' | translate}}  
                                <span>{{'(recommended image size):' | translate}}</span>
                                <span>{{'300x300, max.file size: 1MB' | translate}} </span>
                            </div>
                            <div class="custom_upload_img">
                                <input type="file" hidden name="logo_file" accept='image/*' id="logo_file"   (change)="onFileChange($event,'logo')">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" name="logo"  formControlName="logo"  readonly>
                                    <label class="customLabel" for="logo_file">
                                            {{'Add to' | translate}}
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div class="compny_other_formField">
                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Company Name' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
								
								 <div class="form-group" >
									<input type="text" name="first_name"  [ngClass]="(submitted && ((errors && errors.first_name)  || af.first_name.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="first_name"  placeholder="{{'Company Name' | translate}}">
									<div *ngIf="submitted && errors && errors.first_name" class="invalid-feedback">{{errors.first_name[0]}}</div>
									<div *ngIf="submitted && af.first_name.errors"  class="invalid-feedback">
										 <div *ngIf="af.first_name.errors.required">{{'Company name is required!' | translate}}</div>
									</div>
									
								</div>
								
                                    
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Company Description' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                        <textarea [ngClass]="(submitted && ((errors && errors.description)  || af.description.errors )) ? 'form-control h-100 is-invalid' : 'form-control h-100'" rows="5" formControlName="description"  placeholder="{{'Company Description' | translate}}"></textarea>
										
										 
									<div *ngIf="submitted && errors && errors.first_name" class="invalid-feedback">{{errors.description[0]}}</div>
									<div *ngIf="submitted && af.first_name.errors"  class="invalid-feedback">
										 <div *ngIf="af.first_name.errors.required">{{'Company description is required!' | translate}}</div>
									</div>
										
										
										
										
                                    </div>
                                </div>
                            </div>

                            <div class="row d-none">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Cover image' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
									
									  <div class="custom_upload_img">
                                <input type="file" hidden   accept='image/*' id="cover_image"   (change)="onFileChange($event,'cover_image')">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" name="cover_image"  formControlName="cover_image"  readonly>
                                    <label class="customLabel" for="cover_image">
                                        {{'Add to' | translate}}
                                    </label>
                                </div>

                            </div>
									
									
                                         
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Street & House Number' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                       <input type="text" name="address"  [ngClass]="(submitted && ((errors && errors.address)  || af.address.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="address"  placeholder="{{'Street & House Number' | translate}}">
									<div *ngIf="submitted && errors && errors.address" class="invalid-feedback">{{errors.address[0]}}</div>
									<div *ngIf="submitted && af.address.errors"  class="invalid-feedback">
										 <div *ngIf="af.address.errors.required">{{'Street & House Number is required!' | translate}}</div>
									</div>
                                    </div>
                                </div>
                            </div>
<div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Postcode' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                      <div class="form-group">
                                       <input type="text" name="zip_code"  [ngClass]="(submitted && ((errors && errors.zip_code)  || af.zip_code.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="zip_code"  placeholder="{{'Postcode' | translate}}">
									<div *ngIf="submitted && errors && errors.zip_code" class="invalid-feedback">{{errors.zip_code[0]}}</div>
									<div *ngIf="submitted && af.zip_code.errors"  class="invalid-feedback">
										 <div *ngIf="af.zip_code.errors.required">{{'Postcode is required!' | translate}}</div>
									</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'City' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                       <input type="text" name="city"  [ngClass]="(submitted && ((errors && errors.city)  || af.city.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="city"  placeholder="{{'City' | translate}}">
									<div *ngIf="submitted && errors && errors.city" class="invalid-feedback">{{errors.city[0]}}</div>
									<div *ngIf="submitted && af.city.errors"  class="invalid-feedback">
										 <div *ngIf="af.city.errors.required">{{'City is required!' | translate}}</div>
									</div>
                                    </div>
                                </div>
                            </div>
						

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Country' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
								
								<div class="form-group">
										<select formControlName="country_id"  [ngClass]="(submitted && ((errors && errors.country_id)  || af.country_id.errors )) ? 'form-control custom-select is-invalid' : 'form-control custom-select'" >
											<option *ngFor="let option of countriesList" value="{{option.id}}" >{{option.country_name}}</option>
										</select>
											<div *ngIf="submitted && errors && errors.country_id" class="invalid-feedback">{{errors.country_id[0]}}</div>
								<div *ngIf="submitted && af.country_id.errors"  class="invalid-feedback">
									 <div *ngIf="af.country_id.errors.required">{{'Country is required!' | translate}}</div>
									 
								</div>
                                        
                                    </div>
									
									
								
								
                                     
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Phone Number' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
								
									<div class="form-group">
                                       <input type="text" name="phone_number"  [ngClass]="(submitted && ((errors && errors.phone_number)  || af.phone_number.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="phone_number"  placeholder="{{'Phone Number' | translate}}">
									<div *ngIf="submitted && errors && errors.phone_number" class="invalid-feedback">{{errors.phone_number[0]}}</div>
									<div *ngIf="submitted && af.phone_number.errors"  class="invalid-feedback">
										 <div *ngIf="af.phone_number.errors.required">{{'Phone Number is required!' | translate}}</div>
									</div>
                                    </div>
									
								
                                    
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'E-mail Address' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
								<div class="form-group">
                                       <input type="text" name="email"  [ngClass]="(submitted && ((errors && errors.email)  || af.email.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="email"  placeholder="{{'E-mail Address' | translate}}">
									<div *ngIf="submitted && errors && errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
									<div *ngIf="submitted && af.email.errors"  class="invalid-feedback">
										 <div *ngIf="af.email.errors.required">{{'E-mail is required!' | translate}}</div>
										 <div *ngIf="af.email.errors.email">{{'E-mail is invalid!' | translate}}</div>
									</div>
                                    </div>
								
								
								
                                  
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Web Address' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                       <input type="text" name="website"  class="form-control" formControlName="website"  placeholder="{{'Web Address' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="dividerHeading">
                                        {{'Social Profile' | translate}}
                                        <div class="dividerLIne"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Facebook' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                        <input type="text" name="facebook"  class="form-control" formControlName="facebook"  placeholder="{{'Facebook' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Linkedin' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                         <input type="text" name="linkedin"  class="form-control" formControlName="linkedin"  placeholder="{{'Linkedin' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'XING' | translate}}</label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
                                        <input type="text" name="xing"  class="form-control" formControlName="xing"  placeholder="{{'XING' | translate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="dividerHeading">
                                        {{'Company Video' | translate}}
                                        <div class="dividerLIne"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <label>{{'Upload Video' | translate}} <a href="{{APP_URI + 'uploads/service_providers/' + stepData.video}}"  target="_blank" class="ml-auto" *ngIf="stepData.video != null" ><i class="fa fa-download"></i></a></label>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9">
                                    <div class="form-group">
									
										
									  <div class="custom_upload_img">
                                <input type="file" hidden   accept='.pdf,.mp4' id="video"   (change)="onFileChange($event,'video')">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" name="video"  formControlName="video"  readonly>
                                    <label class="customLabel" for="video">
                                        {{'Add to' | translate}}
                                    </label>
                                </div>

                            </div>
									
									
                                         
                                    </div>
									
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-8 col-lg-9 ml-auto">
                                    <button class="newThemeBtn secondary_newThemeBtn">{{'Cancel' | translate}}</button>
                                    <button type="submit" class="newThemeBtn">{{'Save' | translate}}</button>
                                </div>
                            </div>

                        </div>
                    </div>
               
 </form>
			   </div>

            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main>