 import { Component, OnInit} from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as globalConstant from 'src/app/global_constant'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss']
})
export class MatchesComponent implements OnInit {
	APP_URI = globalConstant.APP_URI;
	loading = false;
	submitted = false;
	pid:any;
	stepData:any;
	userData:any;
	searchData:any;
	hideData = 0;
	isShowHideMatch = 0;
		searchedKeyword: string;
	searched: string;
	form :FormGroup;
	form1 :FormGroup;
	note_id :any;
	constructor(private mainService: MainService, private formBuilder: FormBuilder,  private toastr: ToastrService,private router: Router, private confirmationDialogService: ConfirmationDialogService) { }
	filterby() {
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		this.loading = true;
		this.mainService.search_matches(token,this.form.value).subscribe(
		response => { 
			this.submitted = true;
			this.loading = false;
			if(response.status == 'success'){
				 this.stepData	= response.data;
				this.hideData	= response.hideData;
				 
				this.isShowHideMatch	= 1;
			 
			}
			else {
				 
			 
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
			},
			(error) => {                              
				
			 this.loading = false;
			}
		); 
		return false;
	}
	saveNote() {
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		this.loading = true;
		this.mainService.save_notes(token,this.note_id,this.form1.value).subscribe(
		response => { 
			 
			this.loading = false;
			if(response.status == 'success'){
				this.toastr.success(response.msg);    
				this.stepData	= response.data;
				this.hideData	= response.hideData;
				 
				this.isShowHideMatch	= 1;
			 
			}
			else {
				 
			 
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
			},
			(error) => {                              
				
			 this.loading = false;
			}
		); 
		return false;
	}
	getVal(item,forc,val) {
		if(forc=='C'){
			var optval = '';
			var doesnotmatter = this.searchData.c_static_features[item]["doesnotmatter"];
			if(this.searchData.c_static_features[item].type_of_feature ==7){					
				optval = val;
			}
			
			if(this.searchData.c_static_features[item].type_of_feature ==6){
					this.searchData.c_static_features[item].options.forEach(function(item1,index1){
						if(item1.id == val){
							optval = item1.name;
						}
					});
					if(val == -1){
						optval = doesnotmatter;
					}
				}
			if(this.searchData.c_static_features[item].type_of_feature ==5){
					this.searchData.c_static_features[item].options.forEach(function(item1,index1){
						if(val.length > 0 && val.indexOf('"'+item1.id + '"') >=0){
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
						
					});
					if(val.length > 0 && val.indexOf('"-1"') >=0){
						if(optval!=''){
							optval = optval + ', ' + doesnotmatter;
						}else{	
							optval = doesnotmatter;
						}
					}
				}
				
			
			
			return optval;
		}
		else {
			var optval = '';
			var doesnotmatter = this.searchData.a_static_features[item]["doesnotmatter"];
			if(this.searchData.a_static_features[item].type_of_feature ==7){					
				optval = val;
			}
			
			if(this.searchData.a_static_features[item].type_of_feature ==6){
					this.searchData.a_static_features[item].options.forEach(function(item1,index1){
						if(item1.id == val){
							optval = item1.name;
						}
					});
					if(val == -1){
						optval = doesnotmatter;
					}
				}
			if(this.searchData.a_static_features[item].type_of_feature ==5){
					this.searchData.a_static_features[item].options.forEach(function(item1,index1){
						if(val.length > 0 && val.indexOf('"'+item1.id + '"') >=0){
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
						
					});
					if(val.length > 0 && val.indexOf('"-1"') >=0){
						if(optval!=''){
							optval = optval + ', ' + doesnotmatter;
						}else{	
							optval = doesnotmatter;
						}
					}
				}
				
			
			
			return optval;
		}
	}
	getVal1(item) {
		var optval = '';
		 
		this.searchData.occupations.forEach(function(item1,index1){
						
						if(item.includes('"'+item1.id + '"')){
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
						
					});
		
		 

		return optval;
	}
	setNote(item_id,notes) {
		 
		this.note_id=item_id;
		this.form1.patchValue({notes:notes});
		  
	}
	startChat(id) {
		this.router.navigate(['/chat'], { queryParams: { id: id } });
	}
	showHideMatch() {
		this.isShowHideMatch = 1;
	}
	markAsIntrested(id) {
		let authData= JSON.parse(localStorage.getItem('authUser')).data;
		if(authData.si == null) 
		{	
			this.router.navigate(['/subscribe-plan']);
		}
		else {
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Möchten Sie sich wirklich für dieses Suchprofil interessieren?','Bestätigen','Später')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						this.loading = true;
						this.mainService.company_inserested(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.stepData	= response.data;
									this.hideData	= response.hideData;
									this.toastr.success('Interessenten erfolgreich markiert!');
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
		
		}
		 
	}
 

markAsHide(id) {
		
		let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.hide_match(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.stepData	= response.data;
									this.hideData	= response.hideData;
									this.isShowHideMatch = 0;
									this.toastr.success('Das Spiel wurde erfolgreich ausgeblendet!');
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
		
		 
		
		
		 
	}
 

markAsUnHide(id) {
		
		let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.unhide_match(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.stepData	= response.data;
									this.hideData	= response.hideData;
									//this.isShowHideMatch = 0;
									this.toastr.success('Das Spiel wurde erfolgreich ausgeblendet!');
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
		
		 
		
		
		 
	}
 
get af() { return this.form.controls; }

 ngOnInit(): void {
	 this.userData = JSON.parse(localStorage.getItem('authUser')).data;
	   this.loading = true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		const urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');  
		if(id){
			this.pid = id;
		}
		else {
			this.pid = 0;
		}
		this.mainService.matches(token, this.pid).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){	 
					this.stepData	= response.data;
					this.hideData	= response.hideData;
					this.searchData	= response.searchData;
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
		this.form1 = this.formBuilder.group({
				  
				  notes : ['']
				  
				});	this.form = this.formBuilder.group({
				  occupation_id : [''],
				  radius_for_work : [''],
				  graduation : [''],
				  salary : [''],
				  contract_type : [''],
				  place_of_work : [''],
				  keyword : ['']
				  
				});
			
  }

}
