import { Component, OnInit,Input, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  applicantSignup = true;
   step1 =false;
   step2 = false;
   step3 = false;
   loggedIn: boolean;
   submitted = false;
   loading = false;
   errors:any;
   step1Data:any;
  
  resetPasswordForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private mainService: MainService,
    private router: Router,) { }

  ngOnInit(): void {
	   if(localStorage.getItem('authUser')){
		this.router.navigate(['/dashboard']);
	}

	const urlParams = new URLSearchParams(window.location.search);
	let id = urlParams.get('enc');  
	if(id){
		
	}
	else {
		this.toastr.error ("Invalid URL or Expired!");
		this.router.navigate(['/']);
	}
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      validate_string: [id, Validators.required],
      new_password: ['', Validators.required],
      new_password_confirmation:['',Validators.required]
    });
  }
   get af() { return this.resetPasswordForm.controls; }
 
 
  onSubmit(){
   // console.log(this.resetPasswordForm.value);
   this.submitted = true;
    if(this.resetPasswordForm.invalid){
    // this.toastr.error ("Enter your New Password ");
     return
    }
	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true;
	 this.mainService.reset_password(this.resetPasswordForm.value).subscribe(response=>{ 
		this.submitted = true;
		 this.loading = false;
		if(response.status == 'success'){
			this.toastr.success(response.msg);
			this.router.navigate(['/login']);
		  }
		  else {
			 
			this.errors=response.errors;
			if(response.msg){
				this.toastr.error(response.msg);  
			}
		  }
       
    }
	,
        (error) => {                               
           
            
		   this.loading = false;
    
           
        }
	
	
	); 
   
    //console.log('password reseted',this.resetPasswordForm.value);
    //this.router.navigate(['login-applicant']);
  }
}
