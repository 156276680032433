import { Component, OnInit,Input } from '@angular/core';
import { filter} from 'rxjs/operators';
import { NavigationEnd, Router, ActivatedRoute ,NavigationExtras} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

@Input() activeTab = '';
authData:any;		  
  constructor(   private router: Router, private route   : ActivatedRoute) { 
  
  
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(res => {
		//console.log(this.router.url.split("/"));

		 if(this.router.url.split("/")[1] !=="")
        {
			this.activeTab  = this.router.url.split("/")[1].match(/\S[^?]*(?:\?+|$)/g)[0];	
			//console.log(this.activeTab);			
        }
        else
        {
			
        } 


		
     
    });

   
  
  }
  

  ngOnInit(): void {
	  this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(res => {
		//console.log(this.router.url.split("/"));

		 if(this.router.url.split("/")[1] !=="")
        {
			this.activeTab  = this.router.url.split("/")[1].match(/\S[^?]*(?:\?+|$)/g)[0];	
			console.log(this.activeTab);			
        }
        else
        {
			this.activeTab = ''
        } 


		
     
    });

	   const body = document.getElementsByTagName('body')[0]; body.classList.add('color-theme');
	   this.authData= JSON.parse(localStorage.getItem('authUser')).data;
  }
  

}
