<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup">
                    <div class="heading_new">{{ 'Payment Details' | translate }}</div>
                </div>

                <div class="col-12 paddingSmall ">
                    <div class="profile_box">
                        <div class="paymentDetailHead">{{ 'You are Buying:' | translate }}</div>
                        <div class="paymentDetailSubHead">
                            <div class="paymentTerm">
                                {{ '1 MONTH TERM' | translate }}
                                <span>{{ 'Subscription Plan(Including VAT)' | translate }}</span>
                            </div>
                            <div class="paymentPric">
                                €250
                                <span>{{ 'Total' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="profile_box mt-4">
                        <div class="paymentDetailHead">{{ 'Payment Information:' | translate }}</div>

                        <div class="payment_Radio">
                            <div class="maleRadio">
                                <input type="radio" checked name="gender" hidden id="male" />
                                <label class="genderLabel" for="male">{{ 'Bank Transfer' | translate }}</label>
                            </div>

                            <div class="maleRadio">
                                <input type="radio" name="gender" hidden id="female" />
                                <label class="genderLabel" for="female">Paypal</label>
                            </div>
                        </div>


                        <ul class="payment_detail_bank">
                            <li>
                                <span>{{ 'Bank Name' | translate }}</span> Indusland Bank
                            </li>
                            <li>
                                <span> {{ 'Branch' | translate }}</span> Tonk Phatak, Jaipur
                            </li>
                            <li>
                                <span> {{ 'Ac/No' | translate }}</span> 610369615956
                            </li>
                            <li>
                                <span>{{ 'Swift Code' | translate }}</span> IBS096
                            </li>
                            <li>
                                <span>{{ 'Invoice Number' | translate }}</span> 062021-60909
                            </li>
                        </ul>

                    </div>
                </div>

            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main>