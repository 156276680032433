<app-header></app-header>
<!-- <p>reset-password works!</p> -->
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="sign-auth-form"  style="background-image: url(assets/images/applicant-side.jpg);">
    <div class="row">
        <div class="col-sm-6">
		
		<div class="login-side-image">
                <div class="image-tag-top">
                    <div class="img-tag">
                          <h2>{{"One profile - hundreds of possibilities" | translate}}</h2>
                        <p>{{"Create searches, manage your matches and chat with suitable candidates" | translate}}</p>
                    </div>
                </div>

            </div>
            
        </div>
        <div class="col-sm-6">
            <div class="login-top">
                <div class="login-form">
                    <div class="form-field">
                        <div class="head">
                            <h3>{{ "Welcome back! log in" | translate}}</h3>
                        </div>
                        
                        <form  [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                              <div class="form-group">
                                 <input type="email" name="email"  [ngClass]="(submitted && ((errors && errors.email)  || af.email.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="email" aria-describedby="emailHelp" placeholder="{{'Enter email' | translate}}">
								<div *ngIf="submitted && errors && errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
								<div *ngIf="submitted && af.email.errors"  class="invalid-feedback">
									 <div *ngIf="af.email.errors.required">{{"Email is required." | translate}}</div>
									 <div *ngIf="af.email.errors.email">{{"Please provide valid email." | translate}}</div>
								</div>
                                
                            </div>
							 
							  <div class="form-group">
                                <input type="password" class="form-control" 
								[ngClass]="(submitted && ((errors && errors.new_password)  || af.new_password.errors )) ? 'form-control is-invalid' : 'form-control'"
								  autocomplete="new-password"  formControlName="new_password"
                                    placeholder="{{'Password' | translate}}">  
									<div *ngIf="submitted && errors && errors.new_password" class="invalid-feedback">{{errors.new_password[0]}}</div>
								<div *ngIf="submitted && af.new_password.errors"  class="invalid-feedback">
									 <div *ngIf="af.new_password.errors.required">{{"Password is required." | translate}}</div>
									
								</div>
                       
									 
                            </div>
							 <div class="form-group">
                                <input type="password" class="form-control" 
								[ngClass]="(submitted && ((errors && errors.new_password_confirmation)  || af.new_password_confirmation.errors )) ? 'form-control is-invalid' : 'form-control'"
								  autocomplete="new-password"  formControlName="new_password_confirmation"
                                    placeholder="{{'Confirm Password' | translate}}">  
									<div *ngIf="submitted && errors && errors.new_password_confirmation" class="invalid-feedback">{{errors.new_password_confirmation[0]}}</div>
								<div *ngIf="submitted && af.new_password_confirmation.errors"  class="invalid-feedback">
									 <div *ngIf="af.new_password_confirmation.errors.required">{{'Password is required.' | translate}}</div>
									
								</div>
                       
									 
                            </div>
                             
                            <div class="theme-btn">
                                <button type="submit" class="btn">{{'Save' | translate}}</button>
                            </div>
                            <div class="dont-hv-ac">
                                <p>{{"Don't have an account yet?" | translate}} <a  routerLink="/signup" href="#">{{'Join Now.' | translate}}</a></p>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>