import { Component, OnInit,Input, EventEmitter, Output,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
 
@Component({
  selector: 'search-order-step-3',
  templateUrl: './search-order-step-3.component.html',
   styles: ['.theme-btn .back-btn { background-color: #003661; border-radius: 0; padding: 6px 20px 8px 20px; border-radius: 10px; font-size: 17px; color: #ffffff; font-weight: 500; width: auto; min-width: 140px; }']
})
export class SearchOrderStep3Component implements OnInit {
	@Output() setPStep = new EventEmitter();	
	@Output() setPid = new EventEmitter();
	@Output() setStepData = new EventEmitter();  
	 
	@Input() formData;
	@Input() pId;
	form :FormGroup;
	filedList = ['contract_type','working_hours','salary','vacation','weekend_work'];
	static_features:any; 
	submitted = false;
	loading = false;
	errors:any;
	token:any;
	mst:any;
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
  }
  ngAfterViewInit(){
	   
	   
	   window.scrollTo(0,0);
   }

 
 
	ngOnInit(): void {
 
		this.formData.contract_type = (this.formData.contract_type !='' && this.formData.contract_type != null) ? parseInt(this.formData.contract_type):'';	
		this.formData.working_hours = (this.formData.working_hours !='' && this.formData.working_hours != null) ? parseInt(this.formData.working_hours):'';	
		this.formData.salary = (this.formData.salary !='' && this.formData.salary != null) ? parseInt(this.formData.salary):'';	
		this.formData.vacation = (this.formData.vacation !='' && this.formData.vacation != null) ? parseInt(this.formData.vacation):'';	
		this.formData.weekend_work = (this.formData.weekend_work !='' && this.formData.weekend_work != null) ? parseInt(this.formData.weekend_work):'';	
		 
		this.form = this.formBuilder.group({
				  contract_type : [this.formData.contract_type, Validators.required],
				  working_hours : [this.formData.working_hours, Validators.required],
				  salary : [this.formData.salary,Validators.required],
				  vacation : [this.formData.vacation,Validators.required],
				  weekend_work : [this.formData.weekend_work,Validators.required],
				  step : [3],
				 
				});
				
		
		this.static_features = this.formData['static_features'];	
	
	}
	  
 get af() { return this.form.controls; }
 
onCheckboxChange(e,fld) {
	
    const checkboxFld: FormArray = this.form.get(fld) as FormArray;
    if (e.target.checked) {
		checkboxFld.push(new FormControl(e.target.value));
    } else {
       const index = checkboxFld.controls.findIndex(x => x.value === e.target.value);
       checkboxFld.removeAt(index);
    }
  }
  goBack (){
	  this.setPStep.emit(2);
  }
  isChecked(v,fld) {
	return this.form.get(fld).value.includes(v.toString());
	 
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
	if(firstInvalidControl){
		
		firstInvalidControl.focus(); //without smooth behavior
	}
  }
  onSubmit(){
	    
    if(this.form.invalid){
      this.scrollToFirstInvalidControl();
	  this.submitted = true;
      return
     }	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true; 
	  	
	 this.mainService.save_search_order(this.token,this.pId,this.form.value).subscribe(
		response => { 
			this.submitted = true;
			this.loading = false; 
			if(response.status == 'success'){
				 
				this.setPid.emit(response.data.id);
				this.setPStep.emit(response.step);
				this.setStepData.emit(response.data);
				
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
				this.scrollToFirstInvalidControl();
			}
		},
        (error) => { 
 		
            this.loading = false;
		}
	); 
    
  }


}