import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common'
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	APP_URI = globalConstant.APP_URI;
	authData:any;	
	dashboardData:any;	
	loading = false;
   
   
   		
	constructor(private mainService: MainService,   private router: Router, private confirmationDialogService: ConfirmationDialogService,  private toastr: ToastrService,public datepipe: DatePipe,private translate: TranslateService) { }
	reject_interest(id){
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Wollen Sie wirklich ablehnen?','Ablehnen','Later')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.reject_interest(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									 this.router.navigate(['/interest']);
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
			
		
	}
	accept_interest(id){
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Willst du das wirklich akzeptieren?','Interessiert','Later')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.accept_interest(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									 this.router.navigate(['/interest']);
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
		
	}
	
	markAsIntrested(id) {
		let authData= JSON.parse(localStorage.getItem('authUser')).data;
		if(authData.si == null) 
		{	
			this.router.navigate(['/subscribe-plan']);
		}
		else {
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Möchten Sie sich wirklich für dieses Suchprofil interessieren?','Bestätigen','Später')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						this.loading = true;
						this.mainService.company_inserested(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									 
									this.toastr.success('Interessenten erfolgreich markiert!');
									this.router.navigate(['/matches']);
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
		
		}
		 
	}
 

markAsHide(id) {
		
		let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.hide_match(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									 
									this.toastr.success('Das Spiel wurde erfolgreich ausgeblendet!');
									this.router.navigate(['/matches']);
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
		
		 
		
		
		 
	}
 
	CheckDate(d){
	 
	  var date =new Date();
	  return (this.datepipe.transform(d, 'yyyy-MM-dd') == this.datepipe.transform(date, 'yyyy-MM-dd'));
  }
	startChat(id) {
		this.router.navigate(['/chat'], { queryParams: { id: id } });
	}
  ngOnInit(): void {
	  
	  if(localStorage.getItem('authUser')){
		  //color-theme
		  this.authData= JSON.parse(localStorage.getItem('authUser')).data;
		  if(this.authData.icp != 2){
				if(this.authData.uri == 2 ) this.router.navigate(['/create-search-profile']);
				if(this.authData.uri == 3 ) this.router.navigate(['/create-search-order']);
		  } else {
			  this.loading 	= true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;  
		let mst =JSON.parse(localStorage.getItem('authUser')).mst;  
		this.mainService.dashboard(token).subscribe(response=>{ 
			this.loading = false;
			if(response.status == 'success'){
				var res = {};
				res['data'] 	= response.authData;
				res['token'] 	= token;
				res['mst'] 		= mst;
				localStorage.setItem('authUser', JSON.stringify(res));
				this.dashboardData = response.data;
				
				if(this.dashboardData && this.dashboardData.notifications){
					localStorage.setItem('notifications',JSON.stringify(this.dashboardData.notifications));
				}
			}
			},
			(error) => {                               
				this.loading = false;
			}); 	
			  
		  }
    }
	
	 
  }

}
