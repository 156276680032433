import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MainService } from 'src/app/main.service';
@Injectable()
export class AuthGuard implements CanActivate {
	
    constructor(private router: Router, private mainService: MainService,  ) {
		if(localStorage.getItem('authUser')){
			 
				let token = JSON.parse(localStorage.getItem('authUser')).token;
				this.mainService.callback(token).subscribe(r => { 
				if(r.status == 'success'){
					var res = {};
					res['data']	= r.data;
					res['token'] = token;
					res['mst'] 	= r.mst;
					localStorage.setItem('authUser', JSON.stringify(res));
				}
				else {
					localStorage.removeItem('authUser');
					window.location.reload(true);
				}
				return r;},(e) => {localStorage.removeItem('authUser');}); 
   
				
			
        }
	}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		 
          if(localStorage.getItem('authUser')){
			let authData= JSON.parse(localStorage.getItem('authUser')).data;
			 
			if(route.data.role !=  0 && route.data.role != authData.uri){
				this.router.navigate(['/dashboard']);
				return false;
			}
			return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
