import { Component, OnInit,Input, EventEmitter, Output,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'search-profile-step-5',
  templateUrl: './search-profile-step-5.component.html',
   styles: ['.theme-btn .back-btn { background-color: #003661; border-radius: 0; padding: 6px 20px 8px 20px; border-radius: 10px; font-size: 17px; color: #ffffff; font-weight: 500; width: auto; min-width: 140px; }']
})
export class SearchProfileStep5Component implements OnInit {
	@Output() setPStep = new EventEmitter();	
	@Output() setPid = new EventEmitter();
	@Output() setStepData = new EventEmitter();  
	 
	@Input() formData;
	@Input() pId;
	form :FormGroup;
	filedList = ['contract_type','working_hours','salary','vacation','weekend_work'];
	static_features:any; 
	special_features = []; 
	spf = []; 
	submitted = false;
	loading = false;
	errors:any;
	token:any;
	mst:any;
	select2width='100%';
	select2option1={dropdownAutoWidth:true,minimumResultsForSearch:6,placeholder:'Please Select'};
	select2option2={multiple:true,dropdownAutoWidth:true,minimumResultsForSearch:6,placeholder:'Please Select'};
	 
	
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
		
  }

 
 
ngAfterViewInit() {
window.scrollTo(0,0);		 
	if(this.formData.special_features.length==0){
			this.setPStep.emit(6);
	}
  }

	ngOnInit(): void {
   
		 
			if(this.formData.special_features.length==0){
				this.setPStep.emit(6);
			}
			this.static_features = this.formData['static_features'];	
			this.special_features = this.formData['special_features'];	
			//this.spf = this.formData['spf'];	
			
			const group: any = {};
			var df = [];
			var df1 = '';
			group['step'] =  new FormControl(5);
			group['occupation_id'] =  [this.formData.occupation_id];
			this.special_features.forEach(feature => {
				if(feature.type_of_feature== 4 || feature.type_of_feature== 5) {
					 
					if(this.formData['spf'][feature.id]){
						df = JSON.parse(this.formData['spf'][feature.id]);
					}else {
						df = [feature.default_value];
					}
					group['feature' +feature.id] =  this.formBuilder.array(df, Validators.required);
					this.spf['feature' +feature.id] =  df;
				} else {
					
					if(this.formData['spf'][feature.id]){
						df1 = this.formData['spf'][feature.id];
					}
					else {
						df1 = feature.default_value;
					}
					group['feature' + feature.id] =  [df1, Validators.required];
					this.spf['feature' +feature.id] =  df1;
				}
			});
			this.form =  this.formBuilder.group(group);	
			
		
		
	 
		
	
	}
   
 get af() { return this.form.controls; }
 
valueChanged(e,fld) {
	 const checkboxFld: FormArray = this.form.get(fld) as FormArray;
	
	  while (checkboxFld.length !== 0) {
		checkboxFld.removeAt(0)
	  }

	// checkboxFld.value = [];
	 e.forEach(value => {
			checkboxFld.push(new FormControl(value));	
		 });
	 
	 
    // this.form.patchValue({fld: e});
  }
  
onCheckboxChange(e,fld) {
	//console.log(this.form.get(fld));
	if(this.formData.special_features.length > 0){
		const checkboxFld: FormArray = this.form.get(fld) as FormArray;
		if (e.target.checked) {
			checkboxFld.push(new FormControl(e.target.value));
		} else {
		   const index = checkboxFld.controls.findIndex(x => x.value === e.target.value);
		   checkboxFld.removeAt(index);
		} 
	}
	
     
  }
  goBack (){
	  this.setPStep.emit(4);
  }
  isChecked(v,fld) {
	  if(this.formData.special_features.length == 0){
		  return false;
	  }
	 // console.log(this.form.get(fld).value);
	  //return false;
	return this.form.get(fld).value.includes(v.toString());
	 
  }
   private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
	if(firstInvalidControl){
		
		firstInvalidControl.focus(); //without smooth behavior
	}
  }
 

  onSubmit(){
	    
    if(this.form.invalid){
	  this.submitted = true;
	  this.scrollToFirstInvalidControl();
      return
     }	 
	 this.errors = false;
	 this.submitted = false;
	 
	this.loading = true;
	
	 this.mainService.save_search_profile(this.token,this.pId,this.form.value).subscribe(
		response => { 
		 this.loading = false;
			this.submitted = true;
			if(response.status == 'success'){
				 
				this.setPid.emit(response.data.id);
				this.setPStep.emit(response.step);
				this.setStepData.emit(response.data);
				
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
				this.scrollToFirstInvalidControl();
			}
		},
        (error) => { 
 		
            
		  this.loading = false;
		}
	); 
    
  }


}