import { Component, OnInit} from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {

	APP_URI = globalConstant.APP_URI;
	loading = false;
	submitted = false;
	stepData:any;
	payment_method =1; 
	plan_id:any; 
	Company:any; 
	
	 
	 
	constructor(private mainService: MainService,  private toastr: ToastrService,private router: Router, private confirmationDialogService: ConfirmationDialogService) { }
	changeMethod(m){
		this.payment_method = m;
	}
	changePlan(m){
		this.plan_id = m;
	}
 
 

	buy_now(){
		
		 
			this.loading = true;
			let token =JSON.parse(localStorage.getItem('authUser')).token;
			this.mainService.buy_now(token,this.plan_id,this.payment_method).subscribe(
				response => { 
					
					if(response.status == 'success'){	
						if(this.payment_method == 1){					
							window.location.href = response.data;
							this.loading = false;
						} else {
							let mst =JSON.parse(localStorage.getItem('authUser')).mst;
							var res = {};
							res['data'] 	= response.data;
							res['token'] 	= token;
							res['mst'] 		= mst;
							localStorage.setItem('authUser', JSON.stringify(res));
							this.router.navigate(['/dashboard']);
							this.loading = false;
							window.location.reload();
						}
						
					}
					else 
					{
						this.loading = false;
						this.toastr.error(response.msg); 
					}
		   
				},
				(error) => {                              
					
					this.loading = false;
				   
			});
		 
  
	}
	loadSubscription(){
		this.loading = true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		let authData =JSON.parse(localStorage.getItem('authUser')).data;
		if(authData.si == null){
		this.mainService.subscriptions(token).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){	 
					this.stepData	= response.data;
					this.Company	= response.Company;
					
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
		}
		else {
			this.router.navigate(['/dashboard']);
		}
	}

  ngOnInit(): void {
	   this.loading = true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		let mst =JSON.parse(localStorage.getItem('authUser')).mst;
		const urlParams = new URLSearchParams(window.location.search);
		
		 
		let id = urlParams.get('enc');  
		if(id){
			
				this.mainService.purchase_plan(token, id).subscribe(
					response => { 
						this.loading = false;
						if(response.status == 'success'){
							var res = {};
							res['data'] 	= response.data;
							res['token'] 	= token;
							res['mst'] 		= mst;
							localStorage.setItem('authUser', JSON.stringify(res));
							this.router.navigate(['/dashboard']);
							
						}
						else 
						{
							this.toastr.error(response.msg); 
							this.loadSubscription();
						}
					},
					(error) => {                              
						this.loading = false;	
						this.loadSubscription();						
					});
			
			 
		} else {
			this.loadSubscription();
			
		}
	   
		
		
		
		
  }

}
