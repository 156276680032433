import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  privacyContent:any;
  slug:any;
  loading = true;
  constructor(private titleService:Title,
    private mainService: MainService, 
    private router: Router,) { }

  ngOnInit(): void {
    this.loading=true;  
    this.slug = this.router.url.split('/');
    this.mainService.getCmsContent(this.slug[1]).subscribe(res => {
      this.privacyContent = res.data;   
	  this.titleService.setTitle(this.privacyContent.title); 
      this.loading=false;  
    });
  }

}

