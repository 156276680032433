import { Component, OnInit } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { CookieService  } from 'ngx-cookie-service'; 
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  
	EmployerSignup 	 = true;
	step = 0 ;
	apllicantForm :FormGroup;
	employerForm :FormGroup;
	user: SocialUser;
	loggedIn: boolean;
	submitted = false;
	loading = false;
	errors:any;
	stepData:any;
	checkCookie:any;
	constructor(private authService: SocialAuthService, private mainService: MainService, private formBuilder: FormBuilder,private toastr: ToastrService,private router: Router, private confirmationDialogService: ConfirmationDialogService,private cookies: CookieService) {}  
	signInWithGoogle(): void {	this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);	}
	signInWithFB(): void {	this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);	}
	signOut(): void {	this.authService.signOut();	}
	refreshToken(): void {	this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);	}
	get af() { return this.apllicantForm.controls; }
	get ef() { return this.employerForm.controls; }
	changeEmployer(tab){
		if(tab == 1){
			this.EmployerSignup = true;
		}
		else {
			this.EmployerSignup = false;
		}
	}  

	showPopup(slug){
	  this.loading=true;
	  let that = this;
	   this.mainService.getCmsContent(slug).subscribe(res => {
		   
		   that.confirmationDialogService.confirm(res.data.title, res.data.body,'Close','','lg');
			this.loading=false;  
    });
	return false;
	  
  }
	ngOnInit(): void {
	   this.checkCookie = this.cookies.check('accept_cookie');
		if(localStorage.getItem('authUser')){
			this.router.navigate(['/dashboard']);
		}
	  
		const urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('enc');  
		let emp = urlParams.get('emp');  
		if(id){
			this.loading = true;
			if(emp){
				this.mainService.employer_verification({enc:id}).subscribe(
					response => { 
						this.loading = false;
						if(response.status == 'success'){
							this.step = 2 ;
							this.stepData 			= response.data;
						}
						else 
						{
							this.toastr.error(response.msg); 
						}
					},
					(error) => {                              
						this.loading = false;			  
					});
			}
			else {
				this.mainService.applicant_verification({enc:id}).subscribe(
					response => { 
						this.loading = false;
						if(response.status == 'success'){
							this.step = 1 ;
							this.stepData 			= response.data;
						}
						else 
						{
							this.toastr.error(response.msg); 
						}
					},
					(error) => {                              
						this.loading = false;			  
					});
				
			}
		}
	   if( this.checkCookie){
		this.authService.authState.subscribe((user) => {
			if(user != null){
				this.loading = true;
				if(this.EmployerSignup) {
					this.mainService.employer_social(user).subscribe(
						response => { 
						this.loading = false;
						this.authService.signOut();
							if(response.status == 'success'){
								
								
								
								
								if(response.data.icp == 0){
									this.step = 2 ;
									var d = response.data; d["cn"] = response.mst.countries;
									this.stepData 			= d;
								} else if(response.data.icp == 1){
									var res = {};
									res['data'] 	= response.data;
									res['token'] 	= response.token;
									res['mst'] 		= response.mst;
									localStorage.setItem('authUser', JSON.stringify(res));
									
									this.router.navigate(['/create-search-order']);
								} else {
									var res = {};
									res['data'] 	= response.data;
									res['token'] 	= response.token;
									res['mst'] 		= response.mst;
									localStorage.setItem('authUser', JSON.stringify(res));
									
									this.router.navigate(['/dashboard']);
								}
								
								
								
								
							
							}
							else {
								this.errors = response.errors;
								if(response.msg){
									this.toastr.error(response.msg);  
								}
							}
					   
						},
						(error) => {                              
						  
						  this.loading = false;	
					
						   
						}); 
				}
				else {
					this.mainService.applicant_social(user).subscribe(
						response => { 
						this.loading = false;
						this.authService.signOut();
							if(response.status == 'success'){
								 
								if(response.data.icp == 0){
									this.step = 1 ;
									var d = response.data; d["cn"] = response.mst.countries;
									this.stepData 			= d;
								} else if(response.data.icp == 1){
									var res = {};
									res['data'] 	= response.data;
									res['token'] 	= response.token;
									res['mst'] 		= response.mst;
									localStorage.setItem('authUser', JSON.stringify(res));
									
									this.router.navigate(['/create-search-profile']);
								} else {
									var res = {};
									res['data'] 	= response.data;
									res['token'] 	= response.token;
									res['mst'] 		= response.mst;
									localStorage.setItem('authUser', JSON.stringify(res));
									
									this.router.navigate(['/dashboard']);
								}
								
								
								
								 
							}
							else {
								this.errors = response.errors;
								if(response.msg){
									this.toastr.error(response.msg);  
								}
							}
					   
						},
						(error) => {                              
						  
						  this.loading = false;	
					
						   
						}); 
	   
				}
				
			}
		});
	   }
		 
		this.apllicantForm = this.formBuilder.group({
		  email: ['',Validators.required],
		  password : ['',Validators.required],
		  toc : ['',Validators.required]
		});
		this.employerForm = this.formBuilder.group({
		  email: ['',Validators.required],
		  password : ['',Validators.required],
		  toc : ['',Validators.required]
		});
		
   
  }
  
 
  onSubmit(){
    if(this.apllicantForm.invalid){
	  this.submitted = true;
      return
     }
	 
	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true;
	 this.mainService.applicant_registration(this.apllicantForm.value).subscribe(response=>{ 
		this.submitted = true;
		this.loading = false;
		if(response.status == 'success'){
			this.toastr.success(response.msg);
			this.router.navigate(['/']);
			this.apllicantForm.reset();		
		  }
		  else {
			 
			this.errors=response.errors;
			if(response.msg){
				this.toastr.error(response.msg);  
			}
		  }
       
    }
	,
        (error) => {                               
           
		this.loading = false;		  
    
           
        }
	
	
	); 
   
  }
  
 onSubmit1(){
    if(this.employerForm.invalid){   
	  this.submitted = true;
      return
     }
	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true;
	 this.mainService.employer_registration(this.employerForm.value).subscribe(response=>{ 
		this.submitted = true;
		this.loading = false;
		if(response.status == 'success'){
			this.toastr.success(response.msg);
			this.router.navigate(['/']);
			this.employerForm.reset();		
		  }
		  else {
			 
			this.errors=response.errors;
			if(response.msg){
				this.toastr.error(response.msg);  
			}
		  }
       
		},
        (error) => {                               
			this.loading = false;
        }); 
  }
  

}
