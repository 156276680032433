import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {
  cmsContent:any;
  slug:any;
  loading = false;
  constructor(
    private titleService:Title,
    private mainService: MainService, 
    private router: Router, 
  ) {
    
  }

  ngOnInit() {
    this.loading=true;  
    this.slug = this.router.url.split('/');
    this.mainService.getCmsContent(this.slug[1]).subscribe(res => {
      this.cmsContent = res.data;   
	  this.titleService.setTitle(this.cmsContent.title); 
      this.loading=false;  
    });
  }
}
