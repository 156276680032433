<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
    <div *ngFor="let message of messages">
        {{message}}
    </div>
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup">
                    <div class="heading_new">{{'Chats' | translate}}</div>
                    <div class="searchOrderBtnGroup" *ngIf="noRoomUserlist.length > 0">
                        <button class="newThemeBtn"  data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <i class="far fa-comments"></i>
                            {{'Start New Chat' | translate}}
                        </button>
                    </div>

                </div>


<!-- Modal -->
<div class="modal themeModal  fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{'Start New Chat' | translate}}</h5>
		<button type="button"    data-bs-dismiss="modal" aria-label="Close" class="btn-close"> × </button>
       
      </div>
      <div class="modal-body" style="max-height:400px;overflow:auto;">
         <div class="notify-left1">

                            <div class="form-search" *ngIf="noRoomUserlist.length > 0">
                                <div class="form-group m-0 position-relative">
                                    <input type="search" [(ngModel)]="searched" (keyup)="filterby()" placeholder="{{'Seek...' | translate}}" class="form-control" />
                                
                                    <button type="submit" class="searchBtn" (click)="filterby()"><i class="far fa-search"></i></button>
                                </div>
                            </div>
							
								<ng-container *ngIf="( noRoomUserlist |  filter:searchedKeyword) as result">
   <div  *ngIf="result.length === 0" ><div style="text-align:center;">{{'No User Found!' | translate}}</div> </div>
</ng-container>
<ul class="notify-listing chat-users" *ngIf="noRoomUserlist.length > 0 ; else elseblock2">
                                   
										<li *ngFor="let user of noRoomUserlist | filter:searchedKeyword" (click)="startNewChat(user.id,'active')"  data-bs-dismiss="modal" >
											<div class="notifyuser-list">
												<figure>
													<img src="{{ user.image }}" alt="" />
												</figure>
												<span [ngClass]="user.is_online == 1 ? 'staturChat online' : 'staturChat offline'">
													<div class="active-dot"></div>
													<div [ngClass]="user.is_online == 1 ? 'active-dot-onlines' : 'active-dot-offline'"></div>
												</span>
												<div class="usernames">{{ user.name }}</div>
												 
											</div>
											 
										 
											 
											</li> 
		
		
		
											 
									</ul>
									<ng-template #elseblock2>
										<div style="text-align:center;">{{'No User Found!' | translate}}</div>
									</ng-template>
                            
							  
                        </div>
                       
      </div>
       
    </div>
  </div>
</div>

                <div class="col-12 paddingSmall">
                    <div class="notify-wrapper" [ngClass]="chatShow ? 'chat_open' : '' ">
                        <div class="notify-left" [ngClass]="chatarrow ? 'chat_arrow' : '' ">

                            <div class="form-search">
                                <div class="form-group m-0 position-relative">
                                    <input type="search" [(ngModel)]="search" (keyup)="user_search.next($event)" placeholder="{{'Seek...' | translate}}" class="form-control chat-input" />
                                
                                    <button type="submit" class="searchBtn"><i class="far fa-search"></i></button>
                                </div>
                            </div>

                            <!-- === list of filter chat -->

							<ul class="nav nav-tabs messageFilterUsr" id="myTab" role="tablist">
								<li class="nav-item" role="presentation">
								  <button [ngClass]="activeChatType == 'active' ? 'nav-link active' : 'nav-link'"   id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab"
								   aria-controls="home" aria-selected="true">{{'Chats' | translate}}</button>
								</li>
								<li class="nav-item" role="presentation">
								  <button [ngClass]="activeChatType == 'archive' ? 'nav-link active' : 'nav-link'"id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
								   type="button" role="tab" aria-controls="profile" aria-selected="false">{{'Archiv' | translate}}</button>
								</li>
							
							  </ul>
							  <div class="tab-content" id="myTabContent">
								<div [ngClass]="activeChatType == 'active' ? 'tab-pane fade show active' : 'tab-pane fade'" id="home" role="tabpanel" aria-labelledby="home-tab" style="min-height:400px;">


									<ul class="notify-listing chat-users" *ngIf="activeUserlist.length > 0 ; else elseblock">
                                   
										<li *ngFor="let user of activeUserlist" (click)="getRecentChat(user.id,'active')" >
											<div class="notifyuser-list active" >

												<figure>
													<img src="{{ user.image }}" alt="" />
												</figure>
												<span [ngClass]="user.is_online == 1 ? 'staturChat online' : 'staturChat offline'">
													<div class="active-dot"></div>
													<div [ngClass]="user.is_online == 1 ? 'active-dot-onlines' : 'active-dot-offline'"></div>
												</span>
												<div class="usernames">{{ user.name }}</div>
												<div class="someMessage">{{ user.last_message }}</div>
											</div>
											<div class="notify-times" *ngIf="CheckDate(user.last_message_time)">{{ user.last_message_time | date : 'shortTime' }}<span class="msgNotCnt" *ngIf="user.unread > 0">{{user.unread}}</span></div>
											<div class="notify-times" *ngIf="CheckDate(user.last_message_time) == false">{{ user.last_message_time | date : 'shortDate' }}</div>
											<div class="notify-times">
												<span class="msgNotCnt" *ngIf="user.unread_count > 0">{{user.unread_count}}</span>
											</div>
											

											<!-- <div class="mover" data-toggle="tooltip" data-placement="right" title="Move to Archive">
												<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="share" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-share fa-w-16 fa-2x"><path fill="currentColor" d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z" class=""></path></svg>
											</div> -->

											</li> 
		
		
		
											<!-- <li>fhu</li> -->
									</ul>
									<ng-template #elseblock>
										<div class="error-notfound">{{'No messages found.' | translate}}</div>
									</ng-template>
		
								</div>
								<div  [ngClass]="activeChatType == 'archive' ? 'tab-pane fade show active' : 'tab-pane fade'" id="profile" role="tabpanel" aria-labelledby="profile-tab"  style="min-height:400px;">


									<ul class="notify-listing chat-users" *ngIf="archiveUserlist.length > 0 ; else elseblock1">
                                   
										<li *ngFor="let archiveUser of archiveUserlist" (click)="getRecentChat(archiveUser.id, 'archive')">
											<div class="notifyuser-list">
												<figure>
													<img src="{{ archiveUser.image }}" alt="" />
												</figure>
												<span  [ngClass]="archiveUser.is_online == 1 ? 'staturChat online' : 'staturChat offline'">
													<div class="active-dot"></div>
													<div class="active-dot-offline"></div>
												</span>
												<div class="usernames">{{ archiveUser.name }}</div>
												<div class="someMessage">{{ archiveUser.last_message }}</div>
											</div>
											<div class="notify-times" *ngIf="CheckDate(archiveUser.last_message_time)">{{ archiveUser.last_message_time | date : 'shortTime' }}<span class="msgNotCnt" *ngIf="archiveUser.unread > 0">{{archiveUser.unread}}</span></div>
											<div class="notify-times" *ngIf="CheckDate(archiveUser.last_message_time) == false">{{ archiveUser.last_message_time | date : 'shortDate' }}<span class="msgNotCnt" *ngIf="archiveUser.unread > 0">{{archiveUser.unread}}</span></div>
										
									
										 
											</li> 
		
		
		
											<!-- <li>fhu</li> -->
									</ul>

									<ng-template #elseblock1>
										<div class="error-notfound">{{'No messages found.' | translate}}</div>

									</ng-template>
								</div>
							  </div>
                            <!-- form-search END -->
                        

                        </div>
                        <!-- notify-left END -->
                        <div class="notify-right" *ngIf="showRecentChat">
							<div class="notify-users">
								<div class="notify-users-left">
									<div>{{ activeUser.name }}
										<span class="staturChat staturChat1  online" *ngIf="activeUser.is_online == 1">
											<div class="active-green"></div>{{'Online' | translate}}
										</span>
										<span class="staturChat staturChat1 offline" *ngIf="activeUser.is_online != 1">
											<div  class="active-gray"></div>{{'Offline' | translate}}
										</span>
									</div>
								</div>
<div class="arrow-div"> 
								<a *ngIf="activeUser.room_status == 1" href="javascript:void(0);" class="mover" (click)="update_chat_status(activeUser.id,0)">
									{{'Move to Archive' | translate}}
								</a>
								
								<a *ngIf="activeUser.room_status == 0" href="javascript:void(0);" class="mover" (click)="update_chat_status(activeUser.id,1)">
									{{'Move to Active' | translate}} 
								</a>

								<span class="mover_arrow" (click)="chatarrowtoggle()">
									<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" 
									xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path></svg>
								</span> 
								</div>
								<!-- <a href="#"  class="mover" >
									Move to Active
								</a> -->
								<!-- <div class="notify-users-right">
									<span class="active-time">
											02:30 pm
										</span>
								</div> -->
							</div>
							 <!-- notify-users END -->

							<div class="chat-box">
								<div class="chat-room dashChatChattingInner" #chatBox>
								   
								   <ng-container *ngIf="activeChat && activeChat.length > 0">
										<div *ngFor="let chat of activeChat">
											
											<div class="message message-left" *ngIf="chat.from_user_id == selected_user_id && chat.to_user_id == auth_id">
												<div class="avatar-wrapper avatar-small">
													<img src="{{ activeUser.image }}" alt="avatar" />
												
												</div>
												<div class="bubble bubble-light">
													{{ chat.message }}
												</div>
												<span class="date-time">{{ chat.created_at | date : 'medium' }}</span>
											</div>

											<div class="message message-right" *ngIf="chat.from_user_id == auth_id && chat.to_user_id == selected_user_id">
												<div class="bubble bubble-dark">
													{{ chat.message }}
												</div>
												<span class="date-time">{{ chat.created_at | date : 'medium' }}</span>
											</div>
											
										</div>
								   </ng-container> 

									<div class="datewise-row" *ngIf="1>2">
										<span>{{'Today' | translate}}</span>
									</div>

								</div>
								<div *ngIf="activeUser.room_status == 0" class="alert alert-info">{{'This chat is archived you cannot send message.' | translate}}</div>
								<div class="type-area" *ngIf="activeUser.room_status == 1">
									<div class="input-wrapper">
										<input type="text" [(ngModel)]="message" id="inputText" placeholder="{{'Write your reply here...' | translate}}" (keyup.enter)="sendMessage(room_id)"/>
									</div>
									<div class="chat-btn-set">
										<a href="#!" class="plus-icon" *ngIf="1>2"><i class="fal fa-smile"></i></a>
										<a href="#!" class="camera-icon" *ngIf="1>2"><i class="fal fa-paperclip"></i></a>
										<button type="submit" class="button-send" (click)="sendMessage(room_id)"><i class="fas fa-paper-plane"></i></button>
									</div>
								</div>
							</div>
						<!-- card-block END -->
                        </div>
                        <!-- notify-right END -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- min page box  end-->
</main>