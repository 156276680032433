import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards';
 
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { HomeComponent } from './component/home/home.component';
import { EmployerHomeComponent } from './component/ehome/ehome.component';


import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { CreateSearchProfileComponent } from './component/create-search-profile/create-search-profile.component';
import { CreateProfileComponent } from './component/create-profile/create-profile.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { CmsComponent } from './component/cms/cms.component';
import { FaqComponent } from './component/faq/faq.component';
import { ContactComponent } from './component/contact/contact.component';
import { PrivacyComponent } from './component/privacy/privacy.component';
 
import { ProfilesComponent } from './component/profiles/profiles.component';

import { CompanyProfileComponent } from './component/company-profile/company-profile.component';
import { SearchOrdersComponent } from './component/search-orders/search-orders.component';
import { MatchesComponent } from './component/matches/matches.component';
import { MyProfileComponent } from './component/my-profile/my-profile.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { InterestComponent } from './component/interest/interest.component';
import { ChatComponent } from './component/chat/chat.component';
import { BillingComponent } from './component/billing/billing.component';
import { CreateSearchOrderComponent} from './component/create-search-order/create-search-order.component';
import { PaymentDetailComponent } from './component/payment-detail/payment-detail.component';
import { SubscriptionPlanComponent } from './component/subscription-plan/subscription-plan.component';

import { LoginComponent } from './component/login/login.component';
import { SignupComponent } from './component/signup/signup.component';
import { ListingComponent } from './component/listing/listing.component';



const routes: Routes = [
 
	{path: "", 						component: HomeComponent},
	{path: "for-companies",			component: EmployerHomeComponent},
	
	{path: "login",					component: LoginComponent},
	{path: "signup",				component: SignupComponent},
	
	 
	{path: "forgot-password",		component: ForgotPasswordComponent},
	{path: "reset-password",		component: ResetPasswordComponent},
 
	{path: 'terms',					component: CmsComponent},
	{path: 'faqs',					component: FaqComponent},
	{path: 'contact',				component: ContactComponent},
	{path: 'privacy',				component: PrivacyComponent},
	{path: 'cookie-policy',			component: PrivacyComponent},
	{path: 'data-protection',		component: PrivacyComponent},
	
	//{path: "company-profile",	component: CompanyProfileComponent,		canActivate: [AuthGuard] , data: {role: 3}}, 
	{path: "search-orders",		component: SearchOrdersComponent,		canActivate: [AuthGuard] , data: {role: 3}}, 
	{path: "create-search-order",	component: CreateSearchOrderComponent,	canActivate: [AuthGuard] , data: {role: 3}}, 
	{path: "add-search-order",	component: CreateSearchOrderComponent,	canActivate: [AuthGuard] , data: {role: 3}}, 
	{path: "edit-search-order",	component: CreateSearchOrderComponent,	canActivate: [AuthGuard] , data: {role: 3}}, 
	
	
	{path: "candidate-profiles",	component: ProfilesComponent,			canActivate: [AuthGuard] ,data: {role: 2}},
	{path: "create-search-profile",	component: CreateSearchProfileComponent,canActivate: [AuthGuard] ,data: {role: 2}}, 
	{path: "add-search-profile",	component: CreateProfileComponent,		canActivate: [AuthGuard] ,data: {role: 2}}, 
	{path: "edit-search-profile",	component: CreateProfileComponent,		canActivate: [AuthGuard] ,data: {role: 2}}, 
	
	{path: "dashboard",				component: DashboardComponent,			canActivate: [AuthGuard] ,data: {role: 0}},
 
	 
 
	/*  { 
		 
		 path: "profiles",
		 component: ProfilesComponent, 
		 canActivate: [AuthGuard], 
		 data: { role:  } 
	}, 
	  */
	{ 
		path: "company-profile",
		component: CompanyProfileComponent,	canActivate: [AuthGuard] , data: {role: 3}
	},
	 
	{ 
		path: "matches",
		component: MatchesComponent,	canActivate: [AuthGuard] , data: {role: 3}
	},
	{ 
		path: "my-profile",
		component: MyProfileComponent,	canActivate: [AuthGuard] , data: {role: 2}
	},
	{ 
		path: "change-password",
		component: ChangePasswordComponent,	canActivate: [AuthGuard] , data: {role: 0}
	},
	{ 
		path: "interest",
		component:InterestComponent,	canActivate: [AuthGuard] , data: {role: 2}
		 
	},
	{ 
		path: "chat",
		component:ChatComponent,	canActivate: [AuthGuard] , data: {role: 0}
		 
	},
	{ 
		path: "billing",
		component:BillingComponent,	canActivate: [AuthGuard] , data: {role: 3}
		 
	},
	{ 
		path: "payment-detail",
		component:PaymentDetailComponent,	canActivate: [AuthGuard] , data: {role: 3}
		 
	},
	{ 
		path: "subscribe-plan",
		component:SubscriptionPlanComponent,	canActivate: [AuthGuard] , data: {role: 3}
		 
	},

	{path: 'listing',			
		component: ListingComponent},
 
	//{path: '**', redirectTo: '/'}

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
