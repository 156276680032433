import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {ChatService} from 'src/app/services/chat.service';
import {UserList,UserRecentChat} from 'src/app/classes/user'; 
import { DatePipe } from '@angular/common';
import { Observable, Subscription , Subject } from "rxjs";
import { debounce, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
	
  @ViewChild('chatBox') private myScrollContainer: ElementRef;	
	
currentdate: Date;
  message: string;
  messages: string[] = [];
  authData:any;
  loading=true;
  activeUserlist: Array<any>    = [];
  archiveUserlist: Array<any>    = [];
  noRoomUserlist: Array<any>    = [];

  activeChat: Array<any>  = [];
  search: string;
  activeUser: any;
  showRecentChat: boolean = false;
  activeChatType: string = 'active';
  isLogin: boolean = false;
  room_id:number;
  chatarrow: boolean = false;
  chatShow: boolean = false;
  auth_id:number;
  selected_user_id:number;
  user_search: Subject<any> = new Subject<any>();  
  searchedKeyword: string;
  searched: string;
  constructor(
      private userListData:UserService,
      private chatService:ChatService,
      public datepipe: DatePipe,
	  public toastr : ToastrService,
	  private translate: TranslateService
  ) { 
		if(localStorage.getItem('authUser')){
			//alert(123);
			this.isLogin 	=	true;
			this.authData	= 	JSON.parse(localStorage.getItem('authUser')).data;
			this.auth_id	=	this.authData.i;	
			
			this.initSocket();
		}
		
		this.user_search.pipe(debounceTime(1000)).subscribe(searchText => {
			if(searchText.target.value.length>="2"){
				this.get_user_list(searchText.target.value);
			} 
			if(searchText.target.value.length=="0"){
				this.get_user_list();
			} 
			
		});
		
		this.currentdate = new Date();	

		
		this.chatService.getMessage();
  }
 
  filterby() {
		this.searchedKeyword = this.searched ;
		
	} 	
 CheckDate(d){
	 
	  var date =new Date();
	  return (this.datepipe.transform(d, 'yyyy-MM-dd') == this.datepipe.transform(date, 'yyyy-MM-dd'));
  }

  sendMessage(room_id) {
    var date =new Date();
    var current_date = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    var message_data = 	{
                          room_id:room_id,
                          from_user_id:this.authData.i,
                          to_user_id:this.selected_user_id,
                          message:this.message,
                          created_at:current_date,
						};

    //console.log(message_data);
	this.activeChat.push(message_data);
	this.chatService.sendMessage(message_data);
    this.message = '';
	this.scrollToBottom();
  }

  ngOnInit(): void {
	  this.get_user_list();
	  this.chatService.getMessage().subscribe((messageData: any) => {
		  //console.log(messageData);
			if(messageData.last_message.data.from_user_id == this.selected_user_id && messageData.last_message.data.to_user_id == this.auth_id && this.activeUser.room_status == 1){
				this.activeChat.push(messageData.last_message.data);
				let requestBody = {'id':messageData.last_message.data}
				//console.log("emit")
				this.chatService.updateMessageCounter(requestBody);
				this.scrollToBottom();
			}
			for(let i=0;i<this.activeUserlist.length;i++){
				if(this.activeUserlist[i].id == messageData.last_message.data.from_user_id){
					var sender_name = this.activeUserlist[i].name;
				}
			}
			for(let i=0;i<this.archiveUserlist.length;i++){
				if(this.archiveUserlist[i].id == messageData.last_message.data.from_user_id){
					var sender_name = this.archiveUserlist[i].name;
				}
			}
			var msg =   this.translate.instant('MessageFromUser', {sender_name : sender_name});
			//this.toastr.success(messageData.last_message.data.message, msg);
			
			this.get_user_list();
	  });
	  
	
	 // this.toastr.success('asdffs', this.translate.get('MessageFromUser'));
  
  const urlParams = new URLSearchParams(window.location.search);
	let id = urlParams.get('id');  
		if(id){
			this.startNewChat(id,'active');
		}
  }

  
  chatToggle() {

  
    // let bodyParm = document. getElementsByTagName('body')[0];
    // if(this.chatShow == true){
    //    bodyParm.classList.add('overflow');
    // }else{
    //   bodyParm.classList.remove('overflow');
    // }
    
  }
  chatarrowtoggle() {

	this.chatarrow = !this.chatarrow;
	this.chatShow = false;

    // let bodyParm = document. getElementsByTagName('body')[0];
    // if(this.chatShow == true){
    //    bodyParm.classList.add('overflow');
    // }else{
    //   bodyParm.classList.remove('overflow');
    // }
    
  }
  broadCastOnlineUser(){
	 let requestBody 	=	{'id':this.authData.i};
	 this.chatService.broadCastUserOnline(requestBody); 
  }
  
  get_user_list(search_user : any = ''){
	  console.log(search_user)
	  if(this.isLogin){
		  let requestBody:any 	=	{'user_id':this.authData.i,'search':search_user};
		  //console.log(requestBody);
		  this.userListData.user_list(requestBody).subscribe((result)=>{
			if(result.response == 'success'){
			  this.activeUserlist = result.data;
			  this.archiveUserlist = result.archive_data;
			  this.noRoomUserlist = result.no_room_data;

			  this.loading = false;
			}else{
				this.activeUserlist = [];
				this.archiveUserlist = [];
				this.noRoomUserlist = [];
				this.showRecentChat = false;
				 this.loading = false;
			}
		  }); 
	  }
  
 
 
  }
  
  initSocket(){
	 this.chatService.initSocket(true,{'id': this.authData.i}); 
  }
  
  getRecentChat(selected_user_id, type){

	  if(this.isLogin){
		this.chatShow = !this.chatShow;
		this.chatarrow = false;
		this.selected_user_id	=	selected_user_id;
		this.activeChat 		=	[];
		var requestBody 		=	{'current_user_id':this.authData.i, 'selected_user_id':this.selected_user_id};
		this.userListData.getRecentChats(requestBody).subscribe((result)=>{
		  if(result.response == 'success'){
			var $this = this;
			this.activeUserlist.findIndex(function (res, i) {
				if (res.id == selected_user_id) {
					$this.activeUserlist[i].unread_count = 0;
				}
			});
			this.archiveUserlist.findIndex(function (res, i) {
				if (res.id == selected_user_id) {
					$this.archiveUserlist[i].unread_count = 0;
				}
			});
			this.showRecentChat = 	true;
			this.activeChat		=	result.data;
			this.activeUser		=	result.user;
			this.room_id 		= 	result.room_id;
			this.activeChatType	=	type;
			
			if(this.activeChatType == 'active'){
				this.activeUser.room_status	=	1;	
			}else{
				this.activeUser.room_status	=	0;
			}
			
			this.scrollToBottom();
		  }
		}); 
	  }

  }  
  
  startNewChat(selected_user_id, type){
	if(this.isLogin){
		 this.selected_user_id	=	selected_user_id;
		this.activeChat 		=	[];
		var requestBody 		=	{'current_user_id':this.authData.i, 'selected_user_id':this.selected_user_id};
		this.userListData.getRecentChats(requestBody).subscribe((result)=>{
		  if(result.response == 'success'){
			var $this = this;
			this.activeUserlist.findIndex(function (res, i) {
				if (res.id == selected_user_id) {
					$this.activeUserlist[i].unread_count = 0;
					$this.activeChatType	=	'active';
				}
			});
			this.archiveUserlist.findIndex(function (res, i) {
				if (res.id == selected_user_id) {
					$this.archiveUserlist[i].unread_count = 0;
					$this.activeChatType	=	'archive';
				}
			});
			this.noRoomUserlist.findIndex(function (res, i) {
				if (res.id == selected_user_id) {
					$this.activeUserlist.push(res);;
					$this.activeChatType	=	'active';
				}
			});
			
			this.showRecentChat = 	true;
			this.activeChat		=	result.data;
			this.activeUser		=	result.user;
			this.room_id 		= 	result.room_id;
			//this.activeChatType	=	type;
			
			if(this.activeChatType == 'active'){
				this.activeUser.room_status	=	1;	
			}else{
				this.activeUser.room_status	=	0;
			}
			
			this.scrollToBottom();
		  }
		}); 
	}
	
  }
  
  scrollToBottom(): void {
	setTimeout(()=>{
		this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
	},10);               
  }
	
  update_chat_status(selected_user_id,status){
	let requestBody = {'current_user_id':this.auth_id, 'selected_user_id':selected_user_id, 'status':status};
	this.userListData.updateRoomStatus(requestBody).subscribe((result)=>{
		if(status == 1){
			this.activeChatType 		= 'active';
			this.activeUser.room_status	=	1;
		}else{
			this.activeChatType 		= 'archive';
			this.activeUser.room_status	=	0;
		}
		this.get_user_list();
	});
  }
  
}
