'use strict';
export var login_user_id,token;

export const SUPER_ADMIN_ROLE_ID=1; 
export const CURRENCY='$'
 
export const APP_URI1	=   'https://api.yourcraftjob.de/';
export const APP_URI	=   'https://yourcraftjob.stage02.obdemo.com/';
export const APP_URI2	=   'http://localhost/cake3/yourcraftjob/';
export const CHAT_URI	=   'https://yjcns.stage02.obdemo.com';
export const CHAT_URI1	=   'http://localhost:6023';
export const DOMAIN		=   APP_URI + 'api/1/';



if(sessionStorage.getItem('authUser')){
    token =JSON.parse(sessionStorage.getItem('authUser')).token;
    login_user_id=JSON.parse(sessionStorage.getItem('authUser')).data.id;
}else{
    token=login_user_id='';
}
export const image_path='assets/img/';
