import { Component, OnInit, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
 

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
APP_URI = globalConstant.APP_URI;
	loading = false;
	submitted = false;
	stepData:any;
	errors:any;
	countriesList:any;
	fileSource:any = {image:null};
	form :FormGroup;
	constructor(private mainService: MainService,  private formBuilder: FormBuilder,private toastr: ToastrService,private router: Router,private el: ElementRef) { }
	get af() { return this.form.controls; }
	onFileChange(event,fileIndex) {
        if (event.target.files.length > 0) {
			this.fileSource[fileIndex] = (event.target.files[0]);
			if(fileIndex == 'image') this.form.patchValue({'image':event.target.files[0]['name']});
			
		}
    }
	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
		if(firstInvalidControl){
			firstInvalidControl.focus(); //without smooth behavior
		}
	}
	
  ngOnInit(): void {
	 this.stepData = {'image' : null} ;
	  this.loading = true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		let mst =JSON.parse(localStorage.getItem('authUser')).mst;
		this.countriesList = mst.countries; 
		this.form = this.formBuilder.group({
						image : [''],
						phone_number : [''],
						first_name : ['',Validators.required],
						last_name : ['',Validators.required],
						email : ['',[Validators.required,Validators.email]],
						address :[''],
						city : [''],
						zip_code : [''],
						country_id : [''],
						
				 
						});
		this.mainService.my_profile(token).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){	 
					this.stepData	= response.data;
					
						this.form.patchValue({
						phone_number : this.stepData.phone_number,
						first_name : this.stepData.first_name,
						last_name : this.stepData.last_name,
						email : this.stepData.email,
						address : this.stepData.address,
						city : this.stepData.city,
						zip_code : this.stepData.zip_code,
						country_id : this.stepData.country_id
						});
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
			
			
  }
  onSubmit() {
	 
	   if(this.form.invalid){
			this.submitted = true;
			this.scrollToFirstInvalidControl();
			return;
		}
		else {
			this.errors = false;
			this.submitted = false;
			this.loading = true; 
			const formData = new FormData();
			
			formData.append('phone_number', this.form.get('phone_number').value);
			formData.append('first_name', this.form.get('first_name').value);
			formData.append('last_name', this.form.get('last_name').value);
			formData.append('email', this.form.get('email').value);
			formData.append('address', this.form.get('address').value);
			formData.append('city', this.form.get('city').value);
			formData.append('zip_code', this.form.get('zip_code').value);
			formData.append('country_id', this.form.get('country_id').value);
			
			if(this.fileSource['image'] !=null){
				formData.append('image', this.fileSource['image']);
			}
			
			let token =JSON.parse(localStorage.getItem('authUser')).token;
			this.mainService.save_my_profile(token, formData).subscribe(
				response => {
					this.loading = false; 
					this.submitted = true;
					if(response.status == 'success'){				
						this.toastr.success('Your profile is successfully updated.'); 
						this.router.navigate(['/dashboard']);
					}
					else {
						this.errors=response.errors;
						if(response.msg){
							this.toastr.error(response.msg);  
						}
						 this.scrollToFirstInvalidControl();
					}
				},
				(error) => {	
					this.loading = false;
				}
			); 
		}
	  
		return;
	}

}
