 <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
        &times;
    </button>
</div>
<div class="modal-body" [innerHtml]="message" style="max-height:450px;overflow:auto;">

</div>
<div class="modal-footer">
    <button type="button" class="newThemeBtn cancelTheme" (click)="decline()" *ngIf="btnCancelText">{{ btnCancelText }}</button>
    <button type="button" class="newThemeBtn" (click)="accept()">{{ btnOkText }}</button>
</div>