import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  errors:any;
  submitted= false;
  loading = false;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
	 private mainService: MainService,  
    private router: Router,) { }

  ngOnInit(): void {
  	if(localStorage.getItem('authUser')){
      this.router.navigate(['/dashboard']);
    }
   
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  get af() { return this.forgotPasswordForm.controls; }	
  onSubmit(){
	  this.submitted = true;
	  
    console.log(this.forgotPasswordForm.value);
    if(this.forgotPasswordForm.invalid){
     //this.toastr.error('Enter a Email');
     return
    }
	this.loading = true;
	this.submitted = false;
	 this.mainService.forgot_password(this.forgotPasswordForm.value).subscribe(response=>{ 
		this.submitted = true;
		this.loading = false;
		if(response.status == 'success'){
			  
				 this.toastr.success(response.msg);   
				this.router.navigate(['/']);
		  }
		  else {
			 
			this.errors=response.errors;
			if(response.msg){
				this.toastr.error(response.msg);  
			}
		  }
       
    }
	,
        (error) => {                               
           
            
		  this.loading = false;
    
           
        }
	
	
	); 
   

    //console.log('forgot password to reset password link',this.forgotPasswordForm.value);
    //this.router.navigate(['reset-password']);
  }

}
