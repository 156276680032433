import { Component, OnInit, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
 

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
	APP_URI = globalConstant.APP_URI;
	loading = false;
	submitted = false;
	stepData:any;
	errors:any;
	countriesList:any;
	fileSource:any = {logo:null, cover_image: null , video: null};
	form :FormGroup;
	constructor(private mainService: MainService,  private formBuilder: FormBuilder,private toastr: ToastrService,private router: Router,private el: ElementRef) { }
	get af() { return this.form.controls; }
	onFileChange(event,fileIndex) {
        if (event.target.files.length > 0) {
			//console.log(event.target.files);
			this.fileSource[fileIndex] = (event.target.files[0]);
			 
			if(fileIndex == 'logo') this.form.patchValue({'logo':event.target.files[0]['name']});
			if(fileIndex == 'cover_image') this.form.patchValue({'cover_image':event.target.files[0]['name']});
			if(fileIndex == 'video') this.form.patchValue({'video':event.target.files[0]['name']});
		}
    }
	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
		if(firstInvalidControl){
			firstInvalidControl.focus(); //without smooth behavior
		}
	}
	
  ngOnInit(): void {
	 this.stepData = {'logo' : null} ;
	  this.loading = true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		let mst =JSON.parse(localStorage.getItem('authUser')).mst;
		this.countriesList = mst.countries; 
		this.form = this.formBuilder.group({
						logo : [''],
						cover_image : [''],
						video : [''],
						phone_number : [''],
						website : [''],
						facebook : [''],
						linkedin : [''],
						xing : [''],
						first_name : ['',Validators.required],
						description : ['',Validators.required],
						email : ['',[Validators.required,Validators.email]],
						address : ['',Validators.required],
						city : ['',Validators.required],
						zip_code : ['',Validators.required],
						country_id : ['',Validators.required],
						
				 
						});
		this.mainService.company_profile(token).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){	 
					this.stepData	= response.data;
					
						this.form.patchValue({
					//	logo : this.stepData.logo,
						//cover_image : this.stepData.cover_image,
						//video : this.stepData.video,
						phone_number : this.stepData.phone_number,
						website : this.stepData.website,
						facebook : this.stepData.facebook,
						linkedin : this.stepData.linkedin,
						xing : this.stepData.xing,
						first_name : this.stepData.first_name,
						description : this.stepData.description,
						email : this.stepData.email,
						address : this.stepData.address,
						city : this.stepData.city,
						zip_code : this.stepData.zip_code,
						country_id : this.stepData.country_id
						});
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
			
			
  }
  onSubmit() {
	 
	   if(this.form.invalid){
			this.submitted = true;
			this.scrollToFirstInvalidControl();
			return;
		}
		else {
			this.errors = false;
			this.submitted = false;
			this.loading = true; 
			const formData = new FormData();
			
			if(this.form.get('phone_number').value) formData.append('phone_number', this.form.get('phone_number').value);
			
			
			if(this.form.get('website').value) formData.append('website', this.form.get('website').value);
			if(this.form.get('facebook').value) formData.append('facebook', this.form.get('facebook').value);
			if(this.form.get('linkedin').value) formData.append('linkedin', this.form.get('linkedin').value);
			if(this.form.get('xing').value) formData.append('xing', this.form.get('xing').value);
			if(this.form.get('first_name').value) formData.append('first_name', this.form.get('first_name').value);
			if(this.form.get('description').value) formData.append('description', this.form.get('description').value);
			if(this.form.get('email').value) formData.append('email', this.form.get('email').value);
			if(this.form.get('address').value) formData.append('address', this.form.get('address').value);
			if(this.form.get('city').value) formData.append('city', this.form.get('city').value);
			if(this.form.get('zip_code').value) formData.append('zip_code', this.form.get('zip_code').value);
			if(this.form.get('country_id').value) formData.append('country_id', this.form.get('country_id').value);
			
			if(this.fileSource['logo'] !=null){
				formData.append('logo', this.fileSource['logo']);
			}
			if(this.fileSource['cover_image'] !=null){
				formData.append('cover_image', this.fileSource['cover_image']);
			}
			if(this.fileSource['video'] !=null){
				formData.append('video', this.fileSource['video']);
			}
			let token =JSON.parse(localStorage.getItem('authUser')).token;
			this.mainService.save_company_profile(token, formData).subscribe(
				response => {
					this.loading = false; 
					this.submitted = true;
					if(response.status == 'success'){				
						this.toastr.success(response.msg); 
						var res = {};
						res['data'] 	= response.data;
						res['token'] 	= token;
						res['mst'] 		= response.mst;
						localStorage.setItem('authUser', JSON.stringify(res));
				
						this.router.navigate(['/dashboard']);
					}
					else {
						this.errors=response.errors;
						if(response.msg){
							this.toastr.error(response.msg);  
						}
						 this.scrollToFirstInvalidControl();
					}
				},
				(error) => {	
					this.loading = false;
				}
			); 
		}
	  
		return;
	}

}
