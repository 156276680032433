<!-- step1 -->
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="sign-auth-form" style="background-image: url(assets/images/appli-sign-up.jpg);" >
    <div class="row">
        <div class="col-lg-6">
            <div class="login-side-image">
                <div class="image-tag-top">
                    <div class="img-tag">
                        <h2>{{ 'Create profile Create a profile' | translate }}</h2>
                        <p> {{ 'Create your candidate profile and receive inquiries directly from' | translate }} <br/>{{ 'interested companies.' | translate }}</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="login-top">
                <div class="login-form steps-width">
                    <div class="form-field">
                        <div class="head reg-head">
                            <h2>{{ 'Get started now' | translate }}</h2>
                            <h4>{{ 'Enter your contact information.' | translate }}</h4>
                        </div>
                        
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="text"  [ngClass]="(submitted && ((errors && errors.first_name)  || af.first_name.errors )) ? 'form-control is-invalid' : 'form-control'"   formControlName="first_name"   placeholder="{{ 'First name' | translate }}">
										 
								<div *ngIf="submitted && errors && errors.first_name" class="invalid-feedback">{{errors.first_name[0]}}</div>
								<div *ngIf="submitted && af.first_name.errors"  class="invalid-feedback">
									 <div *ngIf="af.first_name.errors.required">{{ 'First name is required!' | translate }}</div>
									  
								</div>
										
									</div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="text"  [ngClass]="(submitted && ((errors && errors.last_name)  || af.last_name.errors )) ? 'form-control is-invalid' : 'form-control'"   formControlName="last_name" placeholder="{{ 'last name' | translate }}">
											<div *ngIf="submitted && errors && errors.last_name" class="invalid-feedback">{{errors.last_name[0]}}</div>
								<div *ngIf="submitted && af.last_name.errors"  class="invalid-feedback">
									 <div *ngIf="af.last_name.errors.required">{{ 'Last name is required!' | translate }}</div>
									 
								</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="number" class="form-control"  formControlName="phone_number" placeholder="{{ 'Phone number (optional)' | translate }}">
									 
                                    </div>
                                </div>   
						  </div>
                           
                           
                            <div class="theme-btn">
                                <button type="submit" class="btn">{{ 'Further' | translate }}</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
