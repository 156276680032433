import { Component, OnInit,Input, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
 

@Component({
  selector: 'app-applicant-verification',
  templateUrl: './applicant-verification.component.html',
  styleUrls: ['./applicant-verification.component.scss']
})
export class ApplicantVerificationComponent implements OnInit {
    
    
   form :FormGroup;
   countriesList= []; 
  submitted = false;
  loading = false;
  errors:any;
  @Input() formData;
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router
  ) { }
 

  
  ngOnInit(): void {
	  let cn = (this.formData.ci!='' && this.formData.ci != null ) ? this.formData.ci:this.formData.cn[0]['id'];
		this.form = this.formBuilder.group({
				  first_name : [this.formData.fn,Validators.required],
				  last_name : [this.formData.ln,Validators.required],
				  enc : [this.formData.vs],
				  phone_number : [this.formData.p]
				});
		this.countriesList =this.formData.cn;
 
  }
  
 get af() { return this.form.controls; }

  onSubmit(){
    if(this.form.invalid){
	  this.submitted = true;
      return
     }	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true; 	
	 this.mainService.applicant_complete_profile(this.form.value).subscribe(
		response => { 
			this.submitted = true;
			this.loading = false;
			if(response.status == 'success'){
				this.toastr.success(response.msg);
				var res = {};
				res['data'] 	= response.data;
				res['token'] 	= response.token;
				res['mst'] 		= response.mst;
				localStorage.setItem('authUser', JSON.stringify(res));
				this.router.navigate(['/create-search-profile']);
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
		},
        (error) => {                              
          this.loading = false;  
		}
	); 
   
  }


}
