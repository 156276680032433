import { Component, OnInit,Input, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
 
@Component({
  selector: 'search-order-step-1',
  templateUrl: './search-order-step-1.component.html'
})
export class SearchOrderStep1Component implements OnInit {
  
  @Output() setPStep = new EventEmitter();	
  @Output() setPid = new EventEmitter();	
  @Output() setStepData = new EventEmitter();	
   	
 	
  @Input() formData;
  @Input() pId;
  form :FormGroup;
  optionList= []; 
  categoryList= []; 
  submitted = false;
  loading = false;
  showOccu = false;
  errors:any;
  token:any;
  mst:any;
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
  }
 checkCat(evt){
	
	  let category_id = this.form.get('category_id').value;
	
	 var that = this;
	 if(category_id != null && category_id !=''){
		this.categoryList.forEach(function(item1,index1){
			if(item1.id == category_id){
				that.optionList = item1.occupations	;
				//that.form.value.occupation_id = []; //({occupation_id: []});
				const checkboxFld: FormArray = that.form.get('occupation_id') as FormArray;
				checkboxFld.controls.forEach(function(i,ind){
					checkboxFld.removeAt(ind);
				});
				 
				 
			}
		});
	}

	 return true; 
 }
    isChecked(v,fld) {
		
	return this.form.get(fld).value.includes(v.toString());
	 
  }
  onCheckboxChange(e,fld) {
	
    const checkboxFld: FormArray = this.form.get(fld) as FormArray;
    if (e.target.checked) {
		checkboxFld.push(new FormControl(e.target.value));
    } else {
       const index = checkboxFld.controls.findIndex(x => x.value === e.target.value);
       checkboxFld.removeAt(index);
    }
  }
 

	ngOnInit(): void {
		this.formData.occupation_id = (this.formData.occupation_id !='' && this.formData.occupation_id != null) ? this.formData.occupation_id:[];	
		this.formData.category_id = (this.formData.category_id !='' && this.formData.category_id != null) ? parseInt(this.formData.category_id):'';	
		this.form = this.formBuilder.group({
				  category_id : [this.formData.category_id,Validators.required],
				  step : [1],
				  occupation_id : this.formBuilder.array(this.formData.occupation_id, [Validators.required]),
				 
				});
		
		this.categoryList = this.mst['categories'];	
		 var that = this;	
		if(this.formData.category_id !=null && this.formData.category_id !=''){
			
			this.categoryList.forEach(function(item1,index1){
						if(item1.id == that.formData.category_id){
							that.optionList = item1.occupations;	
							 
						}
					});
			
		}
	
	}
 get af() { return this.form.controls; }

  onSubmit(){
    if(this.form.invalid){
	  this.submitted = true;
      return
     }	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true;
	  
	
	 this.mainService.save_search_order(this.token,this.pId,this.form.value).subscribe(
		response => { 
			this.submitted = true;
			this.loading = false;
			if(response.status == 'success'){
				 
				this.setPid.emit(response.data.id);
				this.setPStep.emit(response.step);
				this.setStepData.emit(response.data);
			}
			else {
				 
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
		},
        (error) => {                              
            
		 this.loading = false;
		}
	); 
   
  }


}
