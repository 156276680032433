<main class="dash-main">
    <!-- header setion start -->
  
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup">
                    <div class="heading_new"> {{ 'Search orders' | translate }}</div>
                    <div class="searchOrderBtnGroup">
                        <div class="searchField search_OrderSerch">
                            <input type="text" placeholder="{{ 'Seek....' | translate }}"  [(ngModel)]="searched"/>
                            <div class="searchFieldIcon"><i class="fal fa-search"></i></div>
                        </div>
                        <button class="newThemeBtn" (click)="filterby()">
                            <i class="fas fa-filter"></i>
                             {{ 'Filter' | translate }}
                        </button>
                        <button class="newThemeBtn" routerLink="/add-search-order">
                            <i class="fa fa-plus-circle"></i>
                            {{ 'Add new' | translate }}
                        </button>
                    </div>

                </div>

                <div class="col-12 paddingSmall">
				 <ng-template #noRecordFound><div class="condidate_Table table-responsive" ><table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </div></ng-template>
                    <div class="search_order_profile_cardBox" *ngIf="(stepData && stepData.length > 0) else noRecordFound">
						
<ng-container *ngIf="( stepData |  filter:searchedKeyword) as result">
   <div class="condidate_Table table-responsive" *ngIf="result.length === 0" ><table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </div>
</ng-container>
						
					
                        <div class="profile_box"  *ngFor="let item of stepData   | filter:searchedKeyword">
                            <div class="top_search_order">
                                <div class="top_search_flex">
								
							<div class="search_order_img" style="background-image: url(assets/images/user_blank_image.png);" *ngIf="item.category.image== null else cProfile">
                                <img src="assets/images/user_blank_image.png" alt="" />
                            </div>
							<ng-template #cProfile> <div class="search_order_img" style="background-image: url({{APP_URI + 'uploads/categories/' + item.category.image}});" >
                                <img src="{{APP_URI + 'uploads/categories/' + item.category.image}}" alt="" />
                            </div> </ng-template>
								
								
                                    
                                    <div class="search_order_content">
                                        {{item.category.name}}
                                        <span>
                                        <i class="fas fa-map-marker-alt"></i>
										 {{item.place_of_work}}
                                        
                                    </span>
                                    </div>
                                </div>

                                <div class="condidate_btn_group order_SearchbtnGroup">
                                    <a href="javascript:void(0);" title="Bearbeiten" (click)="goProfile(item.id)" class="new_edit"><i class="fal fa-pencil"></i></a>
                                    <a href="javascript:void(0);" title="Löschen" (click)="deleteProfile(item.id)" class="new_delete"><i class="fal fa-trash-alt"></i></a>
                                    <a href="javascript:void(0);" title="Ansehen" (click)="viewProfile(item.id)"  class="new_view"><i class="fal fa-eye"></i></a>
									<a href="javascript:void(0);" title="Deaktivieren"  (click)="changeStatus(0,item.id)" class="new_view bg-info" *ngIf="item.is_active == 1"><i class="fal fa-check"></i></a>
                                    <a href="javascript:void(0);" title="Aktivieren" (click)="changeStatus(1,item.id)" class="new_view bg-info" *ngIf="item.is_active == 0"><i class="fal fa-close"></i></a>
                                </div>
                            </div>

                            <div class="top_search_body">
                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                        <div class="searchLabel">{{ 'Occupations' | translate }}</div>
                                    </div>
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <div class="search_label_content" *ngFor="let item1 of item.occupations">
											{{item1.name}}
										 
										
										
										</div>
                                    </div>
                                </div> <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                        <div class="searchLabel">{{ 'work experience' | translate }}</div>
                                    </div>
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <div class="search_label_content">
										
										{{getFeature('work_experience',item.work_experience)}}
										
										
										</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                        <div class="searchLabel">{{ 'salary' | translate }}</div>
                                    </div>
                                    <div class="col-12 col-md-8 col-lg-9">
									 <div class="search_label_content">
										
										{{getFeature('salary',item.salary)}}
										
										
										</div>
									
                                        <div class="search_label_content d-none">
                                            <ul class="search_feature_card">
                                                <li>
                                                    <span class="tags_info tags">{{ 'Features 1' | translate }}</span>
                                                </li>
                                                <li>
                                                    <span class="tags_info tags">{{ 'Features 2' | translate }}</span>
                                                </li>
                                                <li>
                                                    <span class="tags_info tags">{{ 'Features 3' | translate }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                        <div class="searchLabel">{{ 'Contract type' | translate }}</div>
                                    </div>
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <div class="search_label_content">	{{getFeature('contract_type',item.contract_type)}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                        <div class="searchLabel">{{ 'working time' | translate }}</div>
                                    </div>
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <div class="search_label_content">{{getFeature('working_hours',item.working_hours)}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                        <div class="searchLabel">{{ 'Additional information' | translate }}</div>
                                    </div>
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <div class="search_label_content">{{item.location}}</div>
                                    </div>
                                </div>


                                <!-- --- btn  -->

                                <div class="row">
                                    <div class="col-12 col-md-8 col-lg-9 ml-auto mt-3">
                                        <button class="newThemeBtn secondary_newThemeBtn" (click)="goMatch(item.id)"><i class="fal fa-list-alt"></i> {{ 'View Matches' | translate }}</button>
                                        <button class="newThemeBtn" routerLink="/chat"><i class="far fa-comments"></i> {{ 'View messages' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <!-- ----- pagination  -->

                <div class="col-12 d-none">

                    <ul class="pagination">
                        <li class="page-item disabled">
                            <a class="page-link nameLink" href="javascript:void(0);" tabindex="-1">{{ 'First' | translate }}</a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link" href="javascript:void(0);" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li class="page-item active"><a class="page-link " href="javascript:void(0);">1</a></li>
                        <li class="page-item ">
                            <a class="page-link" href="javascript:void(0);">2 <span class="sr-only">{{ '(current)' | translate }}</span></a>
                        </li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0);" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link nameLink" href="javascript:void(0);">{{ 'Last' | translate }}</a>
                        </li>
                    </ul>

                </div>



            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main> 