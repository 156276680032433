<app-header></app-header>
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
 <section class="banner points before term-banner after ">
      <div class="container">
        <div class="banner-headings terms-top">
        <div class="terms-head faq_Headheding">
          <h1>{{ 'Any questions? We have the answer.' | translate }}</h1>
           
        </div>
      
          <div class="theme-btn">
            
          </div>
         
      </div>
      </div>
    </section>
  <section class="terms-main" style="background:#fff;">
        <div class="container">
            <div class="row">
                 <div class="col-lg-3">
                    <div class="list-group-top" *ngIf="faqCat">
                        
                    <div id="list-example" class="list-group" >
                        <a  *ngFor="let faqc of faqCat; index as i" (click)="changeAct(i)" [ngClass]="(i==actf)? 'list-group-item list-group-item-action active': 'list-group-item list-group-item-action'" href="/faqs#list-item-{{faqc.id}}"><span class="arrow"><img class="arrow-1" src="assets/images/arrow_1.png"><img class="arrow-2" src="assets/images/arrow_2.png"></span>{{faqc.name}}</a>
                        
                      </div>
                      </div>
                </div>
                <div class="col-lg-9">
                    <div attr.data-bs-spy="scroll" attr.data-bs-target="#list-example" attr.data-bs-offset="0" class="scrollspy-example" tabindex="0">
					<div  *ngFor="let faqc of faqCat;" >
                        <div id="list-item-{{faqc.id}}"></div>
                        <div class="tab-pane fade show active" id="list-home{{faqc.id}}" role="tabpanel" attr.aria-labelledby="list-home{{faqc.id}}-list">
                            <div class="terms-content">
                                <div class="accordian-box">
                                    <div class="accordion accordion-flush" id="accordionFlushExample{{faqc.id}}">
                                        
								                		<div class="head-accordian">
                                             <h3>{{ 'FAQs' | translate }} {{faqc.name}}</h3>
                                           
                                        </div>
                                        <div class="accordion-item" *ngFor="let faq of faqContent[faqc.id];" >
										
										<h2 class="accordion-header" id="heading{{ faq.id }}">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapse{{ faq.id }}" attr.aria-expanded="true" attr.aria-controls="collapse{{ faq.id }}" [innerHtml]="faq.question">
        
      </button>
    </h2>
	
	 <div id="collapse{{ faq.id }}" class="accordion-collapse collapse " attr.aria-labelledby="heading{{ faq.id }}" attr.data-bs-parent="#accordionFlushExample{{faqc.id}}">
      <div class="accordion-body"  [innerHtml]="faq.answer">
         <p [innerHtml]="faq.answer"></p>
      </div>
    </div>
										
                                         
                                        </div>
                                      
									   </div>
                                </div>
    
                            </div>
                        </div>
					
					</div>
                       </div>
                </div>
            </div>
             
           
        </div>
      
    </section>	
