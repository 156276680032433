<main class="dash-main">
  <!-- header setion start -->
  <app-admin-header></app-admin-header>
  <!-- header setion end -->
  <!-- side navbaar section start-->

  <app-sidebar></app-sidebar>
  <!-- side navbaar section end-->
  <!-- min page box  start-->
  <!-- <div class="loader-wrapper" ><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div> -->

  <div class="main-page-box">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 paddingSmall searchFlexGroup">
          <div class="heading_new">{{ 'Match list' | translate }}</div>


          <div class="searchOrderBtnGroup">

            <button class="newThemeBtn">
              <i class="fa fa-plus-circle"></i>
              {{ 'Show Hide Matches' | translate }}
            </button>

          </div>

        </div>

        <div class="">
          <div class="filter_block">
            <div class="form-group">

              <select class="form-control custom-select" id="exampleFormControlSelect1">
                <option>{{ 'Occupation' | translate }} </option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>

            <div class="form-group">

              <input type="text" class="form-control " id="exampleFormControlInput1" placeholder="{{ 'Place of Work' | translate }}">
            </div>


            <div class="form-group">

              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="{{ 'Age' | translate }}">
            </div>

            <div class="form-group">

              <select class="form-control custom-select" id="exampleFormControlSelect1">
                <option>{{ 'Education' | translate }}</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group">

              <select class="form-control custom-select" id="exampleFormControlSelect1">
                <option> {{ 'Salary Expectations' | translate }}</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>

            <div class="form-group">
              <select class="form-control custom-select" id="exampleFormControlSelect1">
                <option>{{ 'Type of Contract' | translate }}</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group">

              <select class="form-control custom-select" id="exampleFormControlSelect1">
                <option> {{ 'Occupation-Specific Features' | translate }} </option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="searchField">
              <input type="text" placeholder="{{ 'Search here....' | translate }}" />
              <div class="searchFieldIcon"><i class="fal fa-search"></i></div>
            </div>
            <button class="ml-sm-4 newThemeBtn">
              <i class="fas fa-filter"></i>
              {{ 'Filter by' | translate }}
            </button>
          </div>
        </div>
      </div>


      <div class="profile_box">
        <div class="top_search_order">
          <div class="top_search_flex">
            <div class="search_order_img" style="background-image: url(assets/images/user_blank_image.png);"><img
                src="assets/images/user_blank_image.png" alt=""></div>
            <!--bindings={
                "ng-reflect-ng-if-else": "[object Object]"
              }-->
            <div class="search_order_content"> Z.U. <span><i class="fas fa-map-marker-alt"></i>New York</span></div>
          </div>
          <!--bindings={
                "ng-reflect-ng-if": "true"
              }-->
          <!--bindings={
                "ng-reflect-ng-if": "false"
              }-->
          <div class="condidate_btn_group d-flex align-items-center">
            <!--bindings={
                "ng-reflect-ng-if": "false"
              }--><button class="newThemeBtn secondary_newThemeBtn"><i
                class="fal fa-list-alt"></i>{{ 'Interested' | translate }}</button>
            <!--bindings={
                "ng-reflect-ng-if": "true"
              }--><button class="newThemeBtn secondary_newThemeBtn"><i class="fal fa-clipboard-list"></i>{{ 'View Notes' | translate }}
            </button><button class="newThemeBtn secondary_newThemeBtn"><i class="far fa-ban"></i>{{ 'Hide' | translate }}</button>

            <!-- <button  class="newThemeBtn secondary_newThemeBtn ng-star-inserted"><i  class="far fa-ban"></i>Unhide</button> -->
            <!-- <button  class="newThemeBtn"><i  class="far fa-comments"></i>Ablehnen</button> -->



            <!--bindings={
                "ng-reflect-ng-if": "true"
              }-->
            <!--bindings={
                "ng-reflect-ng-if": "false"
              }-->
            <!--bindings={
                "ng-reflect-ng-if": "false"
              }-->
            <div class="followcheck progress-circle" data-progress="74">

            </div>
          </div>
        </div>
        <div class="top_search_body">
          <div id="accordion132">
            <div class="match_card">
              <div id="matchHeadOne132"><a href="javascript:void(0);" data-bs-toggle="collapse" aria-expanded="true"
                  class="match_collapse_head" data-bs-target="#matchCardOne132" aria-controls="matchCardOne132">
                  <div class="match_coll_Label">{{ 'Training' | translate }} &amp; {{ 'experiences' | translate }}</div>
                  <div class="new_progress_right">
                    <div class="progress">
                      <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
                        ng-reflect-ng-class="progress-bar progress-bar-stri" aria-valuenow="75"
                        class="progress-bar progress-bar-striped bg-success" style="width: 75%;"></div>
                    </div>
                    <div class="new_progress_value"> 75%</div>
                  </div>
                </a></div>
              <div class="collapse show" id="matchCardOne132" aria-labelledby="matchHeadOne132"
                data-parent="#accordion132">
                <div class="match_card_body">
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'Graduation' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'education' | translate }} &amp; {{ 'qualification' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'work experience' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 0%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-warning" style="width: 0%;"></div>
                      </div>
                      <div class="new_progress_value"> 0%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ "driver's license" | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="match_card">
              <div id="matchHeadTwo132"><a href="javascript:void(0);" data-bs-toggle="collapse" aria-expanded="true"
                  class="match_collapse_head collapsed" data-bs-target="#matchCardTwo132"
                  aria-controls="matchCardTwo132">
                  <div class="match_coll_Label">{{ 'Job parameters' | translate }}</div>
                  <div class="new_progress_right">
                    <div class="progress">
                      <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 60"
                        ng-reflect-ng-class="progress-bar progress-bar-stri"
                        class="progress-bar progress-bar-striped bg-success" style="width: 60%;"></div>
                    </div>
                    <div class="new_progress_value"> 60%</div>
                  </div>
                </a></div>
              <div class="collapse" id="matchCardTwo132" aria-labelledby="matchHeadTwo132" data-parent="#accordion132">
                <div class="match_card_body">
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'Contract type' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'working time' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 0%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-warning" style="width: 0%;"></div>
                      </div>
                      <div class="new_progress_value"> 0%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'salary' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'vacation' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'Weekend work' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 0%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-warning" style="width: 0%;"></div>
                      </div>
                      <div class="new_progress_value"> 0%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="match_card">
              <div id="matchHeadThree132"><a href="javascript:void(0);" data-bs-toggle="collapse" aria-expanded="true"
                  class="match_collapse_head collapsed" data-bs-target="#matchCardThree132"
                  aria-controls="matchCardThree132">
                  <div class="match_coll_Label">{{ 'Place of work & assignment' | translate }}</div>
                  <div class="new_progress_right">
                    <div class="progress">
                      <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 88"
                        ng-reflect-ng-class="progress-bar progress-bar-stri"
                        class="progress-bar progress-bar-striped bg-success" style="width: 88%;"></div>
                    </div>
                    <div class="new_progress_value"> 88%</div>
                  </div>
                </a></div>
              <div class="collapse" id="matchCardThree132" aria-labelledby="matchHeadThree132"
                data-parent="#accordion132">
                <div class="match_card_body">
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'Place of work area' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'External assembly' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'Outside assembly area' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 50%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-warning" style="width: 50%;"></div>
                      </div>
                      <div class="new_progress_value"> 50%</div>
                    </div>
                  </div>
                  <!--bindings={
                "ng-reflect-ng-if": "true"
              }-->
                  <div class="match_card_body_progress">
                    <div class="match_coll_Label">{{ 'Start of contract' | translate }}</div>
                    <div class="new_progress_right">
                      <div class="progress">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow=" 100%"
                          ng-reflect-ng-class="progress-bar progress-bar-stri"
                          class="progress-bar progress-bar-striped bg-success" style="width: 100%;"></div>
                      </div>
                      <div class="new_progress_value"> 100%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 paddingSmall d-none">

        <ul class="pagination">
          <li class="page-item disabled">
            <a class="page-link nameLink" href="javascript:void(0);" tabindex="-1">{{ 'First' | translate }}</a>
          </li>
          <li class="page-item disabled">
            <a class="page-link" href="javascript:void(0);" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item active"><a class="page-link " href="javascript:void(0);">1</a></li>
          <li class="page-item ">
            <a class="page-link" href="javascript:void(0);">2 <span class="sr-only">{{ '(current)' | translate }}</span></a>
          </li>
          <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
          <li class="page-item">
            <a class="page-link" href="javascript:void(0);" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link nameLink" href="javascript:void(0);">{{ 'Last' | translate }}</a>
          </li>
        </ul>

      </div>

    </div>
  </div>

</main>