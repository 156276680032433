import { Component, OnInit, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})



export class ChangePasswordComponent implements OnInit {
	authData:any; 
	NewPassword='password';
	ConfirmPassword='password';
	loading = false;
	submitted = false;
	errors:any;
	form :FormGroup;
	constructor(private mainService: MainService,  private formBuilder: FormBuilder,private toastr: ToastrService,private router: Router,private el: ElementRef) { }
	get af() { return this.form.controls; }
	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
		if(firstInvalidControl){
			firstInvalidControl.focus(); //without smooth behavior
		}
	}

  ngOnInit(): void {
	if(localStorage.getItem('authUser')){
		this.authData = JSON.parse(localStorage.getItem('authUser')).data;
	}
	 this.form = this.formBuilder.group({
						current_password : ['',Validators.required],
						new_password : ['',Validators.required],
						confirm_password : ['',Validators.required],
						 
						
				 
						}, { validators: this.checkPasswords });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  const password = group.get('new_password').value;
  const confirmPassword = group.get('confirm_password').value;

  return password === confirmPassword ? null : group.get('confirm_password').setErrors({ confirmedValidator: true });    
}
  showPassword(cls){
	  if(cls=='1') this.NewPassword = 'text';
	  if(cls=='2') this.ConfirmPassword = 'text';
	  
  }
  hidePassword(cls){
	if(cls=='1') this.NewPassword = 'password';
	if(cls=='2') this.ConfirmPassword = 'password';
  }
  onSubmit() {
	   if(this.form.invalid){
		   console.log(this.form);
			this.submitted = true;
			this.scrollToFirstInvalidControl();
			return;
		}
		else {
			this.errors = false;
			this.submitted = false;
			this.loading = true; 
			 
			let token =JSON.parse(localStorage.getItem('authUser')).token;
			this.mainService.change_password(token, this.form.value).subscribe(
				response => {
					this.loading = false; 
					this.submitted = true;
					if(response.status == 'success'){				
						this.toastr.success(response.msg); 
						this.router.navigate(['/dashboard']);
					}
					else {
						this.errors=response.errors;
						if(response.msg){
							this.toastr.error(response.msg);  
						}
						 this.scrollToFirstInvalidControl();
					}
				},
				(error) => {	
					this.loading = false;
				}
			); 
		}
	  
		return;
	  
	  
  }

}
