<app-header></app-header>
<div class="loader-wrapper" *ngIf="loading">
  <div class="loader"><img src="assets/images/logo-loader.png" alt="" /></div>
</div>
<section class="banner points before term-banner  ">
  <div class="container">
    <div class="banner-headings terms-top">
      <div class="terms-head faq_Headheding">
        <h1>{{"contact form" | translate }}</h1>

      </div>


    </div>
  </div>
</section>

<section class="contact-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="contact-box pink for-padding-box">
          <h3 class="dash-heading">{{"Do you have any questions or comments? Then send us a message. We're looking forward to it!" | translate }}</h3>

          <div class="form-field">
            <div class="pb-2 sub-heading">(* - {{ 'Required fields' | translate }} )</div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{'Name' | translate}}*</label>
                    <input type="name" name="name" [ngClass]="(submitted && ((errors && errors.name)  || af.name.errors )) ? 'form-control is-invalid' : 'form-control'"
                      autocomplete="new-password" formControlName="name" aria-describedby="nameHelp">
                    <div *ngIf="submitted && errors && errors.name" class="invalid-feedback">{{errors.name[0]}}</div>
                    <div *ngIf="submitted && af.name.errors" class="invalid-feedback">
                      <div *ngIf="af.name.errors.required">{{'Name is required.' | translate}}</div>
                       
                    </div>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{'E-Mail-Adresse' | translate}}*</label>
                    <input type="email" name="email" [ngClass]="(submitted && ((errors && errors.email)  || 
                                            af.email.errors )) ? 'form-control is-invalid' : 'form-control'"
                      autocomplete="new-password" formControlName="email" aria-describedby="emailHelp">
                    <div *ngIf="submitted && errors && errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
                    <div *ngIf="submitted && af.email.errors" class="invalid-feedback">
                      <div *ngIf="af.email.errors.required">{{'Email is required.' | translate}}</div>
                      <div *ngIf="af.email.errors.email">{{'Please provide valid email.' | translate}}</div>

                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"Company name" | translate }}</label>
                    <input type="text" name="company_name" class="form-control" formControlName="company_name">

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{"Phone number" | translate }}</label>
                    <input type="text" name="number" class="form-control" formControlName="phone_number">

                  </div>
                </div>
              </div>



              <div class="form-group">
                <label>{{"Your message" | translate }}*</label>
                <textarea name="message" [ngClass]="(submitted && ((errors && errors.message)  || af.message.errors )) ? 
                                    'form-control is-invalid' : 'form-control'" formControlName="message">
                                    </textarea>

                <div *ngIf="submitted && errors && errors.message" class="invalid-feedback">{{errors.message[0]}}</div>
                <div *ngIf="submitted && af.message.errors" class="invalid-feedback">
                  <div *ngIf="af.message.errors.required">{{'Message is required.' | translate}}</div>

                </div>

              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox"
                  [ngClass]="(submitted && ((errors && errors.toc)  || af.toc.errors )) ? 'form-check-input is-invalid' : 'form-check-input'"
                  id="customCheck1" checked="" formControlName="toc">
                <label class="form-check-label" for="customCheck1">

                  {{"I hereby consent to the collection and storage of the data I have provided for the purpose of establishing contact and answering my request in accordance with YourCraftJob's privacy policy." | translate }} {{ 'I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}*
                </label>

                <div *ngIf="submitted && errors && errors.toc" class="invalid-feedback">{{errors.toc[0]}}</div>
                <div *ngIf="submitted && af.toc.errors" class="invalid-feedback">
                  <div *ngIf="af.toc.errors.required">{{'You must accept the terms and conditions!' | translate}}</div>

                </div>




                <input type="hidden"
                  [ngClass]="(submitted && ((errors && errors.rcToken))) ? 'form-check-input is-invalid' : 'form-check-input'"
                  formControlName="rcToken">


                <div *ngIf="submitted && errors && errors.rcToken" class="invalid-feedback">{{errors.rcToken[0]}}</div>


 <div class="form-group allow_action py-3" *ngIf="checkCookie == '0'">
													<label class="form-check-label">
                                                       {{'Google Recaptcha is deactivated!' | translate }} 
                                                    </label>
                                                    <div>
													<button (click)="accept_cookie()" class="btn  btn-sm me-md-2">{{'Allow' | translate }} </button>
													<button  class="btn  btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button>
                                                </div>    </div>


              </div>

              <div class="theme-btn p-0">
                <button type="submit" class="btn width-fit">{{"Submit" | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<!-- 
<section class="browse-profile">
  <div class="container">
  <div class="heading text-center">
  <h4>Hier gibt es Jobs f&uuml;r alle Berufe des Baugewerbes</h4>
  </div>
  
  <div class="table-border browse-table new">
  <div class="boxes-head">
  <div class="box-devide one">
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_1" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_1.png" /></div>
  
  <div class="content">
  <p>Maurer &amp; Betonbauer <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_2" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_2.png" /></div>
  
  <div class="content">
  <p>Zimmerer <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_3" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_3.png" /></div>
  
  <div class="content">
  <p>Dachdecker <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_4" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_4.png" /></div>
  
  <div class="content">
  <p>Stra&szlig;enbauer <span class="d-block"> (m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_5" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_5.png" /></div>
  
  <div class="content">
  <p>Brunnenbauer <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  </div>
  
  <div class="box-devide two">
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_6" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_6.png" /></div>
  
  <div class="content">
  <p>Maler &amp; Lackierer <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_7" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_7.png" /></div>
  
  <div class="content">
  <p>Klempner <span class="d-block"> (m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_8" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_8.png" /></div>
  
  <div class="content">
  <p>Installateur &amp;<span class="d-block">Heizungsbauer (m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_9.png" /></div>
  
  <div class="content">
  <p>Elektrotechniker <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles add-more-img">
  <div class="job-titles-all">
  <div class="img"><img alt="browse_9" onclick="$('.lastbox').toggleClass('d-none')" src="http://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/ck_editor_1628674241.png" style="cursor:pointer;" /> <strong class="pt-2 d-block">Alle anzeigen</strong></div>
  </div>
  </div>
  </div>
  </div>
  
  <div class="box-devide two lastbox d-none">
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_2.png" /></div>
  
  <div class="content">
  <p>Asphaltbauer<span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Rollladen.png" /></div>
  
  <div class="content">
  <p>Rollladen- und Sonnenschutztechniker <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Gleisbauer.png" /></div>
  
  <div class="content">
  <p>Gleisbauer<span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Kanalbauer.png" /></div>
  
  <div class="content">
  <p>Kanalbauer<span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  
  <div class="boxes">
  <div class="job-titles">
  <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Rohrleitungsbauer.png" /></div>
  
  <div class="content">
  <p>Rohrleitungsbauer <span class="d-block">(m/w/d)</span></p>
  </div>
  </div>
  </div>
  </div>




    
  <div class="box-devide two lastbox  d-none">
    <div class="boxes">
    <div class="job-titles">
    <div class="img"><img alt="browse_6" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Wärme.png" /></div>
    
    <div class="content">
    <p> Wärme-, Kälte- und Schallschutzisolierer  <span class="d-block">(m/w/d)</span></p>
    </div>
    </div>
    </div>
    
    <div class="boxes">
    <div class="job-titles">
    <div class="img"><img alt="browse_7" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Gerüstbauer.png" /></div>
    
    <div class="content">
    <p>Gerüstbauer <span class="d-block"> (m/w/d)</span></p>
    </div>
    </div>
    </div>
    
    <div class="boxes">
    <div class="job-titles">
    <div class="img"><img alt="browse_8" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Betonstein.png" /></div>
    
    <div class="content">
    <p> Betonstein- und Terrazzohersteller;<span class="d-block">Heizungsbauer (m/w/d)</span></p>
    </div>
    </div>
    </div>
    
    <div class="boxes">
    <div class="job-titles">
    <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Ofen.png" /></div>
    
    <div class="content">
    <p>Ofen- und Luftheizungsbauer <span class="d-block">(m/w/d)</span></p>
    </div>
    </div>
    </div>
    <div class="boxes">
      <div class="job-titles">
      <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Stuckateur.png" /></div>
      
      <div class="content">
      <p>Stuckateur  <span class="d-block">(m/w/d)</span></p>
      </div>
      </div>
      </div>

    </div>


    <div class="box-devide two lastbox  d-none">
      <div class="boxes">
      <div class="job-titles">
      <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Mechatroniker.png" /></div>
      
      <div class="content">
      <p>Mechatroniker<span class="d-block">(m/w/d)</span></p>
      </div>
      </div>
      </div>
      
      <div class="boxes">
      <div class="job-titles">
      <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Tischler.png" /></div>
      
      <div class="content">
      <p>Tischler  <span class="d-block">(m/w/d)</span></p>
      </div>
      </div>
      </div>
      
      <div class="boxes">
      <div class="job-titles">
      <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/rolle_1.png" /></div>
      
      <div class="content">
      <p>Glaser<span class="d-block">(m/w/d)</span></p>
      </div>
      </div>
      </div>
      
      <div class="boxes">
      <div class="job-titles">
      <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Fliesen.png" /></div>
      
      <div class="content">
      <p> Fliesen-, Platten- und Mosaikleger (<span class="d-block">(m/w/d)</span></p>
      </div>
      </div>
      </div>
      
      <div class="boxes">
      <div class="job-titles">
      <div class="img"><img alt="browse_9" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/estrichleger.png" /></div>
      
      <div class="content">
      <p>Estrichleger <span class="d-block">(m/w/d)</span></p>
      </div>
      </div>
      </div>
      </div>
      <div class="box-devide two  lastbox  d-none">
        <div class="boxes">
        <div class="job-titles">
        <div class="img"><img alt="browse_6" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Parkettleger.png" /></div>
        
        <div class="content">
        <p>Parkettleger <span class="d-block">(m/w/d)</span></p>
        </div>
        </div>
        </div>
        
        <div class="boxes">
        <div class="job-titles">
        <div class="img"><img alt="browse_7" src="https://yourcraftjob.stage02.obdemo.com/uploads/ck_editor_images/Raumausstatter.png" /></div>
        
        <div class="content">
        <p>Raumausstatter <span class="d-block"> (m/w/d)</span></p>
        </div>
        </div>
        </div>
        
     
    
        </div>
    
  </div>
  </div>
  </div>
  </section>
  


 -->
