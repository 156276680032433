<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>   
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
					 
						<div class="radios-and-checks">
                                    <div class="heading-top">
                                        <h2>{{ 'Information on place of work and deployment' | translate }}</h2>
                                    </div>
									<div *ngFor="let option1 of filedList;">
										 
										<div class="radios-and-checks-box" *ngIf="static_features[option1].type_of_feature ==7">
											<div class="heading">
												<h2>{{static_features[option1].label}}</h2>
											</div>
											<div class="row">
												<div class="col-12"  >
													<div class="form-group">
														<input type="text"  [ngClass]="(submitted && ((errors && errors[static_features[option1].fld])  || af[static_features[option1].fld].errors )) ? 'form-control is-invalid' : 'form-control '" formControlName="{{static_features[option1].fld}}"   placeholder="{{'Enter ' + static_features[option1].label}}">
											 
														<div *ngIf="submitted && errors && errors[static_features[option1].fld]" class="invalid-feedback">{{errors[static_features[option1].fld][0]}}</div>
														<div *ngIf="submitted && af[static_features[option1].fld].errors"  class="invalid-feedback">
															<div *ngIf="af[static_features[option1].fld].errors.required">{{ static_features[option1].label + ' ist erforderlich.' | translate}}</div>
														</div>
													</div>
												</div>
												
											</div>
												
										</div>
										 
										<div class="radios-and-checks-box" *ngIf="static_features[option1].type_of_feature ==6">
											<div class="heading">
												<h2>{{static_features[option1].label}}</h2>
											</div>
											<div class="row" *ngIf="static_features[option1].fld =='radius_for_work'">
												<div  class="col-12">
												
													 <div class="form-group">
														<input type="text" id="place_of_work" #search  [ngClass]="(submitted && ((errors && errors.place_of_work)  || af.place_of_work.errors )) ? 'form-control is-invalid gac' : 'form-control gac'" formControlName="place_of_work"   placeholder="{{ 'Enter place of work' | translate }}">
														
														<input type="hidden"  [ngClass]="(submitted  && af.lat && af.lat.errors) ? 'form-control is-invalid' : 'form-control '"  formControlName="lat" >
											 
														<div *ngIf="submitted && errors && errors.place_of_work" class="invalid-feedback">{{errors.place_of_work[0]}}</div>
														<div *ngIf="submitted && (form.value.place_of_work!=null) && af.lat.errors && af.lat.errors.required" class="invalid-feedback">{{"Invalid place of work." | translate }}</div>
														<div *ngIf="submitted && af.place_of_work.errors"  class="invalid-feedback">
<div *ngIf="af.place_of_work.errors && af.place_of_work.errors.required">{{ "Place of work is required." | translate}}</div>
														</div>
													</div>
													
													
												
													
												</div>
											
												<div class="col-6" *ngFor="let opt of static_features[option1].options" >
													<div class="custom-control custom-radio" >
														<input type="radio" [value]="opt.id" id="{{static_features[option1].fld}}{{opt.id}}" name="{{static_features[option1].fld}}" class="form-check-input" formControlName="{{static_features[option1].fld}}"  (change)="initialize()" />
														<label class="form-check-label" for="{{static_features[option1].fld}}{{opt.id}}">{{opt.name}}</label>
													</div>
													
												</div>
												<div class="col-6" *ngIf="static_features[option1].doesnotmatter">
												<div class="custom-control custom-radio">
														<input type="radio" [value]="-1" id="{{static_features[option1].fld}}-1" name="{{static_features[option1].fld}}" class="form-check-input" formControlName="{{static_features[option1].fld}}" />
														<label class="form-check-label" for="{{static_features[option1].fld}}-1">{{static_features[option1].doesnotmatter}}</label>
													</div>
												</div>
												
												<div class="col-12" >
													
													<input type="hidden" [ngClass]="(submitted && ((errors && errors[static_features[option1].fld])  || af[static_features[option1].fld].errors )) ? 'is-invalid' : ''" />
													<div *ngIf="submitted && errors && errors[static_features[option1].fld]" class="invalid-feedback">{{errors[static_features[option1].fld][0]}}</div>
													<div *ngIf="submitted && af[static_features[option1].fld].errors"  class="invalid-feedback">
														<div *ngIf="af[static_features[option1].fld].errors.required">
														{{ static_features[option1].label + ' ist erforderlich.' | translate}}
														</div>
													</div>
													<div id="map_canvas" style="height:400px" *ngIf="checkCookie == 1"  ></div>​
													 <div class="form-group text-center allow_action" *ngIf="checkCookie == '0'">
													<label class="form-check-label">
                                                       {{'Google Map is deactivated!' | translate }} 
                                                    </label>
												<div>	<button (click)="accept_cookie()" class="btn btn-primary btn-sm me-md-2 mr-2">{{'Allow' | translate }} </button>
													<button  class="btn btn-info btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button>
                                                </div></div>
												</div>
												
												
												
											</div>
                           
						   
						   <div class="row" *ngIf="static_features[option1].fld !='radius_for_work'">
												
											
												<div class="col-6" *ngFor="let opt of static_features[option1].options" >
													<div class="custom-control custom-radio" >
														<input type="radio" [value]="opt.id" id="{{static_features[option1].fld}}{{opt.id}}" name="{{static_features[option1].fld}}" class="form-check-input" formControlName="{{static_features[option1].fld}}" />
														<label class="form-check-label" for="{{static_features[option1].fld}}{{opt.id}}">{{opt.name}}</label>
													</div>
													
												</div>
												<div class="col-6" *ngIf="static_features[option1].doesnotmatter">
												<div class="custom-control custom-radio">
														<input type="radio" [value]="-1" id="{{static_features[option1].fld}}-1" name="{{static_features[option1].fld}}" class="form-check-input" formControlName="{{static_features[option1].fld}}" />
														<label class="form-check-label" for="{{static_features[option1].fld}}-1">{{static_features[option1].doesnotmatter}}</label>
													</div>
												</div>
												
												<div class="col-12" >
													
													<input type="hidden" [ngClass]="(submitted && ((errors && errors[static_features[option1].fld])  || af[static_features[option1].fld].errors )) ? 'is-invalid' : ''" />
													<div *ngIf="submitted && errors && errors[static_features[option1].fld]" class="invalid-feedback">{{errors[static_features[option1].fld][0]}}</div>
													<div *ngIf="submitted && af[static_features[option1].fld].errors"  class="invalid-feedback">
														<div *ngIf="af[static_features[option1].fld].errors.required">
														{{ static_features[option1].label + ' ist erforderlich.' | translate}}
														</div>
													</div>
												</div>
												
												
												
											</div>
                           
										</div>
										<div class="radios-and-checks-box" *ngIf="static_features[option1].fld =='work_on_site' && af.work_on_site.value==1  ">
											<div class="heading">
												<h2>{{static_features['area_on_site_work'].label}}</h2>
											</div>
											<div class="row">
												 
											
												<div class="col-6" *ngFor="let opt of static_features['area_on_site_work'].options" >
													<div class="custom-control custom-radio" >
														<input type="radio" [value]="opt.id" id="{{static_features['area_on_site_work'].fld}}{{opt.id}}" name="{{static_features['area_on_site_work'].fld}}" class="form-check-input" formControlName="{{static_features['area_on_site_work'].fld}}" />
														<label class="form-check-label" for="{{static_features['area_on_site_work'].fld}}{{opt.id}}">{{opt.name}}</label>
													</div>
													
												</div>
													
												
												<div class="col-12" >
													
													<input type="hidden" [ngClass]="(submitted && ((errors && errors[static_features['area_on_site_work'].fld])  || af[static_features['area_on_site_work'].fld].errors )) ? 'is-invalid' : ''" />
													<div *ngIf="submitted && errors && errors[static_features['area_on_site_work'].fld]" class="invalid-feedback">{{errors[static_features['area_on_site_work'].fld][0]}}</div>
													<div *ngIf="submitted && af[static_features['area_on_site_work'].fld].errors"  class="invalid-feedback">
														<div *ngIf="af[static_features['area_on_site_work'].fld].errors.required">
														{{ static_features['area_on_site_work'].label + ' ist erforderlich.' | translate}}
														</div>
													</div>
												</div>
												
												
												
											</div>
                           
										</div>
										 <div class="radios-and-checks-box" *ngIf="static_features[option1].type_of_feature ==5">
											<div class="heading">
												<h2>{{static_features[option1].label}}</h2>
											</div>
											<div class="row">
												<div class="col-6"  *ngFor="let opt of static_features[option1].options">
													<div class="form-check">
														<input type="checkbox" class="form-check-input" [checked]="isChecked(opt.id,static_features[option1].fld)" [value]="opt.id" id="{{static_features[option1].fld}}{{opt.id}}"  class="form-check-input" (change)="onCheckboxChange($event, static_features[option1].fld)"  />
														<label class="form-check-label" for="{{static_features[option1].fld}}{{opt.id}}">{{opt.name}}</label>
													</div>
												</div>
												<div class="col-6"  *ngIf="static_features[option1].doesnotmatter">
													<div class="form-check">
														<input type="checkbox" class="form-check-input" [checked]="isChecked(-1,static_features[option1].fld)" [value]="-1" id="{{static_features[option1].fld}}-1}"  class="form-check-input" (change)="onCheckboxChange($event, static_features[option1].fld)"  />
														<label class="form-check-label" for="{{static_features[option1].fld}}-1">{{static_features[option1].doesnotmatter}}</label>
													</div>
												</div>
												<div class="col-12" >
													<input type="hidden" [ngClass]="(submitted && ((errors && errors[static_features[option1].fld])  || af[static_features[option1].fld].errors )) ? 'is-invalid' : ''" />
													<div *ngIf="submitted && errors && errors[static_features[option1].fld]" class="invalid-feedback">{{errors[static_features[option1].fld][0]}}</div>
													<div *ngIf="submitted && af[static_features[option1].fld].errors"  class="invalid-feedback">
														<div *ngIf="af[static_features[option1].fld].errors.required">
														{{ static_features[option1].label + ' ist erforderlich.' | translate}}
														</div>
													</div>
												</div>
											</div>
                           
										</div>
										 
										
									
                                    </div>
									
									
                                    

                                    <div class="theme-btn">
									 
									<button class="back-btn" (click)="goBack()">{{ 'Return' | translate }}</button>&nbsp;&nbsp;<button type="submit" class="btn">{{ 'Further' | translate }}</button>
                                    </div>
									

                            
                        </div>
						
                           
                           
                            
                            
                        </form>