<app-header></app-header>
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="terms-page">
    <div class="container">
        <div class="terms-box">
            <h3> {{ cmsContent.title }}</h3>
                <p  *ngIf="cmsContent && cmsContent.body" [innerHtml]="cmsContent.body"></p>
        </div>
        
    </div>
</section>
