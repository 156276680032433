import { Component, OnInit,Input, EventEmitter, Output,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'search-order-step-6',
  templateUrl: './search-order-step-6.component.html',
   styles: ['.theme-btn .back-btn { background-color: #003661; border-radius: 0; padding: 6px 20px 8px 20px; border-radius: 10px; font-size: 17px; color: #ffffff; font-weight: 500; width: auto; min-width: 140px; }']
})
export class SearchOrderStep6Component implements OnInit {
	@Output() setPStep = new EventEmitter();	
	@Output() setPid = new EventEmitter();
	@Output() setStepData = new EventEmitter();
 	
	@Input() formData;
	@Input() pId;
	form :FormGroup;
	filedList = ['contract_type','working_hours','salary','vacation','weekend_work'];
	static_features:any; 
	submitted = false;
	loading = false;
	errors:any;
	token:any;
	mst:any;
	fileSource:any = [];
	fileName = '';
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
  }
   ngAfterViewInit(){
	   
	   
	   window.scrollTo(0,0);
   }

 
 
	ngOnInit(): void {
 
		this.form = this.formBuilder.group({
				  location : [this.formData.location],
				  step : [6],
				 
				});

		
		
	
	}
	  
 get af() { return this.form.controls; }
 


  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
	if(firstInvalidControl){
		
		firstInvalidControl.focus(); //without smooth behavior
	}
  }
	
 goBack (){
	 if(this.formData.special_features.length==0){
			this.setPStep.emit(4);
	} else {
	  this.setPStep.emit(5);
	}
  }
 
  onSubmit(){ 
    if(this.form.invalid){
		
	  this.submitted = true;
	   this.scrollToFirstInvalidControl();
      return
     }	
	 

	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true; 
	
	  	
	 this.mainService.save_search_order(this.token,this.pId,this.form.value).subscribe(
		response => {
			this.loading = false; 
			this.submitted = true;
			if(response.status == 'success'){
			   let authUser= JSON.parse(localStorage.getItem('authUser'));
				authUser.data.icp = 2;	
				localStorage.setItem('authUser', JSON.stringify(authUser));
				this.toastr.success('Your search order is successfully completed.'); 
				this.router.navigate(['/search-orders']);
				
	
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
				 this.scrollToFirstInvalidControl();
			}
		},
        (error) => {
		 	
            this.loading = false;
		}
	); 
    
  }


}