import { Component, OnInit,OnDestroy,Renderer2   } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {Subscription} from 'rxjs';
import { CookieService  } from 'ngx-cookie-service'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit,OnDestroy  {
	submitted = false;
	loading = false;
	errors:any;
	form: FormGroup;
	checkCookie:any;
	public recentToken: string = ''
	private singleExecutionSubscription: Subscription;

recaptchaAvailable = false;
	constructor(
	private renderer: Renderer2,
	private recaptchaV3Service: ReCaptchaV3Service,
	private mainService: MainService,  
	private formBuilder: FormBuilder,
	private toastr: ToastrService,
	private router: Router, private confirmationDialogService: ConfirmationDialogService,private cookies: CookieService
	) { }
public ngOnDestroy() {
    
	if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
	
	this.renderer.removeClass(document.body, 'recaptcha'); 
		
  }	
  accept_cookie(){
	  this.cookies.set('recaptcha','1');
	  this.checkCookie = 1;
	  window.location.reload();
	  
	}
  	showPopup(slug){
	  this.loading=true;
	  let that = this;
	   this.mainService.getCmsContent(slug).subscribe(res => {
		   
		   that.confirmationDialogService.confirm(res.data.title, res.data.body,'Close','','lg');
			this.loading=false;  
    });
	return false;
	  
  }
getRecaptchaToken(action){
		if(this.checkCookie == "1"){
			this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
			.subscribe(response => {
				this.recentToken = response;
				this.recaptchaAvailable = true;
			},error=>{
			  console.log("error getting recaptcha");
			  this.ngOnDestroy();
			  

			});
		}
}
ngOnInit(): void {
	 this.checkCookie = this.cookies.get('recaptcha');
	 if(this.checkCookie =="1"){	
	  this.renderer.addClass(document.body, 'recaptcha');
	  
	 }
		this.form = this.formBuilder.group({
			name: ['',Validators.required],
			email: ['',[Validators.required, Validators.email]],
			phone_number : [''],
			company_name : [''],
			message : ['',Validators.required],
			toc : ['',Validators.required],
			rcToken  : ['']
		});
		this.getRecaptchaToken('register');
		
	}
	get af() { return this.form.controls; }	
	
	onSubmit(){
		if(this.form.invalid){
			this.submitted = true;			
			return
		}
		
		
		
		if(this.recaptchaAvailable){
			// do we have recaptcha token?
			this.form.value.rcToken = this.recentToken;
		}
		this.errors 	= false;
		this.submitted 	= false;
		this.loading 	= true;
		this.mainService.save_contact(this.form.value).subscribe(response=>{ 
			this.submitted = true;
			this.loading = false;
			
			if(response.status == 'success'){
				this.ngOnDestroy();
				this.toastr.success(response.msg);
				this.router.navigate(['/']);	
				this.form.reset();					
			}
			  else {
				this.getRecaptchaToken('register'); 
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			  }
		   
			},
			(error) => {                               
				this.loading = false;
			}); 	
	}


}
