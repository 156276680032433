import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import * as globalConstant from 'src/app/global_constant'; 
import {ChatService} from 'src/app/services/chat.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	 APP_URI = globalConstant.APP_URI;	
  loggedIn: boolean; 
  menuShow: boolean = false;
  authData:any; 
  headerClass="header-clr-scnd";
  constructor(  private authService: SocialAuthService,   private router: Router, private chatService:ChatService) { }

	ngOnInit(): void {
	  
		if(this.router.url=="/"){
			this.headerClass="";	
		}
		if(localStorage.getItem('authUser')){
			this.authData = JSON.parse(localStorage.getItem('authUser')).data;
			this.loggedIn = true;
		}
	}
Registrierung(): void {
	   this.router.navigate(['/signup']);
  }
  gotoHome(): void {
	   this.router.navigate(['/'],);
  }
  logout(): void {
	  //this.authService.signOut();
	  localStorage.removeItem('authUser');
	  sessionStorage.removeItem('authUser');
	  this.router.navigate(['/']);
	  let requestBody={'id':this.authData.i};
	  this.chatService.userLogout(requestBody);
  }


menuToggle() {

    this.menuShow = !this.menuShow;
    // let bodyParm = document. getElementsByTagName('body')[0];

    
  }

}
