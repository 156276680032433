<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup">
                    <div class="heading_new">{{ 'Subscription Plans' | translate }}</div>
                </div>
            </div>

            <div class="row" *ngIf="stepData && stepData.length > 0" >
                <div class="col-12 col-md-6 paddingSmall col-lg-3" *ngFor="let item of stepData">
                    <div class="profile_box subBoxPlan  {{item.color}}">
                        <div class="sub_head_month">
                           {{item.name}}
                            <span>{{ 'TERM FOR' | translate }}</span>
                        </div>

                        <div class="sub_plan_amt">
                            <div class="sub_plan_price">€{{item.price}}</div>
                            <div class="sub_plan_vat">{{ 'Including VAT' | translate }}</div>
                        </div>
                        <div class="sub_plan_content">
                            {{item.description}}
                        </div>
                        <div class="sub_btn_parent">
                            <button type="button" class="newThemeBtn" (click)="changePlan(item.id)"  data-bs-toggle="modal" data-bs-target="#exampleModal">{{ 'Buy Now' | translate }}</button>
                        </div>


                    </div>
                </div>
           </div>

<!-- Modal -->
<div class="modal themeModal  fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered model-pay">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{ 'Choose Payment Method' | translate }}</h5>
		<button type="button"    data-bs-dismiss="modal" aria-label="Close" class="btn-close"> × </button>
       
      </div>
      <div class="modal-body" style="max-height:400px;overflow:auto;">
        <div class="radios-and-checks-box">
<div class="row">
<div class="col-12">

  <div class="form-group mb-0">
    <div class="selectStatus_btns">
        <div class="selectStatus_box">
          <input type="radio" name="payment_method" value="1" checked class="form-check-input" (click)="changeMethod(1)" id="PaymentMethod1"  />
            <label for="PaymentMethod1">PayPal
               <!-- <img src="assets/images/paypal.png" alt=""/> -->
              </label>
        </div>
        <div class="selectStatus_box">
          <input type="radio"  name="payment_method" value="2" class="form-check-input" (click)="changeMethod(2)"  id="PaymentMethod2"  />
            <label for="PaymentMethod2">{{ 'Bank transfer' | translate }}
              
              <!-- <img src="assets/images/bank-img.png" alt=""/> -->
            </label>
        </div>
       
    </div>
</div>


<!-- 
<div class="custom-control custom-radio">
<input type="radio" name="payment_method" value="1" checked class="form-check-input" (click)="changeMethod(1)" id="PaymentMethod1"  />
<label class="form-check-label" for="PaymentMethod1">PayPal</label>
</div>
</div>
<div class="col-12">
<div class="custom-control custom-radio">
<input type="radio"  name="payment_method" value="2" class="form-check-input" (click)="changeMethod(2)"  id="PaymentMethod2"  />
<label class="form-check-label" for="PaymentMethod2">Bank Transfer</label>
</div> -->
</div>

<div class="col-12" *ngIf="payment_method==2">
<ul class="list-group mt-3" *ngIf="Company.bank_name">
  <li class="list-group-item d-flex justify-content-between align-items-start modelpay-text">
<p>{{ 'Please transfer the invoice amount stating the invoice number and customer number within the next 7 days without deduction to the following bank account:' | translate }}</p>
     
  </li>  <li class="list-group-item d-flex justify-content-between align-items-start modelpay-text">
    <div class="ms-2 me-auto">
      <div class="fw-bold lable-bold">IBAN :</div>
      <div class="output"> {{Company.iban_number}}</div>
    </div>
  </li>
  <li class="list-group-item d-flex justify-content-between align-items-start modelpay-text">
    <div class="ms-2 me-auto">
      <div class="fw-bold lable-bold">BIC :</div>
      <div class="output">   {{Company.bank_account}}</div>
    </div>
  </li> <li class="list-group-item d-flex justify-content-between align-items-start modelpay-text">
    <div class="ms-2 me-auto">
      <div class="fw-bold lable-bold">Bank :</div>
    <div class="output">{{Company.bank_name}}</div>
    </div>
  </li>
 
 
</ul>

</div>
</div>
</div>              
      </div>
	  <div class="modal-footer"><button type="button"  data-bs-dismiss="modal" aria-label="Later"  class="newThemeBtn cancelTheme">{{ 'Later' | translate }}</button><button type="button" class="newThemeBtn" (click)="buy_now()">{{ 'Buy Now' | translate }}</button></div>
       
    </div>
  </div>
</div>






        </div>
    </div>
    <!-- min page box  end-->
</main>