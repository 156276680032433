<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall text-right">
                    <button class="newThemeBtn" (click)="addNewProfile()">
                        <i class="fa fa-plus-circle"></i>
                        {{ 'Add new profile' | translate }}
                    </button>
                </div>

                <div class="col-12 paddingSmall">
                    <div class="condidate_Table table-responsive">
					 <ng-template #noRecordFound>  <table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </ng-template>
                        <table class="table" *ngIf="(stepData && stepData.length > 0) else noRecordFound">
						 
						           <tr *ngFor="let item of stepData">
                                <td>
								
								
                                    <div class="condidate_Prfile_name">
											
							<div class="codidate_profile_block" style="background-image: url(assets/images/condidate_profile.png);" *ngIf="item.category.image== null else cProfile">
                                <img src="assets/images/condidate_profile.png" alt="" />
                            </div>
							<ng-template #cProfile> <div class="codidate_profile_block" style="background-image: url({{APP_URI + 'uploads/categories/' + item.category.image}});" >
                                <img src="{{APP_URI + 'uploads/categories/' + item.category.image}}" alt="" />
                            </div> </ng-template>
									
                                      
                                        <div class="condidate_name_block">
                                            {{item.category.name}}
                                            <span>
                                                {{ 'job' | translate }}
                                            </span>
                                        </div>

                                    </div>
                                </td>
                                <td>
                                    <div class="condidate_Date_block">
                                       {{item.created_at | date : 'dd.MM.yyyy'}}
                                        <span>{{ 'Created on' | translate }}</span>
                                    </div>
                                </td>

                                <td>
                                    <div class="condidate_interest">
                                        {{item.interested}} <span class="tags primary small" *ngIf="item.interested > 0" (click)="goInterest(item.id)" style="cursor:pointer;">Alle ansehen</span>
                                        <span> {{ 'Interested companies' | translate }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="condidate_interest">
									 <span *ngIf="item.step==7"  class="tags success">{{ 'Completed' | translate }}</span>
                                     <span *ngIf="item.step!=7"  class="tags success ">{{ 'Pending' | translate}}</span>
                                         
                                        <span> {{ 'Status' | translate }}</span>
                                    </div>
                                </td>
                                <td>
								
								  
                                    <div class="condidate_btn_group">
                                        <a href="javascript:void(0);"   ngbTooltip="Bearbeiten"  title="Bearbeiten" (click)="goProfile(item.id)" class="new_edit ttp"><i class="fal fa-pencil"></i></a>
                                        <a href="javascript:void(0);" ngbTooltip="Löschen"  title="Löschen" (click)="deleteProfile(item.id)" class="new_delete"><i class="fal fa-trash-alt"></i></a>
                                        <a href="javascript:void(0);" ngbTooltip="Ansehen"  title="Ansehen" (click)="viewProfile(item.id)" class="new_view"><i class="fal fa-eye"></i></a>
                                        <a href="javascript:void(0);" ngbTooltip="Deaktivieren"  title="Deaktivieren"  (click)="changeStatus(0,item.id)" class="new_view bg-info" *ngIf="item.is_active == 1"><i class="fal fa-check"></i></a>
                                        <a href="javascript:void(0);" ngbTooltip="Aktivieren"   title="Aktivieren" (click)="changeStatus(1,item.id)" class="new_view bg-info" *ngIf="item.is_active == 0"><i class="fal fa-close"></i></a>
                                    </div>

                                </td>
                            </tr>
					  </table>
                    </div>

                </div>
            </div>


 
        </div>
     </div>
    <!-- min page box  end-->
</main>