<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup flex-display mb-4">
                    <div class="heading_new">{{'Change Password' | translate}}</div>
                    <div class="searchOrderBtnGroup flex-frow-1 mb-0">
					
					 
                        <button class="newThemeBtn" routerLink="/my-profile" *ngIf="authData.uri == 2"><i class="fal fa-pencil"></i>{{'Edit Profile' | translate}}</button>
                        <button class="newThemeBtn" routerLink="/company-profile" *ngIf="authData.uri == 3"><i class="fal fa-pencil"></i>{{'Edit Profile' | translate}}</button>
                    </div>

                </div>
                <div class="col-12 paddingSmall ">
                    <div class="profile_box">
					 <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
						
                            <div class="col-12 col-md-12 col-lg-9">
                                <div class="row">
                                    <div class="col-12 col-md-5 col-lg-4">
                                        <label>{{'Current Password' | translate}}</label>
                                    </div>
                                    <div class="col-12 col-md-7 col-lg-8">
									
									
										
								 <div class="form-group" >
									<input type="password" name="current_password"  [ngClass]="(submitted && ((errors && errors.current_password)  || af.current_password.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="current_password"  placeholder="{{'Current Password' | translate }}">
									<div *ngIf="submitted && errors && errors.current_password" class="invalid-feedback">{{errors.current_password[0]}}</div>
									<div *ngIf="submitted && af.current_password.errors"  class="invalid-feedback">
										 <div *ngIf="af.current_password.errors.required">{{'Current Password is required!' | translate}}</div>
									</div>
									
								</div>
								
									
                                         
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12 col-md-5 col-lg-4">
                                        <label>{{'New Password' | translate}}</label>
                                    </div>
                                    <div class="col-12 col-md-7 col-lg-8">
									
									
												
								 <div class="form-group dateIconProfile" >
									<input [type]="NewPassword" name="new_password"  [ngClass]="(submitted && ((errors && errors.new_password)  || af.new_password.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="new_password"  placeholder="{{'New Password' | translate }}">
									 <div class="calendarIcon" (mousedown)="showPassword('1')" (mouseout)="hidePassword('1')" (mouseup)="hidePassword('1')"><i class="fas fa-eye"></i></div>
									 <div *ngIf="submitted && errors && errors.new_password" class="invalid-feedback">{{errors.new_password[0]}}</div>
									<div *ngIf="submitted && af.new_password.errors"  class="invalid-feedback">
										 <div *ngIf="af.new_password.errors.required">{{'New Password is required!' | translate}}</div>
									</div>
									
								</div>
									
									
                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-5 col-lg-4">
                                        <label>{{'Confirm Password' | translate}}</label>
                                    </div>
                                    <div class="col-12 col-md-7 col-lg-8">
									
									
									
									 <div class="form-group dateIconProfile" >
									<input [type]="ConfirmPassword" name="confirm_password"  [ngClass]="(submitted && ((errors && errors.confirm_password)  || af.confirm_password.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="confirm_password"  placeholder="{{'Confirm Password' | translate}}">
									 <div class="calendarIcon" (mousedown)="showPassword('2')" (mouseout)="hidePassword('2')" (mouseup)="hidePassword('2')"><i class="fas fa-eye"></i></div>
									 <div *ngIf="submitted && errors && errors.confirm_password" class="invalid-feedback">{{errors.confirm_password[0]}}</div>
									<div *ngIf="submitted && af.confirm_password.errors"  class="invalid-feedback">
										 <div *ngIf="af.confirm_password.errors.required">{{'Confirm Password is required!' | translate}}</div>
										  <div *ngIf="af.confirm_password.errors.confirmedValidator">{{'New Password and Confirm Password must be match.' | translate}}</div>
									</div>
									
								</div>
									
									
									
									
                                      
                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-12 col-md-7 col-lg-8 ml-auto">
                                        <button class="newThemeBtn secondary_newThemeBtn"  routerLink="/dashboard" >{{'Cancel' | translate}}</button>
                                        <button class="newThemeBtn" type="submit">{{'Save' | translate}}</button>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-md-5 mx-auto col-lg-3">
                                <div class="change_pass_img">
                                    <img src="assets/images/change_pass_img.png" />
                                </div>
                            </div>
                        </div>
                        </form>


                    </div>
                </div>

            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main>