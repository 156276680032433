import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as globalConstant from './global_constant'; 
@Injectable({
providedIn: 'root'
})

export class AuthCookieService {
	apiDomain	=   globalConstant.DOMAIN;
 
	private subject = new Subject<any>();
	sendClickEvent() {
	  this.subject.next();
	}
	getClickEvent(): Observable<any>{ 
	  return this.subject.asObservable();
	}	
  
}
