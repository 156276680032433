<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar ></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup interst_section_heading">
                    <div class="heading_new mb-0">{{ 'Interested companies' | translate }}</div>
                    <div class="searchOrderBtnGroup interst_section_right mb-0">
                        <div class="searchField  search_OrderSerch">
                            <input type="text" placeholder="{{ 'Seek....' | translate }}" [(ngModel)]="searched" />
                            <div class="searchFieldIcon"><i class="fal fa-search"></i></div>
                        </div>
                        <button class="newThemeBtn" (click)="filterby()">
                            <i class="fas fa-filter"></i>
                             {{ 'Filter' | translate }}
                        </button>
                    </div>

                </div>

                <div class="col-12 paddingSmall">
				<ng-template #noRecordFound><div class="condidate_Table table-responsive" ><table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </div></ng-template>
                    <div class="search_order_profile_cardBox" *ngIf="(stepData && stepData.length > 0) else noRecordFound">
					
					
					<ng-container *ngIf="( stepData |  filter:searchedKeyword) as result">
   <div class="condidate_Table table-responsive" *ngIf="result.length === 0" ><table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </div>
</ng-container>
					
                        <div class="profile_box" *ngFor="let item of stepData  | filter:searchedKeyword">

                            <div class="top_search_body">
                                <div class="row">

                                    <div class="col-12 borderLineInterest col-lg-5 align-items-center justify-content-center d-flex">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="interestImgLogo">
                                                    <img src="assets/images/user_blank_image.png" *ngIf="item.employer.logo == null"/>
                                                    <img src="{{APP_URI + 'uploads/service_providers/' + item.employer.logo}}" *ngIf="item.employer.logo != null"/>
                                                </div>
                                                <div class="interestLogoName" title="Unternehmensname">{{item.employer.first_name}}</div>
                                                <div class="interestLogoAdd" title="Adresse">{{item.search_order.place_of_work}}, {{item.employer.country}}</div>
                                                <div class="row"  *ngIf="item.employer.phone_number != null">
                                                    <!-- <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="searchLabel">Telefonnummer</div>
                                                    </div> -->
                                                    <div class="col-12 col-md-12 col-lg-12 text-center">
                                                        <div class="search_label_content">{{item.employer.phone_number}}</div>
                                                    </div>
                                                </div>
        
                                                <div class="row"  *ngIf="item.employer.email != null">
                                                    <!-- <div class="col-12 col-md-4 col-lg-4">
                                                        <div class="searchLabel">E-Mail-Adresse</div>
                                                    </div> -->
                                                    <div class="col-12 col-md-12 col-lg-12 text-center">
                                                        <div class="search_label_content">{{item.employer.email}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center" *ngIf="item.applicant_interested == 0">
                                                <button class="newThemeBtn secondary_newThemeBtn" (click)="reject_interest(item.id)"><i class="fal fa-times"></i>{{ 'Reject' | translate }}</button>
                                                <button class="newThemeBtn" (click)="accept_interest(item.id)"><i class="fal fa-check"></i> {{ 'Interested' | translate }}</button>
                                            </div>
											
                                        </div>
                                    </div>

                                    <div class="col-12  col-lg-7 ">
                                        <!-- <div class="row" *ngIf="item.employer.country != null">
                                            <div class="col-12 col-md-4 col-lg-3">
                                                <div class="searchLabel">Land</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-9">
                                                <div class="search_label_content">{{item.employer.country}}</div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row"  *ngIf="item.employer.phone_number != null">
                                            <div class="col-12 col-md-4 col-lg-3">
                                                <div class="searchLabel">Telefonnummer</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-9">
                                                <div class="search_label_content">{{item.employer.phone_number}}</div>
                                            </div>
                                        </div>

                                        <div class="row"  *ngIf="item.employer.email != null">
                                            <div class="col-12 col-md-4 col-lg-3">
                                                <div class="searchLabel">E-Mail-Adresse</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-9">
                                                <div class="search_label_content">{{item.employer.email}}</div>
                                            </div>
                                        </div> -->

                                        <div class="row"  *ngIf="item.employer.website != null">
                                            <div class="col-12 col-md-4 col-lg-4">
                                                <div class="searchLabel">{{ 'website' | translate }}</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-8">
                                                <div class="search_label_content"><a href="javascript:void(0);">{{item.employer.website}}</a></div>
                                            </div>
                                        </div>

                                        <div class="row"  *ngIf="item.employer.facebook != null">
                                            <div class="col-12 col-md-4 col-lg-4">
                                                <div class="searchLabel">{{ 'Facebook Link' | translate }}</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-8">
                                                <div class="search_label_content">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 291.319 291.319" style="enable-background:new 0 0 291.319 291.319;" xml:space="preserve">
                                                            <path style="fill:#3B5998;" d="M145.659,0c80.45,0,145.66,65.219,145.66,145.66c0,80.45-65.21,145.659-145.66,145.659   S0,226.109,0,145.66C0,65.219,65.21,0,145.659,0z"/>
                                                            <path style="fill:#FFFFFF;" d="M163.394,100.277h18.772v-27.73h-22.067v0.1c-26.738,0.947-32.218,15.977-32.701,31.763h-0.055   v13.847h-18.207v27.156h18.207v72.793h27.439v-72.793h22.477l4.342-27.156h-26.81v-8.366   C154.791,104.556,158.341,100.277,163.394,100.277z"/>
                                                        
                                                    </svg> {{item.employer.facebook}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"  *ngIf="item.employer.linkedin != null">
                                            <div class="col-12 col-md-4 col-lg-4">
                                                <div class="searchLabel">{{ 'Linkedin Link' | translate }}</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-8">
                                                <div class="search_label_content">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 291.319 291.319" style="enable-background:new 0 0 291.319 291.319;" xml:space="preserve">
                                                            <path style="fill:#0E76A8;" d="M145.659,0c80.45,0,145.66,65.219,145.66,145.66s-65.21,145.659-145.66,145.659S0,226.1,0,145.66   S65.21,0,145.659,0z"/>
                                                            <path style="fill:#FFFFFF;" d="M82.079,200.136h27.275v-90.91H82.079V200.136z M188.338,106.077   c-13.237,0-25.081,4.834-33.483,15.504v-12.654H127.48v91.21h27.375v-49.324c0-10.424,9.55-20.593,21.512-20.593   s14.912,10.169,14.912,20.338v49.57h27.275v-51.6C218.553,112.686,201.584,106.077,188.338,106.077z M95.589,100.141   c7.538,0,13.656-6.118,13.656-13.656S103.127,72.83,95.589,72.83s-13.656,6.118-13.656,13.656S88.051,100.141,95.589,100.141z"/>
                                                    </svg> {{item.employer.linkedin}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"  *ngIf="item.employer.xing != null">
                                            <div class="col-12 col-md-4 col-lg-4">
                                                <div class="searchLabel">{{ 'Xing Link' | translate }}</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-8">
                                                <div class="search_label_content">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="512pt" viewBox="0 0 512 512" width="512pt"><path d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0" fill="#008878"/><path d="m512 256c0-17.558594-1.769531-34.703125-5.136719-51.265625l-102.859375-102.855469h-69.414062l-72.96875 126.800782-71.222656-71.09375h-69.75l41.878906 71.390624-54.53125 89.042969 190.453125 190.457031c121.179687-20.226562 213.550781-125.554687 213.550781-252.476562zm0 0" fill="#006659"/><path d="m190.398438 157.585938h-69.75l41.878906 71.390624-54.53125 89.042969h70.550781l54.125-88.378906zm0 0" fill="#fff"/><path d="m404.003906 101.878906h-69.410156l-107.824219 187.359375-.316406.554688 68.355469 120.328125h69.195312l-68.246094-120.136719zm0 0" fill="#fff"/><path d="m295.753906 289.988281 68.246094 120.128907h-69.1875l-38.8125-68.316407v-103.34375l78.597656-136.574219h69.40625zm0 0" fill="#fff5cb"/></svg>                                                   {{item.employer.xing}}
                                                </div>
                                            </div>
                                        </div>
										 <div class="row"  *ngIf="item.employer.cover_image == 'pdf' && item.employer.video != null">
                                            <div class="col-12 col-md-4 col-lg-4">
                                                <div class="searchLabel">{{"Document" | translate}}</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-8">
                                                <div class="search_label_content">
												 
													<a href="{{APP_URI + 'uploads/service_providers/' + item.employer.video}}" target="_blank" >{{ 'View' | translate }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"  *ngIf="item.employer.cover_image == 'mp4' && item.employer.video != null">
                                            <div class="col-12 col-md-4 col-lg-4">
                                                <div class="searchLabel">{{ 'Video overview' | translate }}</div>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-8">
                                                <div class="search_label_content videoModalPopup" data-bs-toggle="modal" attr.data-bs-target="#videoModalpop{{item.id}}">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                                        <polygon style="fill:#FFFFFF;" points="187.368,146.928 187.368,355.8 382.992,251.368 "/>
                                                        <path style="fill:#DB2B42;" d="M256,0.376C114.616,0.376,0,114.824,0,256s114.616,255.624,256,255.624S512,397.176,512,256  S397.384,0.376,256,0.376z M184.496,146.928l195.624,104.44L184.496,355.8V146.928z"/>
                                                    </svg> {{ 'Play' | translate }}
                                                </div>
                                            </div>
                                        </div>
										<div class="modal fade" id="videoModalpop{{item.id}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"  *ngIf="item.employer.video != null">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{ 'Company information' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Schließen">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <video src="{{APP_URI + 'uploads/service_providers/' + item.employer.video}}" controls></video>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'Conclude' | translate }}</button>
                
            </div>
        </div>
    </div>
</div>

                                        <div class="row"  *ngIf="item.employer.description != null">
                                            <div class="col-12 col-md-5 col-lg-5">
                                                <div class="searchLabel">{{ 'Company description' | translate }} </div>
                                            </div>
                                            <div class="col-12 col-md-7 col-lg-7">
                                                <div class="search_label_content">{{item.employer.description}}</div>
                                            </div>
                                        </div>
   <div class="row" >
                                              <div class="col-12 text-center d-none">
                                                <button class="newThemeBtn secondary_newThemeBtn" (click)="tc(item.id)"><i class="fal fa-eye"></i>{{ 'View Match Details' | translate }}</button>
                                                 
                                            </div>
                                        </div>

                                    </div>
                               
 <div class="col-12 " style="padding-top:15px;">
                                                
                                   <div class="overall_tabs d-none">
                            <ul class="nav nav-tabs" id="myTab{{item.id}}" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="profile-tab{{item.id}}" data-bs-toggle="tab" attr.data-bs-target="#profile{{item.id}}" type="button" 
                                    role="tab" attr.aria-controls="profile" attr.aria-selected="false">{{'Overall' | translate}}</button>
                                  </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link " id="home-tab{{item.id}}" data-bs-toggle="tab" attr.data-bs-target="#home{{item.id}}" type="button" role="tab"
                                   attr.aria-controls="home" attr.aria-selected="true">{{'Percentage' | translate}}</button>
                                </li>
                               
                            
                              </ul>
                              </div>
                              <div class="tab-content" id="myTabContent{{item.id}}">
                                <div class="tab-pane fade " id="home{{item.id}}" role="tabpanel" attr.aria-labelledby="home-tab{{item.id}}">

                                    <div class="top_search_body"  >
                                        <div id="accordion{{item.id}}">
                                            <div class="match_card">
                                                <div id="matchHeadOne{{item.id}}">
                                                    <a href="javascript:void(0);" class="match_collapse_head" data-bs-toggle="collapse" attr.data-bs-target="#matchCardOne{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardOne{{item.id}}">
                                                        <div class="match_coll_Label">{{ 'Education & experience' | translate }}</div>
                                                        <div class="new_progress_right">
                                                            <div class="progress">
                                                                <div  [ngClass]="(item.education_experience >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"   role="progressbar" style="width:  {{item.education_experience}}%" attr.aria-valuenow="{{item.education_experience}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="new_progress_value"> {{item.education_experience}}%</div>
                                                        </div>
        
                                                    </a>
                                                </div>
        
                                                <div id="matchCardOne{{item.id}}" class="collapse show" attr.aria-labelledby="matchHeadOne{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    <div class="match_card_body">
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Graduation' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.graduation >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.graduation}}%" attr.aria-valuenow=" {{item.graduation}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.graduation}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Education & qualification' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.education_qualification >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.education_qualification}}%" attr.aria-valuenow=" {{item.education_qualification}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.education_qualification}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'work experience' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.work_experience >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'" role="progressbar" style="width: {{item.work_experience}}%" attr.aria-valuenow=" {{item.work_experience}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.work_experience}}%</div>
                                                            </div>
                                                        </div>
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ "driver's license" | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.driving_license >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.driving_license}}%" attr.aria-valuenow=" {{item.driving_license}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.driving_license}}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="match_card">
                                                <div id="matchHeadTwo{{item.id}}">
                                                    <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardTwo{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardTwo{{item.id}}">
                                                        <div class="match_coll_Label">{{ 'Job parameters' | translate }}</div>
                                                        <div class="new_progress_right">
                                                            <div class="progress">
                                                                <div [ngClass]="(item.job_parameters >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.job_parameters}}%" attr.aria-valuenow=" {{item.job_parameters}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="new_progress_value"> {{item.job_parameters}}%</div>
                                                        </div>
        
                                                    </a>
                                                </div>
        
                                                <div id="matchCardTwo{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadTwo{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    <div class="match_card_body">
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Contract type' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.contract_type >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.contract_type}}%" attr.aria-valuenow=" {{item.contract_type}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.contract_type}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'working time' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.working_hours >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.working_hours}}%" attr.aria-valuenow=" {{item.working_hours}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.working_hours}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'salary' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.salary >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.salary}}%" attr.aria-valuenow=" {{item.salary}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.salary}}%</div>
                                                            </div>
                                                        </div>
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'vacation' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.vacation >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.vacation}}%" attr.aria-valuenow=" {{item.vacation}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.vacation}}%</div>
                                                            </div>
                                                        </div>  
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Weekend work' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.weekend_work >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.weekend_work}}%" attr.aria-valuenow=" {{item.weekend_work}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.weekend_work}}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="match_card">
                                                <div id="matchHeadThree{{item.id}}">
                                                    <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardThree{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardThree{{item.id}}">
                                                    
                                                        <div class="match_coll_Label">{{ 'Place of work & assignment' | translate }}</div>
                                                        <div class="new_progress_right">
                                                            <div class="progress">
                                                                <div  [ngClass]="(item.place_of_work_assignment >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width:  {{item.place_of_work_assignment}}%" attr.aria-valuenow=" {{item.place_of_work_assignment}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="new_progress_value"> {{item.place_of_work_assignment}}%</div>
                                                        </div>
        
                                                    </a>
                                                </div>
        
                                                <div id="matchCardThree{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadThree{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    <div class="match_card_body">
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Place of work & area' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.place_of_work >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.place_of_work}}%" attr.aria-valuenow=" {{item.place_of_work}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.place_of_work}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'External assembly' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.work_on_site >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.work_on_site}}%" attr.aria-valuenow=" {{item.work_on_site}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.work_on_site}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress" *ngIf="item.area_on_site_work != -1">
                                                            <div class="match_coll_Label">{{ 'Outside assembly area' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.area_on_site_work >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.area_on_site_work}}%" attr.aria-valuenow=" {{item.area_on_site_work}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.area_on_site_work}}%</div>
                                                            </div>
                                                        </div>
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Start of contract' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.begin_job >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.begin_job}}%" attr.aria-valuenow=" {{item.begin_job}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.begin_job}}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade show active" id="profile{{item.id}}" role="tabpanel" attr.aria-labelledby="profile-tab{{item.id}}">

                                    <div>

                                        <div class="top_search_body"  >
                                            <div id="accordion{{item.id}}">
                                                <div class="match_card">
                                                    <div id="matchHeadOne{{item.id}}">
                                                        <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardOne{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardOne{{item.id}}">
                                                            <div class="match_coll_Label">{{ 'Education & experience' | translate }}</div>
                                                                     
                                                        </a>
                                                    </div>
            
                                                  <div id="matchCardOne{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadOne{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    
                                                   <div class="theme-table theme-table-block theme-table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{{ 'Company' | translate }}</th>
                                                        <th class="">{{ 'Applicant' | translate }}</th>
                                                    </tr>
                                                </thead>
            
                                                <tbody>
<tr><td data-label=""><label class="badge-theme"> {{ 'Graduation' | translate }}} : </label></td><td data-label="Company">{{getVal('graduation','C',item.search_order.graduation)}}</td><td data-label="Applicant">{{getVal('graduation','A',item.search_profile.graduation)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Education & qualification' | translate }} : </label></td><td data-label="Company">{{getVal('education_qualification','C',item.search_order.education_qualification)}}</td><td data-label="Applicant">{{getVal('education_qualification','A',item.search_profile.education_qualification)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'work experience' | translate }} : </label></td><td data-label="Company">{{getVal('work_experience','C',item.search_order.work_experience)}}</td><td data-label="Applicant">{{getVal('work_experience','A',item.search_profile.work_experience)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ "driver's license" | translate }} : </label></td><td data-label="Company">{{getVal('driving_license','C',item.search_order.driving_license)}}</td><td data-label="Applicant">{{getVal('driving_license','A',item.search_profile.driving_license)}}</td></tr>             
                                                </tbody>
                                            </table>
                                        </div> 
                                                </div>         
												</div>
                                                <div class="match_card">
                                                    <div id="matchHeadTwo{{item.id}}">
                                                        <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardTwo{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardTwo{{item.id}}">
                                                            <div class="match_coll_Label">{{ 'Job parameters' | translate }}</div>
                                                    
            
                                                        </a>
                                                    </div>
            <div id="matchCardTwo{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadTwo{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                                     <div class="theme-table theme-table-block theme-table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{{ 'Company' | translate }}</th>
                                                        <th class="">{{ 'Applicant' | translate }}</th>
                                                    </tr>
                                                </thead>
            <tbody>
<tr><td data-label=""><label class="badge-theme"> {{ 'Contract type' | translate }} : </label></td><td data-label="Company">{{getVal('contract_type','C',item.search_order.contract_type)}}</td><td data-label="Applicant">{{getVal('contract_type','A',item.search_profile.contract_type)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'working time' | translate }} : </label></td><td data-label="Company">{{getVal('working_hours','C',item.search_order.working_hours)}}</td><td data-label="Applicant">{{getVal('working_hours','A',item.search_profile.working_hours)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'salary' | translate }} : </label></td><td data-label="Company">{{getVal('salary','C',item.search_order.salary)}}</td><td data-label="Applicant">{{getVal('salary','A',item.search_profile.salary)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'vacation' | translate }} : </label></td><td data-label="Company">{{getVal('vacation','C',item.search_order.vacation)}}</td><td data-label="Applicant">{{getVal('vacation','A',item.search_profile.vacation)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Weekend work' | translate }} : </label></td><td data-label="Company">{{getVal('weekend_work','C',item.search_order.weekend_work)}}</td><td data-label="Applicant">{{getVal('weekend_work','A',item.search_profile.weekend_work)}}</td></tr>
</tbody>
                                            </table>
                                        </div> 
                                                    </div>
                                                </div>
												<div class="match_card">
                                                    <div id="matchHeadTwoThree{{item.id}}">
                                                        <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardTwoThree{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardTwoThree{{item.id}}">
                                                            <div class="match_coll_Label">{{ 'Place of work & assignment' | translate }}</div>
                                                        </a>
                                                    </div>
                                                    <div id="matchCardTwoThree{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadTwoThree{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                                     <div class="theme-table theme-table-block theme-table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{{ 'Company' | translate }}</th>
                                                        <th class="">{{ 'Applicant' | translate }}</th>
                                                    </tr>
                                                </thead>
            
                                                <tbody>
<tr><td data-label=""><label class="badge-theme"> {{ 'Place of work & area' | translate }} : </label></td><td data-label="Company">{{item.search_order.place_of_work}}</td><td data-label="Applicant">{{item.search_profile.place_of_work}} ({{item.search_profile.radius_for_work}}) KM</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'External assembly' | translate }} : </label></td><td data-label="Company">{{getVal('work_on_site','C',item.search_order.work_on_site)}}</td><td data-label="Applicant">{{getVal('work_on_site','A',item.search_profile.work_on_site)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Outside assembly area' | translate }} : </label></td><td data-label="Company">{{getVal('area_on_site_work','C',item.search_order.area_on_site_work)}}</td><td data-label="Applicant">{{getVal('area_on_site_work','A',item.search_profile.area_on_site_work)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Start of contract' | translate }} : </label></td><td data-label="Company">{{getVal('begin_job','C',item.search_order.begin_job)}}</td><td data-label="Applicant">{{getVal('begin_job','A',item.search_profile.begin_job)}}</td></tr>
                                               
                                                </tbody>
                                            </table>
                                        </div> 
                                                  
                                                    </div>
                                                </div>
            
                                           
            
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                              </div>
                          
                                            </div>
							
</div>
						<br/>
						<br/>
						<div class="row">   
						 
						  </div>



                                <!-- --- btn  -->


                            </div>
                        </div>


 
                    </div>

                </div>


                <!-- ----- pagination  -->

                <div class="col-12 d-none">

                    <ul class="pagination">
                        <li class="page-item disabled">
                            <a class="page-link nameLink" href="javascript:void(0);" tabindex="-1">{{ 'First' | translate }}</a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link" href="javascript:void(0);" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li class="page-item active"><a class="page-link " href="javascript:void(0);">1</a></li>
                        <li class="page-item ">
                            <a class="page-link" href="javascript:void(0);">2 <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0);" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link nameLink" href="javascript:void(0);">{{ 'Last' | translate }}</a>
                        </li>
                    </ul>

                </div>



            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main>



<!-- ------------ video modal popup -->

