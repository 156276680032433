import { Component, OnInit,Input, EventEmitter, Output,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
 

@Component({
  selector: 'app-employer-verification',
  templateUrl: './employer-verification.component.html',
  styleUrls: ['./employer-verification.component.scss']
})
export class EmployerVerificationComponent implements OnInit {
    
    
   form :FormGroup;
   countriesList= []; 
  submitted = false;
  loading = false;
  lg = false;
  public imagePath;
  imgURL: any;
  errors:any;
  @Input() formData;
  fileSource:any = {logo:null, cover_image: null , video: null};
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef
  ) { }
 
	onFileChange(event,fileIndex) {
        if (event.target.files.length > 0) {
			//console.log(event.target.files);
			this.fileSource[fileIndex] = (event.target.files[0]);
			if(fileIndex == 'logo') {
				this.form.patchValue({'logo':event.target.files[0]['name']});
				 var reader = new FileReader();
				this.imagePath = event.target.files;
				reader.readAsDataURL(event.target.files[0]); 
				reader.onload = (_event) => { 
				  this.imgURL = reader.result; 
				  this.lg = true;
				}
				  
				
				//this.lg = window.URL.createObjectURL(event.target.files[0]);
				}
			if(fileIndex == 'cover_image') this.form.patchValue({'cover_image':event.target.files[0]['name']});
			if(fileIndex == 'video') this.form.patchValue({'video':event.target.files[0]['name']});
		}
    }
  
  ngOnInit(): void {
	  let cn = (this.formData.ci!='' && this.formData.ci != null ) ? this.formData.ci:this.formData.cn[0]['id'];
		this.form = this.formBuilder.group({
				  video : [''],
				  first_name : [this.formData.fn,Validators.required],
				  description : ['',Validators.required],
				  country_id : [cn,Validators.required],
				  address : [this.formData.a,Validators.required],
				  city : [this.formData.c,Validators.required],
				  zip_code : [this.formData.zc,Validators.required],
				  enc : [this.formData.vs],
				  phone_number : [this.formData.p]
				});
		this.countriesList =this.formData.cn;
 
  }
  
 get af() { return this.form.controls; }
	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
		if(firstInvalidControl){
			firstInvalidControl.focus(); //without smooth behavior
		}
	}
  onSubmit(){
    if(this.form.invalid){
		this.submitted = true;
		this.scrollToFirstInvalidControl();
		return;
     }	 
	
	this.errors = false;
	this.submitted = false;
	this.loading = true; 
	const formData = new FormData();
	
	
	
	if(this.form.get('first_name').value) formData.append('first_name', this.form.get('first_name').value);
	if(this.form.get('description').value) formData.append('description', this.form.get('description').value);
	if(this.form.get('country_id').value) formData.append('country_id', this.form.get('country_id').value);
	if(this.form.get('address').value) formData.append('address', this.form.get('address').value);
	if(this.form.get('city').value) formData.append('city', this.form.get('city').value);
	if(this.form.get('zip_code').value) formData.append('zip_code', this.form.get('zip_code').value);
	if(this.form.get('enc').value) formData.append('enc', this.form.get('enc').value);
	if(this.form.get('phone_number').value) formData.append('phone_number', this.form.get('phone_number').value);
	
	/*
	if(this.form.get('website').value) formData.append('website', this.form.get('website').value);
	if(this.form.get('facebook').value) formData.append('facebook', this.form.get('facebook').value);
	if(this.form.get('linkedin').value) formData.append('linkedin', this.form.get('linkedin').value);
	if(this.form.get('xing').value) formData.append('xing', this.form.get('xing').value);
	*/
	
	if(this.fileSource['video'] !=null){
		formData.append('video', this.fileSource['video']);
	}
	 
	 	if(this.fileSource['logo'] !=null){
		formData.append('logo', this.fileSource['logo']);
	}
	 
	 
	 
	 
	 this.mainService.employer_complete_profile(formData).subscribe(
		response => { 
			this.submitted = true;
			this.loading = false;
			if(response.status == 'success'){
				this.toastr.success(response.msg);
				var res = {};
				res['data'] 	= response.data;
				res['token'] 	= response.token;
				res['mst'] 		= response.mst;
				localStorage.setItem('authUser', JSON.stringify(res));
				this.router.navigate(['/create-search-order']);
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
		},
        (error) => {                              
          this.loading = false;  
		}
	); 
   
  }


}
