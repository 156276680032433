<!-- <p>footer works!</p> -->
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>


<footer>
    <div class="container">

        <div class="col-lg-12 p-0">
            <div class="row">

                <div class="col-md-10">
                    <div class="link">
                        <nav>
                            <a href="#"></a>
                            <a href="#"> yourcraftjob.de © 2021 </a>
                            <a  href="#" (click)="showPopup('privacy')"> <span>|</span> {{ 'Imprint & privacy policy' | translate }} <span>|</span> </a>
                            <a  href="#" (click)="showPopup('terms')" > {{ 'Terms & Conditions of Use' | translate }}</a>
                            
                        </nav>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="social">
                        <nav>
                            <a href="https://www.facebook.com/yourcraftjob/" target="_blank"><img src="../../../assets/images/fb.png"></a>
                            <a href="https://de.linkedin.com/company/yourcraftjob?trk=public_profile_topcard-current-company"  target="_blank"><img src="../../../assets/images/linkdin.png"></a>
                            <a href="https://www.youtube.com/channel/UC2KnNIcuxzbXogltWwaNlQw/featured"  target="_blank"><img src="../../../assets/images/you-tub.png"></a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

    </div>




</footer>
<div class="cookieButton_cont" *ngIf="!checkCookie">
<input id="cookieButton" name="cookieButton" type="checkbox" />
<div id="cookieBar">
    <div class="container">
	<p>{{ 'We use cookies and similar technologies to recognize your repeat visits and preferences, to measure the effectiveness of campaigns, and improve our websites. For settings and more information about cookies, view our' | translate }} <a href="#!" (click)="showPopup('cookie-policy')"> {{ 'Cookie Policy' | translate }}</a>. {{ 'By clicking “I accept” on this banner or using our site, you consent to the use of cookies.' | translate }}
      </p>
      <button (click)="accept_cookie()">{{ 'I accept' | translate }} </button>
      <button  (click)="decline_cookie()" >{{ 'Decline' | translate }}  </button>
</div>
</div>
</div> 