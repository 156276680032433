import { Injectable } from '@angular/core';
import { MainService } from 'src/app/main.service';

@Injectable()
export class DataService {
  countriesList = []
  constructor(private mainService: MainService) {   

  }
   
 
}