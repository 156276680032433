import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-create-search-order',
  templateUrl: './create-search-order.component.html'
})
export class CreateSearchOrderComponent implements OnInit {
	 
	pid:any = 0;	
	step = 0;	
	form :FormGroup;
	submitted = false;
	loading = true;
	errors:any;
	stepData:any;
	authData:any;
	activeTab = 'add-search-order';
	

	constructor(
		private mainService: MainService,  
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private router: Router
	) {
		 
	}
	setPid(pid):void{
		localStorage.setItem('pid',pid);
		this.pid = pid;
	}
	setLoading(ld):void{	
		this.loading= true;
		 
	}
	setunLoading(ld):void{	
		this.loading= false;
		 
	}
	setPStep(step):void{
		localStorage.setItem('pstem',step);
		 console.log(step);
		this.step = step;
	}
	setStepData(stepData):void{
		this.stepData = stepData;
	}
	ngOnInit(): void {
		const urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');  
		if(id){
			this.setPid(id);
		}
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		
		this.authData= JSON.parse(localStorage.getItem('authUser')).data;
	
		
		this.mainService.create_search_order(token,this.pid).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){
					let d = response.data;
					this.stepData	= response.data;
					
					if(response.step == 5 && this.stepData.special_features.length==0){
						response.step = 6;
					}
					 
					if(response.step == 7){
						response.step = 1;
					}
					
					this.setPStep(response.step);
					this.setPid(response.data.id);
					
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
		
	}
	 

}
