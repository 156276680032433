<app-header></app-header>

<section class="terms-page">
    <div class="container">
        <div class="terms-box">
            <h3> {{ privacyContent.title }}</h3>
                <p  *ngIf="privacyContent && privacyContent.body" [innerHtml]="privacyContent.body"></p>
        </div>
        
    </div>
</section>
<section class="footer-login">
    <div class="login-card w-100 bg-brown">
        <div class="container">
            <div class="card-inner">

                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-4">
                            <div class="head">
                                <h3>{{ 'Get started now' | translate }}</h3>
                            </div>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                        data-bs-target="#home1" type="button" role="tab" aria-controls="home"
                                        aria-selected="true"><span><img class="deactive"
                                                src="assets/images/JobSeekerBlue.png"></span><span><img
                                                class="active"
                                                src="assets/images/job-seeker-active.png"></span>{{ 'Employer' | translate }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab1" data-bs-toggle="tab"
                                        data-bs-target="#profile1" type="button" role="tab" aria-controls="profile"
                                        aria-selected="false"><span><img class="deactive"
                                                src="assets/images/name-tag-active.png"></span><span><img
                                                class="active"
                                                src="assets/images/name-tag.png"></span>{{ 'Profile' | translate }}</button>
                                </li>

                            </ul>
                        </div>
                        <div class="col-lg-8">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home1" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="form-field">
                                        <form>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="{{ 'Enter email' | translate }}">
                                                        <!-- <small id="emailHelp" class="form-text text-muted">We'll
                                                            never share
                                                            your email with anyone else.</small> -->
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control"
                                                            id="exampleInputPassword1" placeholder="{{ 'Password' | translate }}">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">

                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            id="exampleCheck1">
                                                        <label class="form-check-label" for="exampleCheck1">{{ 'By submitting the form, you agree to our data protection' | translate }}</label>
                                                    </div>
                                                    <div class="theme-btn">
                                                        <button type="submit" class="btn">{{ 'Submit' | translate }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile1" role="tabpanel"
                                    aria-labelledby="profile-tab1">
                                    <div class="form-field">
                                        <form>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="{{ 'Enter email' | translate }}">
                                                        <!-- <small id="emailHelp" class="form-text text-muted">We'll
                                                            never share
                                                            your email with anyone else.</small> -->
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control"
                                                            id="exampleInputPassword1" placeholder="{{ 'Password' | translate }}">
                                                    </div>
                                                </div>
                                                <div class="col-6">

                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            id="exampleCheck1">
                                                        <label class="form-check-label" for="exampleCheck1">{{ 'By submitting the form, you agree to our data protection' | translate }}</label>
                                                    </div>
                                                    <div class="theme-btn">
                                                        <button type="submit" class="btn">{{ 'ok' | translate }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
