import { Component, OnInit,Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import * as globalConstant from 'src/app/global_constant'; 
import {ChatService} from 'src/app/services/chat.service';
import { DatePipe } from '@angular/common';
import { MainService } from 'src/app/main.service';
declare var $: any;
@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html'
})
export class AdminHeaderComponent implements OnInit {
  APP_URI = globalConstant.APP_URI;	
  loggedIn: boolean; 
  authData:any; 
  @Input() dashboardData;
  notifications:any;
  constructor(  private mainService: MainService,   private authService: SocialAuthService,public datepipe: DatePipe,   private router: Router, private chatService:ChatService) { }
CheckDate(d){
	 
	  var date =new Date();
	  return (this.datepipe.transform(d, 'yyyy-MM-dd') == this.datepipe.transform(date, 'yyyy-MM-dd'));
  }
  ngOnInit(): void {
	  if(localStorage.getItem('authUser')){
		this.authData = JSON.parse(localStorage.getItem('authUser')).data;
		 
		this.loggedIn = true;
	 }
	// console.log(this.dashboardData);
	 if(this.dashboardData && this.dashboardData.notifications){
		 this.notifications= this.dashboardData.notifications;
		 localStorage.setItem('notifications',JSON.stringify(this.notifications));
	 }
	 if(localStorage.getItem('notifications')){
		 this.notifications = JSON.parse(localStorage.getItem('notifications'));
	 }
  }
 
  gotoHome(): void {
	   this.router.navigate(['/']);
  } 
  readNotification(n) {
	  console.log(n);
	  if(n.model == 'UserTransaction'){
		  if(this.authData.uri == 3 ) this.router.navigate(['/billing']);
		  
	  }
	  if(n.model == 'Match'){
		  if(this.authData.uri == 2 ) this.router.navigate(['/interest']);
		  if(this.authData.uri == 3 ) this.router.navigate(['/matches']);
		 
	  }
	  	
		var token  = JSON.parse(localStorage.getItem('authUser')).token;	
		var mst  = JSON.parse(localStorage.getItem('authUser')).mst;	
	   this.mainService.read_notification(token,n.id).subscribe(
		response => { 
			
			if(response.status == 'success'){
				var res = {};
				res['data'] 	= response.authData;
				res['token'] 	= token;
				res['mst'] 		= mst;
				localStorage.setItem('authUser', JSON.stringify(res));
				this.dashboardData = response.data;
				if(this.dashboardData && this.dashboardData.notifications){
					localStorage.setItem('notifications',JSON.stringify(this.dashboardData.notifications));
				}
				this.authData = JSON.parse(localStorage.getItem('authUser')).data;
				 
				 this.notifications = JSON.parse(localStorage.getItem('notifications'));
			}
			
		},
        (error) => {                              
            
		 
		}
	);
	  return false;
  }
  logout(): void {
	  //this.authService.signOut();
	  localStorage.removeItem('authUser');
	  sessionStorage.removeItem('authUser');
	  let requestBody={'id':this.authData.i}
	  this.chatService.userLogout(requestBody);
	  this.router.navigate(['/']);
  }
  ngAfterViewInit() {
  $('.top-header .back-new').click(function(){
		$('main').toggleClass('margin-main')
		$('.property.side-nav').toggleClass('full-width')
		$('.top-header .back-new').toggleClass('trans')
	});
	if(localStorage.getItem('notifications')){
		 this.notifications = JSON.parse(localStorage.getItem('notifications'));
	 }
  }
}
