<header class="top-header">
    <div class="container-fluid px-0 px-md-3">
        <div class="col-md-12 px-0 px-md-3">
            <div class="row">
                <div class="col-2 col-md-6 col-sm-7 ">

                    <!-- <div class="back-new">
                        <i class="fa fa-angle-right"></i>
                    </div> -->
                </div>
                <div class=" col-10 col-md-6 col-sm-5 ">

                    <div class="user-main-header">
                        <div class="notifications">
                            <div class="box noti-drop">
                                <a href="#!">
                                    <div class="images">
                                        <img src="/assets/images/notification.svg">
                                        <div class="count" *ngIf="authData.nc">
                                            {{authData.nc}}
                                        </div>
										 
                                    </div>
                                </a>

                                <div class="notiDropdown">
                                    <div class="notiDropdown_inner">
                                        <ul class="list-unstyled mb-0"  *ngIf="authData.nc">
                                            <li *ngFor="let item of notifications">
                                                <a href="#!" (click)="readNotification(item)">
                                                    {{item.msg}}
													<span *ngIf="CheckDate(item.created_at)">{{ item.created_at | date : 'shortTime' }}</span>
											<span   *ngIf="CheckDate(item.created_at) == false">{{ item.created_at | date : 'shortDate' }}</span>
										
													
                                                    
                                                </a>
                                            </li>
                                            
                                        </ul>
                                        <ul class="list-unstyled mb-0"  *ngIf="authData.nc == 0">
                                            <li >
                                                <a href="#!" >
                                                      {{"No notifications" | translate}}
                                                    
                                                </a>
                                            </li>
                                            
                                        </ul>
                                        <a href="#!" class="viewall_noti d-none">{{ 'View All' | translate }}</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="box"  >
                                <a href="#" routerLink="/chat">  
                                    <div class="images">
                                    <img src="/assets/images/message-2.svg" >
                                        <div class="count" *ngIf="authData.cc">
                                            {{authData.cc}}
                                        </div>
                                    </div>
								  </a>

                            </div>
                        </div>
                        <div class="d-flex user-top">

                                
								<ng-template #user_blank_image> <img src="/assets/images/user_blank_image.png"> </ng-template>
							 
                                 
                            <div class="top-user1"  *ngIf="authData.uri == 2" style="background-image: url({{APP_URI + 'uploads/users/' + authData.im}});">
                               <img src="{{APP_URI + 'uploads/users/' + authData.im}}" alt="" *ngIf="authData.im != null else user_blank_image"  />
                            </div>
							<div class="top-user1" *ngIf="authData.uri == 3" style="background-image: url({{APP_URI + 'uploads/service_providers/' + authData.lo}});">
                               <img src="{{APP_URI + 'uploads/service_providers/' + authData.lo}}" alt="" *ngIf="authData.lo != null else user_blank_image"  />
                            </div>

                            <div class="dropdown">
                                <div class="drop-inner">
                                    <div class="userDropmail">
                                        <span class="user-id"><a routerLink="/dashboard">{{authData.fn}}</a></span>
                                        <span class="userMail">{{authData.e}}</span>
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/my-profile" *ngIf="authData.uri == 2">{{'My account' | translate}}</a>
                                    <a href="javascript:void(0);" routerLink="/company-profile" *ngIf="authData.uri == 3">{{'Company Profile' | translate}}</a>
                                    <a href="javascript:void(0);" routerLink="/change-password">{{'Change Password' | translate}}</a>
                                    <a href="#" (click)="logout()">
                                        <span class="mr-2">
                                            <img class="logout-simp" src="./../assets/images/Exit.svg">
                                            <img class="logout-active" src="./../assets/images/Exit-active.svg">
                                        </span> {{'logout' | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>