<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
						
						<div class="radios-and-checks">
                                    
                                    <div class="radios-and-checks-box" >
                                        <div class="heading">
                                            <!-- <h2>Was ist Dein Beruf?</h2> -->
                                            <h2>{{ 'What is job category?' | translate }}</h2>
                                        </div>
                                     <div class="row">	
										<div class="col-6" *ngFor="let option of categoryList">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" [value]="option.id"  [id]="'Category' + option.id" name="category_id" class="form-check-input" formControlName="category_id" (change)="checkCat($event)" />
                                                    <label class="form-check-label" for="Category{{option.id}}">{{option.name}}</label>
                                                </div>
                                                
                                            </div> 
											
												<div class="col-sm-12">
                                    <div  >
											<input type="hidden" [ngClass]="(submitted && ((errors && errors.category_id)  || af.category_id.errors )) ? 'is-invalid' : ''" />
											<div *ngIf="submitted && errors && errors.category_id" class="invalid-feedback">{{errors.category_id[0]}}</div>
								<div *ngIf="submitted && af.category_id.errors"  class="invalid-feedback">
									 <div *ngIf="af.category_id.errors.required">{{ 'Please choose a category.' | translate }}
                                         <!-- Occupation is required. -->
                                        </div>
									 
								</div>
                                        
                                    </div>
                                </div>
 </div> 
	
                           
                                    </div>
  <div class="radios-and-checks-box" *ngIf="optionList.length > 0">
                                        <div class="heading">
                                            <!-- <h2>Was ist Dein Beruf?</h2> -->
                                            <h2>{{ 'What is your profession?' | translate }}</h2>
                                        </div>
                                         <div class="row">
						 
                                   <div class="col-6" *ngFor="let option of optionList">
								  
										<div class="form-check">
											<input type="checkbox"  class="form-check-input" [checked]="isChecked(option.id,'occupation_id')"  [value]="option.id" id="{{'Occupation' + option.id}}" (change)="onCheckboxChange($event, 'occupation_id')"    />
											<label class="form-check-label" for="Occupation{{option.id}}">{{option.name}}</label>
										</div>
								  
 
                                                
                                            </div>

                                   
								<div class="col-sm-12">
                                    <div  >
											<input type="hidden" [ngClass]="(submitted && ((errors && errors.occupation_id)  || af.occupation_id.errors )) ? 'is-invalid' : ''" />
											<div *ngIf="submitted && errors && errors.occupation_id" class="invalid-feedback">{{errors.occupation_id[0]}}</div>
								<div *ngIf="submitted && af.occupation_id.errors"  class="invalid-feedback">
									 <div *ngIf="af.occupation_id.errors.required">
                                          
                                          {{"Please select occupation!" | translate}}
										  
                                        </div>
									 
								</div>
                                        
                                    </div>
                                </div>
								   
                                
							 
                            </div>
                           
                                    </div>

                                    <div class="theme-btn">
                                        <button type="submit" class="btn">{{ 'Further' | translate }}</button>
                                    </div>

                            
                        </div>
						
                           
                           
                            
                            
                        </form>