<app-header></app-header> 
 <!-- step1 -->
<app-applicant-verification [formData]="stepData"  *ngIf = "step == 1"></app-applicant-verification>
<app-employer-verification [formData]="stepData"  *ngIf = "step == 2"></app-employer-verification> 
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="sign-auth-form" [ngStyle]="{'background-image': (EmployerSignup) ? 'url(assets/images/emp-sign-up-side.jpg)' : 'url(assets/images/appli-sign-up.jpg)'}" *ngIf = "step == 0">
    <div class="row">
        <div class="col-lg-6">
            <div class="login-side-image">
                <div class="image-tag-top">
                    <div class="img-tag">
                        <h2>{{ 'Registration' | translate }}</h2>
                        <p *ngIf="EmployerSignup == false">{{ 'Create your profile and receive direct inquiries from interested companies.' | translate }}</p>
                        <p *ngIf="EmployerSignup">{{ 'Create a company profile and find suitable candidates for your company today.' | translate }}</p>

                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="login-top">
                <div class="login-form">
                    <div class="form-field">
                        <div class="head reg-head ">
                            <h2>{{ 'Get started now' | translate }}</h2>
                        </div>
                        <div class="social-part" *ngIf="checkCookie">
                            <div class="social">
                                <button class="img google" (click)="signInWithFB()">
                                    <img src="assets/images/fb-blue.png">
                                </button>
                                <button class="img google" (click)="signInWithGoogle()">
                                    <img src="assets/images/google.svg">
                                </button>
                            </div>
                            <div class="line-sec">
                                <p>{{ 'OR' | translate }}</p>
                            </div>
                        </div>
                      
                        <div class="login-card applicant-login">
                            
                            <div class="card-inner">
                               
                                <div class="card-content">
                        
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button  class="nav-link active" id="home-tab" (click)="changeEmployer(1)"  data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><span><img
                                                        class="deactive"
                                                        src="assets/images/JobSeekerBlue.png"></span><span><img class="active"
                                                        src="assets/images/job-seeker-active.png"></span>{{ 'Companies' | translate }}</button >
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link " id="profile-tab" (click)="changeEmployer(0)"  data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><span><img
                                                        class="deactive"
                                                        src="assets/images/name-tag-active.png"></span><span><img
                                                        class="active" src="assets/images/name-tag.png"></span>{{ 'candidate' | translate }}</button>
                                        </li>
                        
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel"
                                            aria-labelledby="home-tab">
                                            <div class="form-field">
                                                <form  [formGroup]="employerForm" (ngSubmit)="onSubmit1()" autocomplete="off">

						
						
                                                    <div class="form-group" >
                                                        <input type="email" name="email"  [ngClass]="(submitted && ((errors && errors.email)  || ef.email.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="email" aria-describedby="emailHelp" placeholder="E-Mail">
                                                        <div *ngIf="submitted && errors && errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
                                                        <div *ngIf="submitted && ef.email.errors"  class="invalid-feedback">
                                                             <div *ngIf="ef.email.errors.required">{{ 'Email is required.' | translate }}</div>
                                                             <div *ngIf="ef.email.errors.email">{{ 'Please enter a valid email.' | translate }}</div>
                                                        </div>
                                               
                                                            
                                                            
                                                            
                                                            
                                                        <small id="emailHelp" class="form-text text-muted">{{ 'We will never share your email with anyone.' | translate }}</small>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" 
                                                        [ngClass]="(submitted && ((errors && errors.password)  || ef.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                          autocomplete="new-password"  formControlName="password"
                                                            placeholder="{{ 'password' | translate }}">  
                                                            <div *ngIf="submitted && errors && errors.password" class="invalid-feedback">{{errors.password[0]}}</div>
                                                        <div *ngIf="submitted && ef.password.errors"  class="invalid-feedback">
                                                             <div *ngIf="ef.password.errors.required">{{ 'Password is required.' | translate }}</div>
                                                            
                                                        </div>
                                               
                                                             
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" [ngClass]="(submitted && ((errors && errors.toc)  || ef.toc.errors )) ? 'form-check-input is-invalid' : 'form-check-input'"   id="customCheck1" checked="" formControlName="toc">
                                                        <label class="form-check-label" for="customCheck1">{{ 'I have the' | translate }} <a  href="#" (click)="showPopup('terms')"> <b>{{ 'Terms of Use' | translate }}</b></a> {{ 'and the' | translate }} <a  href="#" (click)="showPopup('privacy')"> <b> {{ 'Data protection' | translate }}</b> </a> 
                                                            {{ 'read and understood by YourCraftJob and I hereby agree to them. I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}
                                                        </label>
                                                         
                                                         <div *ngIf="submitted && errors && errors.toc" class="invalid-feedback">{{errors.toc[0]}}</div>
                                                        <div *ngIf="submitted && ef.toc.errors"  class="invalid-feedback">
                                                             <div *ngIf="ef.toc.errors.required">{{ 'You have to accept the terms and conditions!' | translate }}</div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                        
                                                    <div class="theme-btn">
                                                        <button type="submit" class="btn">{{ 'to register' | translate }}</button>
                                                    </div>
                                                    <div class="dont-hv-ac">
                                                        <p>{{ 'Do you have an account' | translate }}  <a  routerLink="/login"  href="#">{{ 'log in' | translate }}</a></p>
                        
                                                    </div>
                        
                                                </form>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade " id="profile" role="tabpanel"
                                            aria-labelledby="profile-tab">
                                            <div class="form-field">
                                                <form  [formGroup]="apllicantForm" (ngSubmit)="onSubmit()" autocomplete="off">

						
						
                                                    <div class="form-group" >
                                                        <input type="email" name="email"  [ngClass]="(submitted && ((errors && errors.email)  || af.email.errors )) ? 'form-control is-invalid' : 'form-control'"   autocomplete="new-password" formControlName="email" aria-describedby="emailHelp" placeholder="E-Mail">
                                                        <div *ngIf="submitted && errors && errors.email" class="invalid-feedback">{{errors.email[0]}}</div>
                                                        <div *ngIf="submitted && af.email.errors"  class="invalid-feedback">
                                                             <div *ngIf="af.email.errors.required">{{ 'Email is required.' | translate }}</div>
                                                             <div *ngIf="af.email.errors.email">{{ 'Please enter a valid email address.' | translate }}</div>
                                                        </div>
                                               
                                                            
                                                            
                                                            
                                                            
                                                        <small id="emailHelp" class="form-text text-muted">{{ 'We will never pass your email on to third parties.' | translate }}</small>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" 
                                                        [ngClass]="(submitted && ((errors && errors.password)  || af.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                          autocomplete="new-password"  formControlName="password"
                                                            placeholder="{{ 'password' | translate }}">  
                                                            <div *ngIf="submitted && errors && errors.password" class="invalid-feedback">{{errors.password[0]}}</div>
                                                        <div *ngIf="submitted && af.password.errors"  class="invalid-feedback">
                                                             <div *ngIf="af.password.errors.required">{{ 'Password is required.' | translate }}</div>
                                                            
                                                        </div>
                                               
                                                             
                                                    </div>
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" [ngClass]="(submitted && ((errors && errors.toc)  || af.toc.errors )) ? 'form-check-input is-invalid' : 'form-check-input'"   id="customCheck2" checked="" formControlName="toc">
                                                        <label class="form-check-label" for="customCheck2">{{ 'I have the' | translate }} <a  href="#" (click)="showPopup('terms')"> <b>{{ 'Terms of Use' | translate }}</b></a> {{ 'and the' | translate }} <a  href="#" (click)="showPopup('privacy')"> <b> {{ 'Data protection' | translate }}</b> </a> 
                                                            {{ 'read and understood by YourCraftJob and I hereby agree to them. I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}
                                                        </label>
                                                         
                                                         <div *ngIf="submitted && errors && errors.toc" class="invalid-feedback">{{errors.toc[0]}}</div>
                                                        <div *ngIf="submitted && af.toc.errors"  class="invalid-feedback">
                                                             <div *ngIf="af.toc.errors.required">{{ 'You have to accept the terms and conditions!' | translate }}</div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="theme-btn">
                                                        <button type="submit" class="btn">{{ 'to register' | translate }}</button>
                                                    </div>
                                                    <div class="dont-hv-ac">
                                                        <p>{{ 'Do you have an account' | translate }}  <a  routerLink="/login"  href="#">{{ 'to register' | translate }}</a></p>
                        
                                                    </div>
                        
                                                </form>
                                            </div>
                                        </div>
                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

