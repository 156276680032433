<app-header></app-header>

<!-- step1 -->
<app-applicant-verification [formData]="step1Data"  *ngIf = "(step==1)"></app-applicant-verification>   
<app-employer-verification [formData]="step1Data"  *ngIf = "(step==2)"></app-employer-verification>   
<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<section class="banner points before after" *ngIf = "(step==0)" >
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-sm-8">
				
			
                <div class="banner-headings" *ngIf="(homeContent && homeContent.banner_content!='' && homeContent.banner_content!=null)"  [innerHtml]="homeContent.banner_content">
                </div>
				
            </div>
            <div class="col-xl-5 col-lg-4 col-sm-4">
                <div class="banner-person" *ngIf="(homeContent && homeContent.banner_image!='' && homeContent.banner_image!=null)">
                    <img [src]="homeContent.banner_image" alt="" />
                </div>
				
            </div>
            <div class="col-lg-5 col-xl-4">
                <div class="login-card">
                    <div class="card-inner">
                        <div class="head">
                            <h3>{{"Get started now" | translate }}</h3>
                        </div>
                        <div class="card-content">

                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" (click)="changeEmployer(1)" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><span><img class="deactive" src="assets/images/JobSeekerBlue.png"></span><span><img class="active" src="assets/images/job-seeker-active.png"></span>{{"Companies" | translate }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab" (click)="changeEmployer(0)"
                                        data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab"
                                        aria-controls="profile" aria-selected="false"><span><img class="deactive"
                                                src="assets/images/name-tag-active.png"></span><span><img class="active"
                                                src="assets/images/name-tag.png"></span>{{"candidate" | translate }}</button>
                                </li>

                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="form-field">
                                             <form [formGroup]="employerForm" (ngSubmit)="onSubmit(1)">
                                            <div class="form-group">
                                                <input type="email" name="email"
                                                    [ngClass]="(submitted==1 && ((errors && errors.email)  || ef.email.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                    autocomplete="new-password" formControlName="email"
                                                    aria-describedby="emailHelp" placeholder="{{'E-Mail' | translate}}">
                                                <div *ngIf="submitted==1 && errors && errors.email"
                                                    class="invalid-feedback">{{errors.email[0]}}</div>
                                                <div *ngIf="submitted==1 && ef.email.errors" class="invalid-feedback">
                                                    <div *ngIf="ef.email.errors.required">{{"Email is required." | translate }}</div>
                                                    <div *ngIf="ef.email.errors.email">{{"Please provide valid email." | translate }}</div>
                                                </div>
                                              
                                            </div>
                                            <div class="form-group">
                                                <input type="password" class="form-control"
                                                    [ngClass]="(submitted==1 && ((errors && errors.password)  || ef.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                    autocomplete="new-password" formControlName="password"
                                                    placeholder="{{'Passwort' | translate }}">
                                                <div *ngIf="submitted==1 && errors && errors.password"
                                                    class="invalid-feedback">{{errors.password[0]}}</div>
                                                <div *ngIf="submitted==1 && ef.password.errors" class="invalid-feedback">
                                                    <div *ngIf="ef.password.errors.required">{{"Password is required." | translate }}</div>

                                                </div>
                                            </div>
                                            <div class="social-part" *ngIf="checkCookie == 1">

                                                <div class="line-sec">

                                                    <div class="social">
                                                       
                                                        <button class="img google"   type="button"   (click)="signInWithFB()">
                                                            <img src="assets/images/fb-blue.png">
                                                        </button>
                                                        <button class="img google"   type="button"   (click)="signInWithGoogle()">
                                                            <img src="assets/images/google.svg">
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
											<div class="form-group allow_action text-center" *ngIf="checkCookie == 0">
													<label class="form-check-label">
                                                       {{'Google & Facebook Login is deactivated!' | translate }} 
                                                    </label>
												<div>	<button (click)="accept_cookie()" class="btn  btn-sm me-md-2 mr-2">{{'Allow' | translate }} </button>
													<button  class="btn  btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button></div>
                                                </div>
                                              <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" [ngClass]="(submitted==1 && ((errors && errors.toc)  || ef.toc.errors )) ? 'form-check-input is-invalid' : 'form-check-input'"   id="customCheck1"  formControlName="toc">
                                                        <label class="form-check-label" for="customCheck1">{{ 'I have the' | translate }}<a  href="#" (click)="showPopup('terms')"> <b> {{ 'Terms of Use' | translate }} </b> </a> {{ 'and the' | translate }} <a   href="#" (click)="showPopup('privacy')"> <b>{{ 'Data protection' | translate }}</b></a>
                                                             {{ 'read and understood by YourCraftJob and I hereby agree to them. I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}
                                                        </label>
                                                         <input type="hidden"  [ngClass]="(submitted==1 && ((errors && errors.toc)  || ef.toc.errors )) ? 'is-invalid' : ''"    />
                                                         <div *ngIf="submitted==1 && errors && errors.toc" class="invalid-feedback">{{errors.toc[0]}}</div>
                                                        <div *ngIf="submitted==1 && ef.toc.errors"  class="invalid-feedback">
                                                             <div *ngIf="ef.toc.errors.required">{{"You must accept the terms and conditions!" | translate }}</div>
                                                            
                                                        </div>
                                                        
                                                    </div>

                                            <div class="theme-btn">
                                                <button type="submit" class="btn">{{"Registrieren" | translate }}</button>
                                            </div>
                                        </form>
                                   
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="form-field">
                                        <form [formGroup]="applicantForm" (ngSubmit)="onSubmit(2)">
                                            <div class="form-group">
                                                <input type="email" name="email"
                                                    [ngClass]="(submitted==2 && ((errors && errors.email)  || af.email.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                    autocomplete="new-password" formControlName="email"
                                                    aria-describedby="emailHelp" placeholder="{{'E-Mail' | translate}}">
                                                <div *ngIf="submitted==2 && errors && errors.email"
                                                    class="invalid-feedback">{{errors.email[0]}}</div>
                                                <div *ngIf="submitted==2 && af.email.errors" class="invalid-feedback">
                                                    <div *ngIf="af.email.errors.required">{{"Email is required." | translate }}</div>
                                                    <div *ngIf="af.email.errors.email">{{"Please provide valid email." | translate }}</div>
                                                </div>
                                              
                                            </div>
                                            <div class="form-group">
                                                <input type="password" class="form-control"
                                                    [ngClass]="(submitted==2 && ((errors && errors.password)  || af.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                    autocomplete="new-password" formControlName="password"
                                                    placeholder="{{'Passwort' | translate }}">
                                                <div *ngIf="submitted==2 && errors && errors.password"
                                                    class="invalid-feedback">{{errors.password[0]}}</div>
                                                <div *ngIf="submitted==2 && af.password.errors" class="invalid-feedback">
                                                    <div *ngIf="af.password.errors.required">{{"Password is required." | translate }}</div>

                                                </div>
                                            </div>
                                            <div class="social-part" *ngIf="checkCookie == 1">

                                                <div class="line-sec">

                                                    <div class="social">
                                                       
                                                        <button class="img google"   type="button"   (click)="signInWithFB()">
                                                            <img src="assets/images/fb-blue.png">
                                                        </button>
                                                        <button class="img google"   type="button"   (click)="signInWithGoogle()">
                                                            <img src="assets/images/google.svg">
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
											<div class="form-group allow_action text-center" *ngIf="checkCookie == 0">
													<label class="form-check-label">
                                                       {{'Google & Facebook Login is deactivated!' | translate }} 
                                                    </label>
												<div>	<button (click)="accept_cookie()" class="btn  btn-sm me-md-2 mr-2">{{'Allow' | translate }} </button>
													<button  class="btn  btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button></div>
                                                </div>
                                              <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" [ngClass]="(submitted==2 && ((errors && errors.toc)  || af.toc.errors )) ? 'form-check-input is-invalid' : 'form-check-input'"   id="customCheck2"  formControlName="toc">
                                                        <label class="form-check-label" for="customCheck2">{{ 'I have the' | translate }}<a   href="#" (click)="showPopup('terms')"> <b> {{ 'Terms of Use' | translate }}</b> </a>   {{ 'and the' | translate }} <a   href="#" (click)="showPopup('privacy')"> <b>{{ 'Data protection' | translate }}</b></a>
                                                            {{ 'read and understood by YourCraftJob and I hereby agree to them. I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}
                                                        </label>
                                                         <input type="hidden"  [ngClass]="(submitted==2 && ((errors && errors.toc)  || af.toc.errors )) ? 'is-invalid' : ''"    />
                                                         <div *ngIf="submitted==2 && errors && errors.toc" class="invalid-feedback">{{errors.toc[0]}}</div>
                                                        <div *ngIf="submitted==2 && af.toc.errors"  class="invalid-feedback">
                                                             <div *ngIf="af.toc.errors.required">{{"You must accept the terms and conditions!" | translate }}</div>
                                                            
                                                        </div>
                                                        
                                                    </div>

                                            <div class="theme-btn">
                                                <button type="submit" class="btn">{{"Registrieren" | translate }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf = "(step==0)" >
<div *ngFor="let block of blocks" >
<div [innerHtml]="block.content"></div>
</div>
</div>
  
<section class="footer-login" *ngIf = "(step==0)">

    <div class="login-card w-100 bg-brown">
        <div class="container">
            <div class="card-inner">

                <div class="card-content">
                    <div class="row">

                        <div class="col-lg-4">
                            <div class="head">
                                <h3>{{"Get started now" | translate }}</h3>
                            </div>
                            <ul class="nav nav-tabs" id="myTab1" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab1" (click)="changeEmployer(1)" data-bs-toggle="tab"
                                        data-bs-target="#home1" type="button" role="tab" aria-controls="home"
                                        aria-selected="true"><span><img class="deactive"
                                                src="assets/images/JobSeekerBlue.png"></span><span><img class="active"
                                                src="assets/images/job-seeker-active.png"></span>{{"Companies" | translate }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab1" (click)="changeEmployer(0)" data-bs-toggle="tab"
                                        data-bs-target="#profile1" type="button" role="tab" aria-controls="profile1"
                                        aria-selected="false"><span><img class="deactive"
                                                src="assets/images/name-tag-active.png"></span><span><img class="active"
                                                src="assets/images/name-tag.png"></span>{{"candidate" | translate }}</button>
                                </li>

                            </ul>
                        </div>
                        <div class="col-lg-8">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home1" role="tabpanel"
                                    aria-labelledby="home-tab1">
                                    <div class="form-field">
                                           <form [formGroup]="employerForm1" (ngSubmit)="onSubmit(3)">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="email" name="email"
                                                            [ngClass]="(submitted==3 && ((errors && errors.email)  || ef1.email.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                            autocomplete="new-password" formControlName="email"
                                                            aria-describedby="emailHelp" placeholder="{{'E-Mail' | translate }}">
                                                        <div *ngIf="submitted==3 && errors && errors.email"
                                                            class="invalid-feedback">{{errors.email[0]}}</div>
                                                        <div *ngIf="submitted==3 && ef1.email.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="ef1.email.errors.required">{{"Email is required." | translate }}
                                                            </div>
                                                            <div *ngIf="ef1.email.errors.email">{{"Please provide valid
                                                                email." | translate }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control"
                                                            [ngClass]="(submitted==3 && ((errors && errors.password)  || ef1.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                            autocomplete="new-password" formControlName="password"
                                                            placeholder="{{'Passwort' | translate }}">
                                                        <div *ngIf="submitted==3 && errors && errors.password"
                                                            class="invalid-feedback">{{errors.password[0]}}</div>
                                                        <div *ngIf="submitted==3 && ef1.password.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="ef1.password.errors.required">{{"Password is
                                                                required." | translate }}</div>

                                                        </div>
                                                    </div>

                                                    <div class="social-part form-group" *ngIf="checkCookie == 1">

                                                        <div class="line-sec">
        
                                                            <div class="social">
                                                               
                                                                <button class="img google"   type="button"   (click)="signInWithFB()">
                                                                    <img src="assets/images/fb-blue.png">
                                                                </button>
                                                                <button class="img google"   type="button"  (click)="signInWithGoogle()">
                                                                    <img src="assets/images/google.svg">
                                                                </button>
                                                            </div>
        
                                                        </div>
                                                    </div>
													<div class="form-group allow_action" *ngIf="checkCookie == 0">
													<label class="form-check-label">
                                                       {{'Google & Facebook Login is deactivated!' | translate }} 
                                                    </label>
												<div>	<button (click)="accept_cookie()" class="btn  btn-sm me-md-2 mr-2">{{'Allow' | translate }} </button>
													<button  class="btn  btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button>
                                                </div></div>
                                                </div>
                                                <div class="col-sm-6">



                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            id="exampleCheck3"
                                                            [ngClass]="(submitted==3 && ef1.toc.errors) ? 'form-check-input is-invalid' : 'form-check-input'"
                                                            checked="" formControlName="toc">
                                                        <label class="form-check-label" for="exampleCheck3">{{ 'I have the' | translate }} <a  href="#" (click)="showPopup('terms')" > <b>{{ 'Terms of Use' | translate }}</b></a> {{ 'and the' | translate }} <a  href="#" (click)="showPopup('privacy')"> <b> {{ 'Data protection' | translate }}</b> </a> 
                                                            {{ 'read and understood by YourCraftJob and I hereby agree to them. I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}</label>
                                                        <div *ngIf="submitted==3 && ef1.toc.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="ef1.toc.errors.required">{{ 'You must agree before submitting.' | translate }}!</div>
                                                        </div>

                                                    </div>

                                                    <div class="theme-btn">
                                                        <button type="submit" class="btn">{{"to register" | translate }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                  
									 </div>
                                </div>
                                <div class="tab-pane fade" id="profile1" role="tabpanel" aria-labelledby="profile-tab1">
                                    <div class="form-field">
                                        <form [formGroup]="applicantForm1" (ngSubmit)="onSubmit(4)">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <input type="email" name="email"
                                                            [ngClass]="(submitted==4 && ((errors && errors.email)  || af1.email.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                            autocomplete="new-password" formControlName="email"
                                                            aria-describedby="emailHelp" placeholder="{{'E-Mail' | translate }}">
                                                        <div *ngIf="submitted==4 && errors && errors.email"
                                                            class="invalid-feedback">{{errors.email[0]}}</div>
                                                        <div *ngIf="submitted==4 && af1.email.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="af1.email.errors.required">{{"Email is required." | translate }}
                                                            </div>
                                                            <div *ngIf="af1.email.errors.email">{{"Please provide valid
                                                                email." | translate }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control"
                                                            [ngClass]="(submitted==4 && ((errors && errors.password)  || af1.password.errors )) ? 'form-control is-invalid' : 'form-control'"
                                                            autocomplete="new-password" formControlName="password"
                                                            placeholder="{{'Passwort' | translate }}">
                                                        <div *ngIf="submitted==4 && errors && errors.password"
                                                            class="invalid-feedback">{{errors.password[0]}}</div>
                                                        <div *ngIf="submitted==4 && af1.password.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="af1.password.errors.required">{{"Password is
                                                                required." | translate }}</div>

                                                        </div>
                                                    </div>

                                                    <div class="social-part form-group" *ngIf="checkCookie == 1">

                                                        <div class="line-sec">
        
                                                            <div class="social">
                                                               
                                                                <button class="img google"   type="button"  (click)="signInWithFB()">
                                                                    <img src="assets/images/fb-blue.png">
                                                                </button>
                                                                <button class="img google"   type="button"   (click)="signInWithGoogle()">
                                                                    <img src="assets/images/google.svg">
                                                                </button>
                                                            </div>
        
                                                        </div>
                                                    </div>
													<div class="form-group allow_action" *ngIf="checkCookie == 0">
													<label class="form-check-label">
                                                       {{'Google & Facebook Login is deactivated!' | translate }} 
                                                    </label>
													<div><button (click)="accept_cookie()" class="btn  btn-sm me-md-2 mr-2">{{'Allow' | translate }} </button>
													<button  class="btn  btn-sm" (click)="showPopup('cookie-policy')" >{{'Cookie Policy' | translate }} </button>
                                                </div>
                                                </div></div>
                                                <div class="col-sm-6">



                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            id="exampleCheck4"
                                                            [ngClass]="(submitted==4 && af1.toc.errors) ? 'form-check-input is-invalid' : 'form-check-input'"
                                                            checked="" formControlName="toc">
                                                        <label class="form-check-label" for="exampleCheck4">{{ 'I have the' | translate }} <a   href="#" (click)="showPopup('terms')"> <b>{{ 'Terms of Use' | translate }}</b></a>{{ 'and the' | translate }} <a  href="#" (click)="showPopup('privacy')"> <b> {{ 'Data protection' | translate }}</b> </a> 
                                                            {{ 'read and understood by YourCraftJob and I hereby agree to them. I can revoke my consent at any time by sending an email to info@yourcraftjob.de.' | translate }}</label>
                                                        <div *ngIf="submitted==4 && af1.toc.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="af1.toc.errors.required">{{ 'You must agree before submitting.' | translate }}!</div>
                                                        </div>

                                                    </div>

                                                    <div class="theme-btn">
                                                        <button type="submit" class="btn">{{'to register' | translate}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>