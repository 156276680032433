import { Component, OnInit,Input, EventEmitter, Output,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'search-profile-step-6',
  templateUrl: './search-profile-step-6.component.html',
   styles: ['.theme-btn .back-btn { background-color: #003661; border-radius: 0; padding: 6px 20px 8px 20px; border-radius: 10px; font-size: 17px; color: #ffffff; font-weight: 500; width: auto; min-width: 140px; }']
})
export class SearchProfileStep6Component implements OnInit {
	@Output() setPStep = new EventEmitter();	
	@Output() setPid = new EventEmitter();
	@Output() setStepData = new EventEmitter();
 	
	@Input() formData;
	@Input() pId;
	form :FormGroup;
	filedList = ['contract_type','working_hours','salary','vacation','weekend_work'];
	static_features:any; 
	submitted = false;
	loading = false;
	errors:any;
	token:any;
	mst:any;
	fileSource:any = [];
	fileName = '';
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
  }
   ngAfterViewInit(){
	   
	   
	   window.scrollTo(0,0);
   }

onFileChange(event,fileIndex) {
        if (event.target.files.length > 0) {
			 
			this.fileSource[fileIndex].file = (event.target.files[0]);
			this.fileSource[fileIndex].file_name = (event.target.files[0]['name']);
				//event.target.files[0];
			//console.log(this.form.controls.files["controls"][fileIndex].controls);
			let fs = this.form.controls.files["controls"][fileIndex].controls.file.patchValue(event.target.files[0]['name']);
			
			//this.form.controls.files[fileIndex]["file_name"]  =(event.target.files[0]['name']);
			  
		}
    }
 
 
	ngOnInit(): void {
 
		this.form = this.formBuilder.group({
				  cv : [''],
				  file : [''],
				  location : [this.formData.location],
				  files: this.formBuilder.array([this.formBuilder.group({file:''})]),
				  step : [6],
				 
				});
			this.fileSource.push({'file_name': '','file' : ''});	
		
		
	
	}
  filedbError(fileIndex){
	  if(this.errors){
		if(this.errors['files.' + fileIndex]){
			return this.errors['files.' + fileIndex];
		}  
	  }
	  
		  return false;
	  
	  
	  
  }
 get af() { return this.form.controls; }
 
 addSellingPoint() {
    this.filesControll.push(this.formBuilder.group({file:''}));
	this.fileSource.push({'file_name': '','file' : ''});
  }

  deleteSellingPoint(index) {
    this.filesControll.removeAt(index);
	let arr = this.fileSource;
	for( var i = 0; i < arr.length; i++){ 
		if ( i === index) {
			arr.splice(i, 1); 
		}
	}
	
	this.fileSource = arr;
	//console.log(this.fileSource);
  }
 
 get filesControll() {
	
    return this.form.get('files') as FormArray;
  }
 fileError(fileIndex) {
	 this.fileSource[fileIndex].file_name =this.filesControll.controls[fileIndex]["controls"].file.value ;
	 console.log(this.filesControll.controls[fileIndex]["controls"]);
	return (this.filesControll.controls[fileIndex]["controls"].file.errors);
    return true;
	//'File name is required!';
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
	if(firstInvalidControl){
		
		firstInvalidControl.focus(); //without smooth behavior
	}
  }
	
 goBack (){
	 if(this.formData.special_features.length==0){
			this.setPStep.emit(4);
	} else {
	  this.setPStep.emit(5);
	}
  }
 
  onSubmit(){
	let arr = this.fileSource;
	//console.log(this.form);
	for( var i = 0; i < arr.length; i++){ 
		 if(arr[i]["file"] !=''){
			//console.log( this.filesControll.controls[i]["controls"][]);
			let fl = this.filesControll.controls[i]["controls"].file;
			 
			fl.setValidators(Validators.required);
			fl.updateValueAndValidity();
		 }
	}    
    if(this.form.invalid){
		
	  this.submitted = true;
	   this.scrollToFirstInvalidControl();
      return
     }	
	 
	
	
	
	 
	 
	
	 
	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true; 
	 const formData = new FormData();
	 
	for( var i = 0; i < arr.length; i++){ 
		if(this.fileSource[i].file_name!='' && this.fileSource[i].file!=''){
			formData.append('file_name['+i+']', this.fileSource[i].file_name);
			formData.append('files['+i+']', this.fileSource[i].file);
		}
	}
	 
		//formData.append('search_profile_files', this.fileSource);
		formData.append('location', this.form.get('location').value);
		formData.append('step', '6');
	  	
	 this.mainService.save_search_file(this.token,this.pId,formData).subscribe(
		response => {
			this.loading = false; 
			this.submitted = true;
			if(response.status == 'success'){
				
				let authUser= JSON.parse(localStorage.getItem('authUser'));
					authUser.data.icp = 2;	
					localStorage.setItem('authUser', JSON.stringify(authUser));
					this.toastr.success('Your profile is successfully completed.'); 
					this.router.navigate(['/candidate-profiles']);
				
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
				 this.scrollToFirstInvalidControl();
			}
		},
        (error) => {
		 	
            this.loading = false;
		}
	); 
    
  }


}