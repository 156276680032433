import { Component, OnInit,Input, EventEmitter, Output,ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
 
@Component({
  selector: 'search-profile-step-2',
  templateUrl: './search-profile-step-2.component.html',
  styles: ['.theme-btn .back-btn { background-color: #003661; border-radius: 0; padding: 6px 20px 8px 20px; border-radius: 10px; font-size: 17px; color: #ffffff; font-weight: 500; width: auto; min-width: 140px; }']
})
export class SearchProfileStep2Component implements OnInit {
	@Output() setPStep = new EventEmitter();	
	@Output() setPid = new EventEmitter();
	@Output() setStepData = new EventEmitter();  
	  
	
  @Input() formData;
  @Input() pId;
  form :FormGroup;
  filedList = ['graduation','education_qualification','work_experience','driving_license'];
  static_features:any; 
  submitted = false;
  loading = false;
  errors:any;
  token:any;
  mst:any;
  constructor(
	 
    private mainService: MainService,  
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
	private el: ElementRef
  ) { 
		let authUser= JSON.parse(localStorage.getItem('authUser'));
		this.mst	= authUser.mst;	
		this.token  = authUser.token;	
  }

   ngAfterViewInit(){
	   
	   
	   window.scrollTo(0,0);
   }		
 
	ngOnInit(): void {
		this.formData.graduation = (this.formData.graduation !='' && this.formData.graduation != null) ? parseInt(this.formData.graduation):'';	
		this.formData.education_qualification = (this.formData.education_qualification !='' && this.formData.education_qualification != null) ? parseInt(this.formData.education_qualification):'';	
		this.formData.work_experience = (this.formData.work_experience !='' && this.formData.work_experience != null) ? parseInt(this.formData.work_experience):'';	
		this.form = this.formBuilder.group({
				  graduation : [this.formData.graduation,Validators.required],
				  education_qualification : [(this.formData.education_qualification),Validators.required],
				  work_experience : [(this.formData.work_experience),Validators.required],
				  driving_license : this.formBuilder.array(this.formData.driving_license, [Validators.required]),
				  step : [2],
				 
				});
				
		 
		this.static_features = this.formData['static_features'];	
	
	}
	private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
	if(firstInvalidControl){
		
		firstInvalidControl.focus(); //without smooth behavior
	}
  }
	  
 get af() { return this.form.controls; }
 
onCheckboxChange(e,fld) {
	
    const checkboxFld: FormArray = this.form.get(fld) as FormArray;
    if (e.target.checked) {
		checkboxFld.push(new FormControl(e.target.value));
    } else {
       const index = checkboxFld.controls.findIndex(x => x.value === e.target.value);
       checkboxFld.removeAt(index);
    }
  }
  goBack (){
	  this.setPStep.emit(1);
  }
  isChecked(v,fld) {
	return this.form.get(fld).value.includes(v.toString());
	 
  }
  onSubmit(){
	    
    if(this.form.invalid){
	  this.submitted = true;
	  this.scrollToFirstInvalidControl();
      return
     }	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true;
	 this.mainService.save_search_profile(this.token,this.pId,this.form.value).subscribe(
		response => { 
			this.submitted = true;
			 this.loading = false;
			if(response.status == 'success'){
				 
				this.setPid.emit(response.data.id);
				this.setPStep.emit(response.step);
				this.setStepData.emit(response.data);
				
			}
			else {
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
				this.scrollToFirstInvalidControl();
			}
		},
        (error) => {                              
            this.loading = false;
		}
	); 
    
  }


}