<main class="dash-main">
    <!-- header setion start -->
<app-admin-header  [dashboardData]="dashboardData" ></app-admin-header>
<!-- header setion end -->
<!-- side navbaar section start-->

<app-sidebar ></app-sidebar>
 

 <div class="loader-wrapper" *ngIf="loading">
  <div class="loader"><img src="assets/images/logo-loader.png" alt="" /></div>
</div>
<!-- side navbaar section end-->
<!-- min page box  start--> 
<ng-template #nonewchat>
            <div class="dashboard-box dash-bx-pad" >
                <h3 class="dash-heading">{{ 'No New Chat Found'| translate}}</h3>
				</div>
				</ng-template>
				<ng-template #nocnt>
												<h3 >0</h3>
											</ng-template>
											
											
											<ng-template #nonotfi>
					
						<div class="dashboard-box graph-pd">
                            <h3 class="dash-heading">{{'No New Notifications Found' | translate}}</h3>
							<div class="notification-box ">
								
							</div>
						</div>
												 
					</ng-template>
 <div class="main-page-box" *ngIf="authData.uri == 2">
    <div class="container-fluid">
        <section class="new-matches" *ngIf="dashboardData">
          
			
			<ng-template #nonewint>
				<div class="dashboard-box dash-bx-pad">
				<h3 class="dash-heading">{{ 'no new inquiries' | translate}}</h3>
			</div>

									</ng-template>
			
            <div class="dashboard-box dash-bx-pad" *ngIf="dashboardData.interests && dashboardData.interests.length > 0; else nonewint">
                <h3 class="dash-heading">{{"New interested companies" | translate}}</h3>
 
                <div class="new-matches-box" *ngFor="let item of dashboardData.interests">
                    <div class="row">
                        <div class=" col-lg-9">

                            <div class="matches-image">
                                <img src="assets/images/user_blank_image.png" *ngIf="item.employer.logo == null"/>
								<img src="{{APP_URI + 'uploads/service_providers/' + item.employer.logo}}" *ngIf="item.employer.logo != null"/>
								  &nbsp;&nbsp;{{item.employer.first_name}} 
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-4 col-sm-6">
                            <div class="matches-image-con">
                                <div class="images">
								 <img src="assets/images/browse_9.png" *ngIf="item.occupation.image == null"/>
								<img src="{{APP_URI + 'uploads/occupations/' + item.occupation.image}}" *ngIf="item.occupation.image != null"/>
                                    
                                </div>
                                <div class="content">
                                    <h3>{{item.employer.first_name}}</h3>
                                    <p>{{item.occupation.name}}</p>
                                </div>
                            </div>
                        </div> -->
                        <div class=" col-lg-3">
                            <div class="matches-button">
                                <div class="btn-box ">
                                <div class="row">
 
                                    <div class="offset-xl-2 col-md-12 col-lg-12 col-xl-10">
									
									 <!-- 
                                        <button class="btn purple" (click)="accept_interest(item.id)">{{'Weitere Informationen' | translate}}</button> -->
                                        <button class="btn purple" routerLink="/interest">{{'additional Information' | translate}}</button>
                                    </div>
                                    <!-- <div class="col-6">
                                        <button class="btn brown" (click)="reject_interest(item.id)"><img
 
                                                src="/assets/images/close.png">Ablehnen</button>
                                    </div> -->
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
	   <section class="new-matches"  *ngIf="dashboardData">
	  
            <div class="dashboard-box dash-bx-pad" *ngIf="dashboardData.chats && dashboardData.chats.length > 0 ; else nonewchat">
                <!-- <h3 class="dash-heading">{{ 'Neue Nachrichten'| translate}}</h3> -->
                <div class="job-status-head flex-box pb-3">
                    <h3 class="dash-heading">{{'New Messages' | translate}}</h3>
                    <div class="notification-box ">
                        <div class="theme-btn brown" *ngIf="dashboardData.new_chats.length > 0">
                            <button type="btn" class="btn mt-0" routerLink="/chat">{{'View all messages' | translate}}</button>
                        </div>
                    </div> 
                </div>
			  <div class="row"  *ngFor="let item of dashboardData.chats">
                    <div class="col-sm-2">
                        <div class="matches-image">
						
							 <img src="assets/images/user_blank_image.png" *ngIf="item.from_user.logo == null"/>
							<img src="{{APP_URI + 'uploads/service_providers/' + item.from_user.logo}}" *ngIf="item.from_user.logo != null"/>
							 {{item.from_user.first_name}}   	  
                                                       
                            
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="chat-box">
                            <div class="matches-image-con">
                                <div class="images">
                                    <img src="/assets/images/msg-black.png" alt="msg-black">
                                </div>
                                <div class="content">
                                    <p>{{item.message}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="chat-box">

                            <form>
                                <div class="content">
                                    <p>{{"Input to Answer" | translate}}...</p>
                                </div>
                                <div class="input-answer">
                                    <input class="input-field-in">

                                    <button type="submit" class="btn send"><img
                                            src="/assets/images/side_3.png" alt="side3"></button>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="chat-and-count">
            <div class="col-12 p-0">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                            <!-- <div class="col-sm-6">
                                <div class="box graph graph_1 color-1 dashboard-box graph-pd">
                                    <div class="box-con flex-box">
                                        <div class="box-inn">
                                            <h3 *ngIf="dashboardData && dashboardData.interest_count > 0 ; else nocnt">{{dashboardData.interest_count}}</h3>
											
                                        </div>
                                        <div class="img-box">
                                            <img src="/assets/images/bewerber_3.png">
                                        </div>
                                    </div>
                                    <div class="con">
                                        <p>Interessierte Unternehmen</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="box graph graph_1 color-2 dashboard-box graph-pd">
                                    <div class="box-con flex-box">
                                        <div class="box-inn">
                                            
											 <h3 *ngIf="dashboardData && dashboardData.profile_count > 0 ; else nocnt">{{dashboardData.profile_count}}</h3>
                                            

                                        </div>
                                        <div class="img-box">
                                            <img src="/assets/images/bewerber_3.png">
                                        </div>
                                    </div>
                                    <div class="con">
                                        <p>{{'Search Profiles' | translate}}</p>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-12" *ngIf="dashboardData && dashboardData.graph && dashboardData.graph.length > 0">
                                <div class="job-status dashboard-box graph-pd">
                                    <div class="job-status-head flex-box">
                                        <h3 class="dash-heading">{{'New Matches' | translate}}</h3>
                                        <div class="toggles flex-box">
                                            <div class="form-check form-switch ">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" checked>
                                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                                    {{'Accepted' | translate}}</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" checked>
                                                <label class="form-check-label"
                                                    for="flexSwitchCheckChecked">{{'Request Recieved' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="month-dropdown flex-box">
                                       
                                        <div class="select-month">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected value="1">{{'This Month' | translate}}</option>
                                                <option value="2">{{'This Week' | translate}}</option>
                                                <option value="3">{{'This year' | translate}}</option>
                                               
                                            </select>
                                        </div>
                                    </div>
                                    <div class="grph-image">
			asdadad
			 
                                        <img src="/assets/images/graph.png">
                                    </div>
                                    <div class="points">
                                        <p class="one">{{'Accepted' | translate}}</p>
                                        <p class="two">{{'Request Received' | translate}}</p>
                                      
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-12" *ngIf="dashboardData && dashboardData.new_chats && dashboardData.new_chats.length > 0">
                                <!-- <div class="chats dashboard-box graph-pd" *ngIf="dashboardData.new_chats.length > 0">
                                    <div class="job-status-head flex-box">
                                        <h3 class="dash-heading">{{'Neue Nachrichten' | translate}}</h3>
                                        <div class="notification-box ">
                                            <div class="theme-btn brown" *ngIf="dashboardData.new_chats.length > 0">
                                                <button type="btn" class="btn mt-0" routerLink="/chat">{{'Alle Nachrichten anzeigen' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chats-box-top"  *ngFor="let item of dashboardData.new_chats">
                                        <div class="box chat-box">
                                            <div class="user-head flex-box">
                                                <div class="user">
                                                    <div class="image">
                                                        <div class="image-inner">
														
														 <img src="assets/images/user_blank_image.png" *ngIf="item.from_user.logo == null"/>
								<img src="{{APP_URI + 'uploads/service_providers/' + item.from_user.logo}}" *ngIf="item.from_user.logo != null"/>
								  
                                                             
                                                        </div>
                                                        <div class="activity" *ngIf="item.is_online">

                                                        </div>
                                                    </div>
                                                    <div class="user-name">
                                                        <p>{{item.from_user.first_name}}</p>
                                                          <h3>{{item.from_user.address}}</h3>
                                                    </div>
                                                </div>
                                                <div class="time">
                                                    <p>{{item.created_at | date : 'shortTime' }}</p>
                                                </div>
                                            </div>
                                            <div class="user-masg">
                                                <div class="msg">
                                                    <form>
                                                        <div class="content">
                                                            <p>{{ item.message }}</p>
                                                        </div>
                                                        <div class="input-answer">     
                                                            <button type="button" (click)="startChat(item.from_user_id)" class="btn send"><img src="/assets/images/side_3.png" alt="side3"></button>                   
                                                          
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
								 
								
								</div> -->
                            </div>
                        </div>
                    </div>
						<div class="col-lg-4">
					
                        <!-- <div class="dashboard-box graph-pd" *ngIf="dashboardData && dashboardData.notifications && dashboardData.notifications.length > 0; else nonotfi">
						
                            <h3 class="dash-heading">{{'Recent Notifications' | translate}}</h3>
 
                            <div class="notification-box ">
                                <div class="box " *ngFor="let item of dashboardData.notifications">
                                    <div class="matches-image-con">
                                        <div class="images color_1">
                                            <img src="/assets/images/Eye.png" alt="bnrows_9">
                                        </div>
                                        <div class="content" >
                                            <p [innerHtml]="item.msg"> 
                                            </p>
                                            
											<h5 *ngIf="CheckDate(item.created_at)">{{ item.created_at | date : 'shortTime' }}</h5>
											<h5   *ngIf="CheckDate(item.created_at) == false">{{ item.created_at | date : 'shortDate' }}</h5>
										
											
											
                                        </div>
                                    </div>
                                </div>
 
                            </div>
                        </div> -->
						
                    </div>
                </div>
                </div>
             
        </section>
    </div>
</div>
<!-- min page box  end--><!-- min page box  start-->
 <div class="main-page-box" *ngIf="authData.uri == 3">
    <div class="container-fluid">
	
			<ng-template #nonewmatch>
				<div class="dashboard-box dash-bx-pad">
				<h3 class="dash-heading">{{ 'No New Match Found!' | translate}}</h3>
			</div>
			</ng-template>
        <section class="new-matches" *ngIf="dashboardData">
            <div class="dashboard-box dash-bx-pad" *ngIf="dashboardData.newmatches && dashboardData.newmatches.length > 0; else nonewmatch">
                <h3 class="dash-heading">{{'New Matches' | translate}}</h3>
                <div class="new-matches-box" *ngFor="let item of dashboardData.newmatches">
                    <div class="row align-items-center">
                        <div class="col-lg-9">
                            <div class="matches-image">
                                 <img src="assets/images/user_blank_image.png" *ngIf="item.applicant.image == null"/>
								<img src="{{APP_URI + 'uploads/users/' + item.applicant.image}}" *ngIf="item.applicant.image != null"/>
								 &nbsp;&nbsp;{{item.applicant.s_name}}
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-4 col-sm-6">
                            <div class="matches-image-con">
                                <div class="images">
                                    <img src="assets/images/browse_9.png" *ngIf="item.occupation.image == null"/>
								<img src="{{APP_URI + 'uploads/occupations/' + item.occupation.image}}" *ngIf="item.occupation.image != null"/>
                                  
                                </div>
                                <div class="content">
                                      <h3>{{item.applicant.s_name}}</h3>
                                    <p>{{item.occupation.name}}</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-3">
                            <div class="matches-button">
                                <div class="btn-box ">
                                <div class="row">
                                    <div class="offset-xl-2 col-md-12 col-lg-12 col-xl-10">
                                        <!-- <button class="btn purple mb-2 mb-sm-0" (click)="markAsIntrested(item.id)">{{'Weitere Informationen' | translate}}</button> -->
										 <button class="btn purple" routerLink="/matches">{{'additional Information' | translate}}</button>
                                    </div>
                                    <!-- <div class="col-sm-6">
                                        <button class="btn brown" (click)="markAsHide(item.id)"><img
                                                src="/assets/images/close.png">{{'Hide' | translate}}</button>
                                    </div> -->
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="new-matches"  *ngIf="dashboardData">
            <div class="dashboard-box dash-bx-pad" *ngIf="dashboardData.chats && dashboardData.chats.length > 0 ; else nonewchat">
                <!-- <h3 class="dash-heading">{{ 'Neue Nachrichten' | translate}}</h3> -->
                <div class="job-status-head flex-box pb-3">
                    <h3 class="dash-heading">{{'New Messages' | translate}}</h3>
                    <div class="notification-box ">
                        <div class="theme-btn brown" *ngIf="dashboardData.new_chats.length > 0">
                            <button type="btn" class="btn mt-0" routerLink="/chat">{{'View all messages' | translate}}</button>
                        </div>
                    </div> 
                </div>
                <div class="row"  *ngFor="let item of dashboardData.chats">
                    <div class="col-sm-2">
                        <div class="matches-image">
                            <img src="assets/images/user_blank_image.png" *ngIf="item.from_user.image == null"/>
							<img src="{{APP_URI + 'uploads/users/' + item.from_user.image}}" *ngIf="item.from_user.image != null"/>
							 &nbsp; {{item.from_user.first_name}}   
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="chat-box">
                            <div class="matches-image-con">
                                <div class="images">
                                    <img src="/assets/images/msg-black.png" alt="msg-black">
                                </div>
                                <div class="content">
                                      <p>{{item.message}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="chat-box">

                            <form>
                                <div class="content">
                                    <p>{{"Input to Answer" | translate}}...</p>
                                </div>
                                <div class="input-answer">
                                    <input class="input-field-in">

                                    <button type="submit" class="btn send"><img
                                            src="/assets/images/side_3.png" alt="side3"></button>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="chat-and-count">
            <div class="col-12 p-0">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                            <!-- <div class="col-sm-6">
                                <div class="box graph graph_1 color-1 dashboard-box graph-pd">
                                    <div class="box-con flex-box">
                                        <div class="box-inn">
                                            <h3 *ngIf="dashboardData && dashboardData.match_count > 0 ; else nocnt">{{dashboardData.match_count}}</h3>

                                        </div>
                                        <div class="img-box">
                                            <img src="/assets/images/bewerber_3.png">
                                        </div>
                                    </div>
                                    <div class="con">
                                        <p>{{'Matches' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="box graph graph_1 color-2 dashboard-box graph-pd">
                                    <div class="box-con flex-box">
                                        <div class="box-inn">
                                             <h3 *ngIf="dashboardData && dashboardData.interest_count > 0 ; else nocnt">{{dashboardData.interest_count}}</h3>


                                        </div>
                                        <div class="img-box">
                                            <img src="/assets/images/bewerber_3.png">
                                        </div>
                                    </div>
                                    <div class="con">
                                        <p>{{'Interested' | translate}}</p>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-sm-12">
                                <div class="box graph graph_1 color-3  dashboard-box graph-pd">
                                    <div class="box-con flex-box">
                                        <div class="box-inn">
                                             <h3 *ngIf="dashboardData && dashboardData.search_order > 0 ; else nocnt">{{dashboardData.search_order}}</h3>


                                        </div>
                                        <div class="img-box ">
                                            <img src="/assets/images/bewerber_3.png">
                                        </div>
                                    </div>
                                    <div class="con">
                                        <p>{{'Search Orders' | translate}}</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-12">
                                <div class="job-status dashboard-box graph-pd" *ngIf="dashboardData && dashboardData.graph && dashboardData.graph.length > 0">
                                    <div class="job-status-head flex-box">
                                        <h3 class="dash-heading">{{'New Matches' | translate}}</h3>
                                        <div class="toggles flex-box">
                                            <div class="form-check form-switch ">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" checked>
                                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                                    {{'Accepted' | translate}}</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" checked>
                                                <label class="form-check-label"
                                                    for="flexSwitchCheckChecked">Pending' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="month-dropdown flex-box">
                                       
                                        <div class="select-month">
                                            <select class="form-select" aria-label="Default select example">
                                                 
                                       <option selected value="1">{{'This Month' | translate}}</option>
                                                <option value="2">{{'This Week' | translate}}</option>
                                                <option value="3">{{'This Year' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="grph-image">
                                        <img src="/assets/images/graph.png">
                                    </div>
                                    <div class="points">
                                        <p class="one">{{'Accepted' | translate}}</p>
                                        
                                        <p class="three">{{'Pending' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                              <!-- <div class="col-12" *ngIf="dashboardData && dashboardData.new_chats && dashboardData.new_chats.length > 0">
                                <div class="chats dashboard-box graph-pd" *ngIf="dashboardData.new_chats.length > 0">
                                    <div class="job-status-head flex-box">
                                        <h3 class="dash-heading">{{'Neue Nachrichten' | translate}}</h3>
                                        <div class="notification-box ">
                                            <div class="theme-btn brown" *ngIf="dashboardData.new_chats.length > 0">
                                                <button type="btn" class="btn mt-0" routerLink="/chat">{{'Alle Nachrichten anzeigen' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chats-box-top"  *ngFor="let item of dashboardData.new_chats">
                                        <div class="box chat-box">
                                            <div class="user-head flex-box">
                                                <div class="user">
                                                    <div class="image">
                                                        <div class="image-inner">
														
														 <img src="assets/images/user_blank_image.png" *ngIf="item.from_user.image == null"/>
								<img src="{{APP_URI + 'uploads/users/' + item.from_user.image}}" *ngIf="item.from_user.image != null"/>
								  
                                                             
                                                        </div>
                                                        <div class="activity" *ngIf="item.is_online">

                                                        </div>
                                                    </div>
                                                    <div class="user-name">
                                                        <p>{{item.from_user.first_name}}</p>
                                                          <h3>{{item.from_user.address}}</h3>
                                                    </div>
                                                </div>
                                                <div class="time">
                                                    <p>{{item.created_at | date : 'shortTime' }}</p>
                                                </div>
                                            </div>
                                            <div class="user-masg">
                                                <div class="msg">
                                                    <form>
                                                        <div class="content">
                                                            <p>{{ item.message }}</p>
                                                        </div>
                                                        <div class="input-answer">     
                                                            <button type="button" (click)="startChat(item.from_user_id)" class="btn send"><img src="/assets/images/side_3.png" alt="side3"></button>                   
                                                          
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
								 
								
								</div>
                            </div>
                       -->
						</div>
                    </div>
                     		<div class="col-lg-4">
<!-- 					
                        <div class="dashboard-box graph-pd" *ngIf="dashboardData && dashboardData.notifications && dashboardData.notifications.length > 0; else nonotfi">
						
                            <h3 class="dash-heading">{{'Recent Notifications' | translate}}</h3>
 
                            <div class="notification-box ">
                                <div class="box " *ngFor="let item of dashboardData.notifications">
                                    <div class="matches-image-con">
                                        <div class="images color_1">
                                            <img src="/assets/images/Eye.png" alt="bnrows_9">
                                        </div>
                                        <div class="content" >
                                            <p [innerHtml]="item.msg"> 
                                            </p>
                                            
											<h5 *ngIf="CheckDate(item.created_at)">{{ item.created_at | date : 'shortTime' }}</h5>
											<h5   *ngIf="CheckDate(item.created_at) == false">{{ item.created_at | date : 'shortDate' }}</h5>
										
											
											
                                        </div>
                                    </div>
                                </div>
 
                            </div>
                        </div> -->
						
                    </div>
               
                </div>
            </div>
        </section>
    </div>
</div>
<!-- min page box  end-->
</main>