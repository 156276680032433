<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
 <div class="head">
                            <!-- <p>Optional</p> -->
                        </div>
						<div class="heading-top line-heading pb-3">
							<h4>{{ 'Would you like to provide more information about your job?' | translate }}
							</h4>
						</div>
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <textarea
                                            [ngClass]="(submitted && ((errors && errors.location)  || af.location.errors )) ? 'form-control is-invalid' : 'form-control'"
                                            formControlName="location" placeholder="{{ 'Remarks' | translate }}"></textarea>

                                        <div *ngIf="submitted && errors && errors.location" class="invalid-feedback">
                                            {{errors.location[0]}}</div>

                                    </div>
                                </div>
								
								 
									
								 
                               
                                 <div class="col-sm-12">
                                    <div class="theme-btn">
                                        <button class="back-btn" (click)="goBack()">{{ 'Return' | translate }}</button>&nbsp;&nbsp;<button
                                            type="submit" class="btn">{{ 'Save on computer' | translate }}</button>
                                    </div>
                                </div>
                            </div>


                        </form>