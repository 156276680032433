import { Component, OnInit } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { DomSanitizer } from '@angular/platform-browser'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service'; 
import { CookieService  } from 'ngx-cookie-service'; 
import {TranslateService} from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-ehome',
  templateUrl: './ehome.component.html',
  styleUrls: ['./ehome.component.scss']
})
export class EmployerHomeComponent implements OnInit {
	user: SocialUser;
	loggedIn: boolean;
	submitted = 0;
	step = 0;
	loading = true;
	step1Data:any;  
	errors:any;  
	homeContent:any;
	blocks = [];
	applicantForm :FormGroup;
	applicantForm1 :FormGroup;
	employerForm :FormGroup;
	employerForm1 :FormGroup;
	EmployerSignup 	 = true;
	checkCookie:any;
     
	constructor(
		private sanitizer: DomSanitizer,
		private authService: SocialAuthService,   
		private mainService: MainService,  
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private router: Router, private confirmationDialogService: ConfirmationDialogService,private cookies: CookieService,private translate: TranslateService) { 
		if(localStorage.getItem('authUser')){
			this.router.navigate(['/dashboard']);
		}else {
			this.mainService.employer_home().subscribe(response => { this.loading = false;this.setHomeContent(response);}, (error) => { this.loading = false;}); 
		}
	}
	showPopup(slug){
	  this.loading=true;
	  let that = this;
	   this.mainService.getCmsContent(slug).subscribe(res => {
		   
		   that.confirmationDialogService.confirm(res.data.title, res.data.body,'Close','','lg');
			this.loading=false;  
    });
	return false;
	  
  }
	ngOnInit(): void {
		this.checkCookie = this.cookies.get('accept_cookie');
		
		//homeContent
		/*
		bypassSecurityTrustHtml
		bypassSecurityTrustScript
		bypassSecurityTrustStyle
		bypassSecurityTrustUrl
		bypassSecurityTrustResourceUrl
		*/
		if(localStorage.getItem('homeContent')){
			this.setHomeContent(JSON.parse(localStorage.getItem('homeContent')));
			this.loading = false;
		} 
		const body = document.getElementsByTagName('body')[0]; body.classList.add('color-theme');
		if( this.checkCookie){
		this.authService.authState.subscribe((user) => {
			if(user != null){
				this.loading = true;
				if(this.EmployerSignup){
					this.mainService.employer_social(user).subscribe( response => { 
						this.authService.signOut();
						this.loading = false;
						this.errors = false;
						this.submitted = 0;
						
						
						if(response.status == 'success'){
							if(response.data.icp == 0) {
								this.step		= 2;
								var d 			= response.data;
								d["cn"] 		= response.mst.countries;
								this.step1Data	= d;
								 
							} else { 
								var res = {};
								res['data'] 	= response.data;
								res['token'] 	= response.token;
								res['mst'] 		= response.mst;
								localStorage.setItem('authUser', JSON.stringify(res));
								this.router.navigate(['/dashboard']);
							}
						}
						else {
							this.errors = response.errors;
							if(response.msg){
								this.toastr.error(response.msg);  
							}
						}

					},
					(error) => {                              
						this.loading = false;
					}); 
				}
				else {
					this.mainService.applicant_social(user).subscribe( response => { 
						this.authService.signOut();
						this.loading 	= false;
						this.errors 	= false;
						this.submitted 	= 0;
						if(response.status == 'success'){
							if(response.data.icp == 0) {
								this.step		= 1;
								var d 			= response.data;
								d["cn"] 		= response.mst.countries;
								this.step1Data	= d;
								 
							} 
							else if(response.data.icp == 1){
								var res = {};
								res['data'] 	= response.data;
								res['token'] 	= response.token;
								res['mst'] 		= response.mst;
								localStorage.setItem('authUser', JSON.stringify(res));
								this.router.navigate(['/create-search-profile']);
							}
							else {
								var res = {};
								res['data'] 	= response.data;
								res['token'] 	= response.token;
								res['mst'] 		= response.mst;
								localStorage.setItem('authUser', JSON.stringify(res));
								this.router.navigate(['/dashboard']);
							}
						}
						else {
							this.errors = response.errors;
							if(response.msg){
								this.toastr.error(response.msg);  
							}
						}

					},
					(error) => {                              
						this.loading = false;
					}); 
				}
			}
		});
		}

		this.applicantForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			toc: ['', Validators.required],
		});

		this.applicantForm1 = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			toc: ['', Validators.required],
		});

		this.employerForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			toc: ['', Validators.required],
		});

		this.employerForm1 = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			toc: ['', Validators.required],
		});


	}


	changeEmployer(tab){
		if(tab == 1){
			this.EmployerSignup = true;
		}
		else {
			this.EmployerSignup = false;
		}
		 
	}  



	signInWithGoogle(): void {
		this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}

	signInWithFB(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}

	signOut(): void {
		this.authService.signOut();
	}
	refreshToken(): void {
		this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
	}

	get af() { return this.applicantForm.controls; }
	get af1() { return this.applicantForm1.controls; }
	get ef() { return this.employerForm.controls; }
	get ef1() { return this.employerForm1.controls; }
	ngOnDestroy() { if(document.getElementById('cust_css') !=null) document.getElementById('cust_css').remove();}
	accept_cookie(){
	  this.cookies.set('accept_cookie','1');
	  this.checkCookie = 1;
	  window.location.reload();
	  
	}
	setHomeContent(response){

		let home  =  this.sanitizer.bypassSecurityTrustHtml(response);
		let hmt = {};
		hmt['banner_content'] = this.sanitizer.bypassSecurityTrustHtml(response.banner_content);
		hmt['banner_image'] = response.banner_image;
		//   hmt['banner_image'] = this.sanitizer.bypassSecurityTrustUrl(response.banner_image);
		this.blocks = [];
		response.blocks.forEach(block => {
			if(this.checkCookie == 0 && (block.content.indexOf('www.youtube.com') !== -1 || block.content.indexOf('www.youtube-nocookie.com') !== -1) ){
				block.content = block.content.replace(/<iframe[^>]*>/g,'<div class="container"><div class="d-sm-flex align-items-center py-3"><label class="form-check-label pb-2 pb-sm-0">' + this.translate.instant('Youtube is deactivated!') + '</label><div class="flex-grow-1 text-left text-sm-right allow_action"><button   class="btn  btn-sm me-md-2 mr-2 accept_cookieh">' + this.translate.instant('Allow') + '</button><button  class="btn  btn-sm cookie-policyh"  >' + this.translate.instant('Cookie Policy') + '</button></div></div></div>').replace(/<\/iframe>/g,'');
				
				
				
				//block.content ='<div class="container"><div class="d-sm-flex align-items-center py-3"><label class="form-check-label pb-2 pb-sm-0">' + this.translate.instant('Youtube is deactivated!') + '</label><div class="flex-grow-1 text-left text-sm-right allow_action"><button   class="btn btn-sm me-md-2 mr-2 accept_cookie">' + this.translate.instant('Allow') + '</button><button  class="btn  btn-sm cookie-policy"  >' + this.translate.instant('Cookie Policy') + '</button></div></div></div>';
			}
			block.content = this.sanitizer.bypassSecurityTrustHtml(block.content)
			this.blocks.push(block);
		});
		if(document.getElementById('cust_css') != null){
			document.getElementById('cust_css').remove();
		}
		this.homeContent = hmt;
		if(response.css!=null){
			var css = document.createElement('style');
			css.type = 'text/css';
			css.id = 'cust_css';
			css.appendChild(document.createTextNode(response.css));
			document.getElementsByTagName("head")[0].appendChild(css);
		}
		//localStorage.setItem('homeContent', JSON.stringify(response));


	}

	applicant_registration(data,sub){
	  
		this.errors = false;
		this.submitted = 0;
		this.loading = true;
		 
		this.mainService.applicant_registration(data).subscribe(response=>{ 
			this.submitted = sub;
			this.loading = false;
			if(response.status == 'success'){
				this.errors = false;
				this.submitted = 0;
				this.toastr.success(response.msg);
				this.router.navigate(['/']);
				if(sub == 1) this.employerForm.reset();		
				if(sub == 2) this.applicantForm.reset();		
				if(sub == 3) this.applicantForm1.reset();		
				if(sub == 4) this.employerForm1.reset();	 		
				 
			}
			else { 
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
		   
		},
        (error) => {                               
		  this.loading = false;
        }); 
	}		
	employer_registration(data,sub){
	  
		this.errors = false;
		this.submitted = 0;
		this.loading = true;
		 
		this.mainService.employer_registration(data).subscribe(response=>{ 
			this.submitted = sub;
			this.loading = false;
			if(response.status == 'success'){
				this.errors = false;
				this.submitted = 0;
				this.toastr.success(response.msg);
				this.router.navigate(['/']);
				if(sub == 1) this.employerForm.reset();		
				if(sub == 2) this.applicantForm.reset();		
				if(sub == 3) this.applicantForm1.reset();		
				if(sub == 4) this.employerForm1.reset();		
				 
			}
			else { 
				this.errors=response.errors;
				if(response.msg){
					this.toastr.error(response.msg);  
				}
			}
		   
		},
        (error) => {                               
		  this.loading = false;
        }); 
	}		
	onSubmit(sub){
		this.submitted = sub;
		if(sub == 1){
			if(this.employerForm.invalid) return
			this.employer_registration(this.employerForm.value,sub);
		}
		if(sub == 2){
			if(this.applicantForm.invalid) return
			this.applicant_registration(this.applicantForm.value,sub);
		}
		if(sub == 3){
			if(this.applicantForm1.invalid) return
			this.applicant_registration(this.applicantForm1.value,sub);
		}
		
		if(sub == 4){
			if(this.employerForm1.invalid) return
			this.employer_registration(this.employerForm1.value,sub);
		}
	} 
  ngAfterViewInit() {
		 var that = this;
		$('body').on('click',".accept_cookie",function(){
				that.accept_cookie();
		});
		$('body').on('click',".cookie-policy",function(){
			 
				that.showPopup("cookie-policy");
		});
  }
}
