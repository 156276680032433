<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
 <div class="head">
                            <!-- <p>Optional</p> -->
                        </div>
						<div class="heading-top line-heading pb-3">
							<h4>{{ 'Would you like to provide more information about your qualifications or the desired job?' | translate }}
							</h4>
						</div>
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <textarea
                                            [ngClass]="(submitted && ((errors && errors.location)  || af.location.errors )) ? 'form-control is-invalid' : 'form-control'"
                                            formControlName="location" placeholder="{{ 'Remarks' | translate }}"></textarea>

                                        <div *ngIf="submitted && errors && errors.location" class="invalid-feedback">
                                            {{errors.location[0]}}</div>

                                    </div>
                                </div>
								
								 <div class="col-sm-12" formArrayName="files">
									<div class="row" *ngFor="let item of filesControll.controls; let fileIndex=index" [formGroupName]="fileIndex">
										<div class="col-sm-12" >
											<div class="form-group attech-box" >
												<div class="attach">
													<input type="text" formControlName="file" readonly='readonly'   class="form-control"
													 placeholder="{{ 'CV, certificates and references (PDF files only)' | translate }}">
													<div class="upload-box">
														<div class="theme-btn file m-0">
															<input   type="file" accept='application/pdf' [ngClass]="(submitted && fileError(fileIndex)) ? 'form-control is-invalid' : 'form-control'"  (change)="onFileChange($event,fileIndex)">
															<button type="button" class="btn ">{{ 'Add to' | translate }}</button>
															 
														</div>
													</div>
												</div>
										
												<div class="theme-btn add file m-0" >
													<button type="button" class="btn round" (click)="addSellingPoint()" *ngIf="fileIndex==0"><img src="/assets/images/plus-lg.svg" alt="plus-lg"></button>
													<button type="button" class="btn round" (click)="deleteSellingPoint(fileIndex)" *ngIf="fileIndex !=0"><img src="/assets/images/minus.svg" alt="plus-lg"></button>
												</div> 

												
											</div> 
											
											
											<div   *ngIf="submitted && fileError(fileIndex)" style="margin-top:-25px;">
													<input   type="hidden" class="is-invalid" >
													<div    class="invalid-feedback">{{"File name is required." | translate}}</div>
												</div>
												<div   *ngIf="submitted && errors && filedbError(fileIndex)" style="margin-top:-25px;">
													<input   type="hidden" class="is-invalid" >
													<div    class="invalid-feedback">{{filedbError(fileIndex)}}.</div>
												</div>
												 
											
										</div> 
										
									</div>
									<div class="note-block"><strong>{{ 'Note:' | translate }}</strong><span>
										 {{ 'The specification of additional information and the upload of a curriculum vitae, certificates, etc. are optional.' | translate }} {{ 'Please note, however, that your chances of being contacted by interesting companies are significantly higher with a complete candidate profile.' | translate }}
										</span></div>

								</div>
									
									
									
								 
                               
                                 <div class="col-sm-12">
                                    <div class="theme-btn">
                                        <button class="back-btn" (click)="goBack()">{{ 'Return' | translate }}</button>&nbsp;&nbsp;<button
                                            type="submit" class="btn">{{ 'Save on computer' | translate }}</button>
                                    </div>
                                </div>
                            </div>


                        </form>