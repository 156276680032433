<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 paddingSmall searchFlexGroup">
                    <div class="heading_new">{{'Matches' | translate}}</div>


                    <div class="searchOrderBtnGroup">

						  <button class="newThemeBtn"   *ngIf="(hideData && hideData > 0 && isShowHideMatch==0)"  (click)="showHideMatch()">
                            <i class="fa fa-plus-circle"></i>
                           {{'Show Hide Matches' | translate}}
                        </button>
						
                    </div>

                </div>

                <div class="">
				 <form [formGroup]="form"  >
                    <div class="filter_block form-block">
                            <div class="form-group" *ngIf="searchData && searchData.occupations">
							<select formControlName="occupation_id"  class="form-control custom-select" >
								<option value="" selected="selected" >{{ 'job' | translate }}</option>
								<option *ngFor="let option of searchData.occupations" value="{{option.id}}" >{{option.name}}</option>
							</select>
                   
                                
                            </div>

                            <div class="form-group" *ngIf="searchData && searchData.place_of_work">
							<select formControlName="place_of_work"  class="form-control custom-select" >
								<option value="" selected="selected" >{{ 'Workplace' | translate }}</option>
								<option *ngFor="let option of searchData.place_of_work" value="{{option.id}}" >{{option.place_of_work}}</option>
							</select>
							</div>
							<div class="form-group" *ngIf="searchData && searchData.radius_for_work">
							<select formControlName="radius_for_work"  class="form-control custom-select" >
								<option value="" selected="selected" >{{ 'distance' | translate }}</option>
								<option *ngFor="let option of searchData.radius_for_work" value="{{option.id}}" >{{option.name}}</option>
							</select>
                   
                                
                            </div>
							
                           	<div class="form-group" *ngIf="searchData && searchData.graduation">
							<select formControlName="graduation"  class="form-control custom-select" >
								<option value="" selected="selected" >{{ 'Highest school degree' | translate }}</option>
								<option *ngFor="let option of searchData.graduation" value="{{option.id}}" >{{option.name}}</option>
							</select>
                   
                                
                            </div>
							   	<div class="form-group" *ngIf="searchData && searchData.salary">
							<select formControlName="salary"  class="form-control custom-select" >
								<option value="" selected="selected" >{{ 'Pay grade' | translate }}</option>
								<option *ngFor="let option of searchData.salary" value="{{option.id}}" >{{option.name}}</option>
							</select>
                   
                                
                            </div>
							
                              	<div class="form-group" *ngIf="searchData && searchData.contract_type">
							<select formControlName="contract_type"  class="form-control custom-select" >
								<option value="" selected="selected" >{{ 'Contract type' | translate }}</option>
								<option *ngFor="let option of searchData.contract_type" value="{{option.id}}" >{{option.name}}</option>
							</select>
                   
                                
                            </div>
							
                           
 
                           
                            <div class="searchField form-group">
                                <input type="text"  formControlName="keyword"  placeholder="{{ 'Search here....' | translate }}"  />
                                <div class="searchFieldIcon"><i class="fal fa-search"></i></div>
                            </div>
                            <button class="ml-sm-4 newThemeBtn"  (click)="filterby()">
                                <i class="fas fa-filter"></i>
                                {{ 'Filter by' | translate }}
                            </button>
                            </div>
                </form>
				</div>
				
				<div class="modal themeModal  fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"> {{'View Notes' | translate}}</h5>
		<button type="button"    data-bs-dismiss="modal" aria-label="Close" class="btn-close"> × </button>
       
      </div>
      <div class="modal-body" style="max-height:350px;min-height:150px;overflow:auto;">
      	 <form [formGroup]="form1" (ngSubmit)="saveNote();" >
                     
                           
                            
							
                              	<div class="form-group" >
							<textarea formControlName="notes"  class="form-control" >
								 
							</textarea>
                   
                                
                            </div>
							
                           
 
                           
                           
                            <button class="ml-sm-4 newThemeBtn" data-bs-dismiss="modal" >
                               
                                 {{'Save' | translate}}
                            </button>
                             
                </form>
			
                     
      </div>
	   
       
    </div>
  </div>
</div>




                <div class="col-12 paddingSmall">
				<ng-template #noRecordFound><div class="condidate_Table table-responsive" ><table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </div></ng-template>
				<ng-template #cProfile><div class="search_order_img" style="background-image: url(assets/images/user_blank_image.png);" ><img src="assets/images/user_blank_image.png" alt="" /></div></ng-template>
                    <div class="search_order_profile_cardBox"  *ngIf="(stepData && stepData.length > 0) else noRecordFound">
					
					
					<ng-container *ngIf="( stepData |  filter:searchedKeyword) as result">
   <div class="condidate_Table table-responsive" *ngIf="result.length === 0" ><table class="table"><tr><td colspan="5" class="text-center">{{ 'No entry found!' | translate }}</td></tr></table> </div>
</ng-container>
					
					<ng-container *ngFor="let item of stepData  | filter:searchedKeyword " >
                        <div class="profile_box" *ngIf="(item.company_hide==0 ||  isShowHideMatch == 1)" >
						 
                            <div class="top_search_order top_search_line"   >
                                <div class="top_search_flex" *ngIf="userData.si == null">
									<div class="search_order_img" style="background-image: url(assets/images/user_blank_image.png);" *ngIf="item.applicant.image != null else cProfile">
										<img src="{{APP_URI + 'uploads/users/' + item.applicant.image}}" alt="" />
									</div>
                                    <div class="search_order_content" >
                                        {{item.applicant.s_name}} <span *ngIf="userData.si != null"><i class="fas fa-map-marker-alt"></i>{{item.search_profile.place_of_work}}</span>
                                    </div>  
                                </div>
								<div class="top_search_flex" *ngIf="userData.si != null">
									<div class="search_order_img" style="background-image: url(assets/images/user_blank_image.png);" *ngIf="item.fullapplicant.image != null else cProfile">
										<img src="{{APP_URI + 'uploads/users/' + item.fullapplicant.image}}" alt="" />
									</div>
									<div class="search_order_content">
                                        {{item.fullapplicant.name}}
                                        <span> <i class="fas fa-map-marker-alt"></i> {{item.search_profile.place_of_work}}</span>
                                    </div>
									 
							
                                </div>
								
								
								

                                <div class="condidate_btn_group d-flex align-items-center">
                                    <button class="newThemeBtn secondary_newThemeBtn"  *ngIf="item.company_interested == 1 && item.applicant_interested == 0 "><i class="fal fa-list-alt"></i>{{'Waiting for Applicant' | translate}}</button> 
									<button class="newThemeBtn secondary_newThemeBtn" (click)="markAsIntrested(item.id)" *ngIf="item.company_interested == 0"><i class="fal fa-list-alt"></i>{{'Interessiert' | translate}}</button>
<button class="newThemeBtn secondary_newThemeBtn" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="setNote(item.id,item.notes)" ><i class="fal fa-clipboard-list"></i>{{'View Notes' | translate}}</button>
     
                                    <button class="newThemeBtn secondary_newThemeBtn" (click)="markAsHide(item.id)" *ngIf="item.company_interested == 0 && item.company_hide == 0"><i class="far fa-ban"></i>{{'Hide' | translate}}</button>
									 <button class="newThemeBtn secondary_newThemeBtn" (click)="markAsUnHide(item.id)" *ngIf="item.company_hide == 1"><i class="far fa-ban"></i>{{'Unhide' | translate}}</button>
                                    <button class="newThemeBtn"  *ngIf="item.company_interested == 1 && item.applicant_interested == 1" (click)="startChat(item.applicant_id);"><i class="far fa-comments"></i>{{ 'Reject' | translate }}</button>
                                    <div class="followcheck progress-circle" attr.data-progress="{{item.match_per}}"></div>

                                </div>




<!-- Modal -->

                                
                            </div>
                            <div class="overall_tabs">
                            <ul class="nav nav-tabs" id="myTab{{item.id}}" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="home-tab{{item.id}}" data-bs-toggle="tab" attr.data-bs-target="#home{{item.id}}" type="button" role="tab"
                                   attr.aria-controls="home" attr.aria-selected="true">{{'Percentage' | translate}}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="profile-tab{{item.id}}" data-bs-toggle="tab" attr.data-bs-target="#profile{{item.id}}" type="button" 
                                  role="tab" attr.aria-controls="profile" attr.aria-selected="false">{{'Overall' | translate}}</button>
                                </li>
                            
                              </ul>
                              </div>
                              <div class="tab-content" id="myTabContent{{item.id}}">
                                <div class="tab-pane fade show active" id="home{{item.id}}" role="tabpanel" attr.aria-labelledby="home-tab{{item.id}}">

                                    <div class="top_search_body"  >
                                        <div id="accordion{{item.id}}">
                                            <div class="match_card">
                                                <div id="matchHeadOne{{item.id}}">
                                                    <a href="javascript:void(0);" class="match_collapse_head" data-bs-toggle="collapse" attr.data-bs-target="#matchCardOne{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardOne{{item.id}}">
                                                        <div class="match_coll_Label">{{ 'Education & experience' | translate }}</div>
                                                        <div class="new_progress_right">
                                                            <div class="progress">
                                                                <div  [ngClass]="(item.education_experience >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"   role="progressbar" style="width:  {{item.education_experience}}%" attr.aria-valuenow="{{item.education_experience}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="new_progress_value"> {{item.education_experience}}%</div>
                                                        </div>
        
                                                    </a>
                                                </div>
        
                                                <div id="matchCardOne{{item.id}}" class="collapse show" attr.aria-labelledby="matchHeadOne{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    <div class="match_card_body">
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Occupation' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.occupation_id >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.occupation_id}}%" attr.aria-valuenow=" {{item.occupation_id}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.occupation_id}}%</div>
                                                            </div>
                                                        </div>
														<div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Graduation' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.graduation >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.graduation}}%" attr.aria-valuenow=" {{item.graduation}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.graduation}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Education & qualification' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.education_qualification >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.education_qualification}}%" attr.aria-valuenow=" {{item.education_qualification}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.education_qualification}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Work Experience' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.work_experience >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'" role="progressbar" style="width: {{item.work_experience}}%" attr.aria-valuenow=" {{item.work_experience}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.work_experience}}%</div>
                                                            </div>
                                                        </div>
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ "driver's license" | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.driving_license >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.driving_license}}%" attr.aria-valuenow=" {{item.driving_license}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.driving_license}}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="match_card">
                                                <div id="matchHeadTwo{{item.id}}">
                                                    <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardTwo{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardTwo{{item.id}}">
                                                        <div class="match_coll_Label">{{ 'Job parameters' | translate }}</div>
                                                        <div class="new_progress_right">
                                                            <div class="progress">
                                                                <div [ngClass]="(item.job_parameters >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.job_parameters}}%" attr.aria-valuenow=" {{item.job_parameters}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="new_progress_value"> {{item.job_parameters}}%</div>
                                                        </div>
        
                                                    </a>
                                                </div>
        
                                                <div id="matchCardTwo{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadTwo{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    <div class="match_card_body">
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Contract type' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.contract_type >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.contract_type}}%" attr.aria-valuenow=" {{item.contract_type}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.contract_type}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'working time' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.working_hours >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.working_hours}}%" attr.aria-valuenow=" {{item.working_hours}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.working_hours}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'salary' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.salary >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.salary}}%" attr.aria-valuenow=" {{item.salary}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.salary}}%</div>
                                                            </div>
                                                        </div>
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'vacation' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.vacation >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.vacation}}%" attr.aria-valuenow=" {{item.vacation}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.vacation}}%</div>
                                                            </div>
                                                        </div>  
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Weekend work' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.weekend_work >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.weekend_work}}%" attr.aria-valuenow=" {{item.weekend_work}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.weekend_work}}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="match_card">
                                                <div id="matchHeadThree{{item.id}}">
                                                    <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardThree{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardThree{{item.id}}">
                                                    
                                                        <div class="match_coll_Label">{{ 'Place of work & assignment' | translate }}</div>
                                                        <div class="new_progress_right">
                                                            <div class="progress">
                                                                <div  [ngClass]="(item.place_of_work_assignment >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width:  {{item.place_of_work_assignment}}%" attr.aria-valuenow=" {{item.place_of_work_assignment}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div class="new_progress_value"> {{item.place_of_work_assignment}}%</div>
                                                        </div>
        
                                                    </a>
                                                </div>
        
                                                <div id="matchCardThree{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadThree{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    <div class="match_card_body">
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Place of work & area' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.place_of_work >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.place_of_work}}%" attr.aria-valuenow=" {{item.place_of_work}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.place_of_work}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'External assembly' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.work_on_site >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.work_on_site}}%" attr.aria-valuenow=" {{item.work_on_site}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.work_on_site}}%</div>
                                                            </div>
                                                        </div>
        
                                                        <div class="match_card_body_progress" *ngIf="item.area_on_site_work != -1">
                                                            <div class="match_coll_Label">{{ 'Outside assembly area' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div  [ngClass]="(item.area_on_site_work >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.area_on_site_work}}%" attr.aria-valuenow=" {{item.area_on_site_work}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.area_on_site_work}}%</div>
                                                            </div>
                                                        </div>
                                                        <div class="match_card_body_progress">
                                                            <div class="match_coll_Label">{{ 'Start of contract' | translate }}</div>
                                                            <div class="new_progress_right">
                                                                <div class="progress">
                                                                    <div [ngClass]="(item.begin_job >50) ? 'progress-bar progress-bar-striped bg-success' : 'progress-bar progress-bar-striped bg-warning'"  role="progressbar" style="width: {{item.begin_job}}%" attr.aria-valuenow=" {{item.begin_job}}%" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                                <div class="new_progress_value"> {{item.begin_job}}%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile{{item.id}}" role="tabpanel" attr.aria-labelledby="profile-tab{{item.id}}">

                                    <div>

                                        <div class="top_search_body"  >
                                            <div id="accordion{{item.id}}">
                                                <div class="match_card">
                                                    <div id="matchHeadOne{{item.id}}">
                                                        <a href="javascript:void(0);" class="match_collapse_head" data-bs-toggle="collapse" attr.data-bs-target="#matchCardOne{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardOne{{item.id}}">
                                                            <div class="match_coll_Label">{{ 'Education & experience' | translate }}</div>
                                                                     
                                                        </a>
                                                    </div>
            
                                                  <div id="matchCardOne{{item.id}}" class="collapse show" attr.aria-labelledby="matchHeadOne{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                    
                                                   <div class="theme-table theme-table-block theme-table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{{ 'Company' | translate }}</th>
                                                        <th class="">{{ 'Applicant' | translate }}</th>
                                                    </tr>
                                                </thead>
            
                                                <tbody>
<tr><td data-label=""><label class="badge-theme"> {{ 'Occupation' | translate }} : </label></td><td data-label="Company">
{{getVal1(item.search_order.occupation_id)}}
</td><td data-label="Applicant">{{getVal1(item.search_profile.occupation_id)}}</td></tr>

<tr><td data-label=""><label class="badge-theme"> {{ 'Graduation' | translate }} : </label></td><td data-label="Company">{{getVal('graduation','C',item.search_order.graduation)}}</td><td data-label="Applicant">{{getVal('graduation','A',item.search_profile.graduation)}}</td></tr>

<tr><td data-label=""><label class="badge-theme"> {{ 'Education & qualification' | translate }} : </label></td><td data-label="Company">{{getVal('education_qualification','C',item.search_order.education_qualification)}}</td><td data-label="Applicant">{{getVal('education_qualification','A',item.search_profile.education_qualification)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'work experience' | translate }} : </label></td><td data-label="Company">{{getVal('work_experience','C',item.search_order.work_experience)}}</td><td data-label="Applicant">{{getVal('work_experience','A',item.search_profile.work_experience)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ "driver's license" | translate }} : </label></td><td data-label="Company">{{getVal('driving_license','C',item.search_order.driving_license)}}</td><td data-label="Applicant">{{getVal('driving_license','A',item.search_profile.driving_license)}}</td></tr>             
                                                </tbody>
                                            </table>
                                        </div> 
                                                </div>         
												</div>
                                                <div class="match_card">
                                                    <div id="matchHeadTwo{{item.id}}">
                                                        <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardTwo{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardTwo{{item.id}}">
                                                            <div class="match_coll_Label">{{ 'Job parameters' | translate }}</div>
                                                    
            
                                                        </a>
                                                    </div>
            <div id="matchCardTwo{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadTwo{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                                     <div class="theme-table theme-table-block theme-table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{{ 'Company' | translate }}</th>
                                                        <th class="">{{ 'Applicant' | translate }}</th>
                                                    </tr>
                                                </thead>
            <tbody>
<tr><td data-label=""><label class="badge-theme"> {{ 'Contract type' | translate }} : </label></td><td data-label="Company">{{getVal('contract_type','C',item.search_order.contract_type)}}</td><td data-label="Applicant">{{getVal('contract_type','A',item.search_profile.contract_type)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'working time' | translate }} : </label></td><td data-label="Company">{{getVal('working_hours','C',item.search_order.working_hours)}}</td><td data-label="Applicant">{{getVal('working_hours','A',item.search_profile.working_hours)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'salary' | translate }} : </label></td><td data-label="Company">{{getVal('salary','C',item.search_order.salary)}}</td><td data-label="Applicant">{{getVal('salary','A',item.search_profile.salary)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'vacation' | translate }} : </label></td><td data-label="Company">{{getVal('vacation','C',item.search_order.vacation)}}</td><td data-label="Applicant">{{getVal('vacation','A',item.search_profile.vacation)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Weekend work' | translate }} : </label></td><td data-label="Company">{{getVal('weekend_work','C',item.search_order.weekend_work)}}</td><td data-label="Applicant">{{getVal('weekend_work','A',item.search_profile.weekend_work)}}</td></tr>
</tbody>
                                            </table>
                                        </div> 
                                                    </div>
                                                </div>
												<div class="match_card">
                                                    <div id="matchHeadTwoThree{{item.id}}">
                                                        <a href="javascript:void(0);" class="match_collapse_head collapsed" data-bs-toggle="collapse" attr.data-bs-target="#matchCardTwoThree{{item.id}}" aria-expanded="true" attr.aria-controls="matchCardTwoThree{{item.id}}">
                                                            <div class="match_coll_Label">{{ 'Place of work & assignment' | translate }}</div>
                                                        </a>
                                                    </div>
                                                    <div id="matchCardTwoThree{{item.id}}" class="collapse" attr.aria-labelledby="matchHeadTwoThree{{item.id}}" attr.data-parent="#accordion{{item.id}}">
                                                                     <div class="theme-table theme-table-block theme-table-responsive">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{{ 'Company' | translate }}</th>
                                                        <th class="">{{ 'Applicant' | translate }}</th>
                                                    </tr>
                                                </thead>
            
                                                <tbody>
<tr><td data-label=""><label class="badge-theme"> {{ 'Place of work & area' | translate }} : </label></td><td data-label="Company">{{item.search_order.place_of_work}}</td><td data-label="Applicant"><span *ngIf="userData.si == null">{{item.search_profile.place_of_work}} ({{item.search_profile.radius_for_work}}) KM</span></td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'External assembly' | translate }} : </label></td><td data-label="Company">{{getVal('work_on_site','C',item.search_order.work_on_site)}}</td><td data-label="Applicant">{{getVal('work_on_site','A',item.search_profile.work_on_site)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Outside assembly area' | translate }} : </label></td><td data-label="Company">{{getVal('area_on_site_work','C',item.search_order.area_on_site_work)}}</td><td data-label="Applicant">{{getVal('area_on_site_work','A',item.search_profile.area_on_site_work)}}</td></tr>
<tr><td data-label=""><label class="badge-theme"> {{ 'Start of contract' | translate }} : </label></td><td data-label="Company">{{getVal('begin_job','C',item.search_order.begin_job)}}</td><td data-label="Applicant">{{getVal('begin_job','A',item.search_profile.begin_job)}}</td></tr>
                                               
                                                </tbody>
                                            </table>
                                        </div> 
                                                  
                                                    </div>
                                                </div>
            
                                           
            
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                              </div>
                          
                        </div>
                      </ng-container>



                    </div>

                </div>


                <!-- ----- pagination  -->

                <div class="col-12 paddingSmall d-none">

                    <ul class="pagination">
                        <li class="page-item disabled">
                            <a class="page-link nameLink" href="javascript:void(0);" tabindex="-1">{{ 'First' | translate }}</a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link" href="javascript:void(0);" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li class="page-item active"><a class="page-link " href="javascript:void(0);">1</a></li>
                        <li class="page-item ">
                            <a class="page-link" href="javascript:void(0);">2 <span class="sr-only">{{ '(current)' | translate }}</span></a>
                        </li>
                        <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0);" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link nameLink" href="javascript:void(0);">{{ 'Last' | translate }}</a>
                        </li>
                    </ul>

                </div>



            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main>