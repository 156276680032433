
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as globalConstant from './global_constant'; 

@Injectable({
  providedIn: 'root'
})
export class MainService {
  apiDomain	=   globalConstant.DOMAIN;

  constructor(private http: HttpClient) {   
	
  }
   home(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.apiDomain + 'home' ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }    
  employer_home(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(this.apiDomain + 'employer_home' ,httpOptions).pipe(map(res=>{
      return res;
    }));
  } 
  employer_social(data){  
    return this.http.post<any>(this.apiDomain + 'employer_social' ,data).pipe(map(res=>{
      return res;
    }));
  } 
  social_login(data){  
    return this.http.post<any>(this.apiDomain + 'social_login' ,data).pipe(map(res=>{
      return res;
    }));
  }  
  applicant_social(data){  
    return this.http.post<any>(this.apiDomain + 'applicant_social' ,data).pipe(map(res=>{
      return res;
    }));
  } 
  employer_registration(data){  
    return this.http.post<any>(this.apiDomain + 'employer_registration',data).pipe(map(res=>{
      return res;
    }));
  }  
  applicant_registration(data){  
    return this.http.post<any>(this.apiDomain + 'applicant_registration',data).pipe(map(res=>{
      return res;
    }));
  }    
  
  change_password(token,data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'change_password' ,data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  forgot_password(data){  
    return this.http.post<any>(this.apiDomain + 'forgot_password',data).pipe(map(res=>{
      return res;
    }));
  }   
  reset_password(data){  
    return this.http.post<any>(this.apiDomain + 'reset_password',data).pipe(map(res=>{
      return res;
    }));
  }   
  employer_verification(data){  
    return this.http.post<any>(this.apiDomain + 'employer_verification',data).pipe(map(res=>{
      return res;
    }));
  }   
  applicant_verification(data){  
    return this.http.post<any>(this.apiDomain + 'applicant_verification',data).pipe(map(res=>{
      return res;
    }));
  }   
  employer_login(data){  
    return this.http.post<any>(this.apiDomain + 'employer_login',data).pipe(map(res=>{
      return res;
    }));
  }  
  applicant_login(data){  
    return this.http.post<any>(this.apiDomain + 'applicant_login',data).pipe(map(res=>{
      return res;
    }));
  }  
  applicant_complete_profile(data){  
    return this.http.post<any>(this.apiDomain + 'applicant_complete_profile' ,data).pipe(map(res=>{
      return res;
    }));
  }
  employer_complete_profile(data){  
    return this.http.post<any>(this.apiDomain + 'employer_complete_profile' ,data).pipe(map(res=>{
      return res;
    }));
  }
  
  create_search_order(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'create_search_order/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
 view_search_order(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'view_search_order/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  } 
	delete_search_order(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'delete_search_order/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  search_orders(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
		'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'search_orders' ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
   save_search_order(token,id,data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'save_search_order/' + id,data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
 
 
  
  
  
  create_search_profile(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'create_search_profile/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  
  view_search_profile(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'view_search_profile/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  delete_search_profile(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'delete_search_profile/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
 
search_profiles(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
		'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'search_profiles' ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  save_search_profile(token,id,data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'save_search_profile/' + id,data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
 

 getCmsContent(id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.apiDomain + 'get_cms_content/' + id ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  getFaqs(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(this.apiDomain + 'faqs' ,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
 
 save_contact(data){
    return this.http.post<any>(this.apiDomain + 'save_contact' ,data).pipe(map(res=>{
      return res;
    }));
  }  
  save_search_file(token,id,data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'save_search_profile/' + id,data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  callback(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'cbk',httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  company_profile(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'company_profile',httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  save_company_profile(token,data){
    const httpOptions = {
      headers: new HttpHeaders({
      //  'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'save_company_profile',data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }		
 
 
  interests(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'interests/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  matches(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'matches/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  search_matches(token,data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'search_matches' ,data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  save_notes(token,id,data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'save_notes/'+id ,data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  company_inserested(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'company_inserested/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
 
  hide_match(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'hide_match/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }  
  unhide_match(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'unhide_match/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
	change_search_profile_status(token, status, id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'change_search_profile_status/'+ status+'/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }	change_search_order_status(token,status, id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'change_search_order_status/'+ status+'/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  	accept_interest(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'applicant_interested/1/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  
  reject_interest(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'applicant_interested/2/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  buy_now(token,id,payment_method){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'buy_now/' + id+'/'+payment_method,httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  purchase_plan(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'purchase_plan/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }	   
  cancel_plan(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'cancel_plan/' + id,httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  my_profile(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'my_profile',httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  billing(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'billing',httpOptions).pipe(map(res=>{
      return res;
    }));
  }	  
  subscriptions(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.get<any>(this.apiDomain + 'subscriptions',httpOptions).pipe(map(res=>{
      return res;
    }));
  }	 

  view_invoice(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
		  'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      }),
	 
    };
    return this.http.get<any>(this.apiDomain + 'view_invoice/'+id,httpOptions).pipe(map(res=>{ return res;}));
  }
  dashboard(token){
    const httpOptions = {
      headers: new HttpHeaders({
		  'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      }),
	 
    };
    return this.http.get<any>(this.apiDomain + 'dashboard',httpOptions).pipe(map(res=>{ return res;}));
  }	
  read_notification(token,id){
    const httpOptions = {
      headers: new HttpHeaders({
		  'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      }),
	 
    };
    return this.http.get<any>(this.apiDomain + 'read_notification/'+ id,httpOptions).pipe(map(res=>{ return res;}));
  }	  
  save_my_profile(token,data){
    const httpOptions = {
      headers: new HttpHeaders({
      //  'Content-Type': 'application/json',
        'Authorization':'Bearer '+token
      })
    };
    return this.http.post<any>(this.apiDomain + 'save_my_profile',data,httpOptions).pipe(map(res=>{
      return res;
    }));
  }		
 
 
  
}
