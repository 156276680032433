
import { Component, OnInit} from '@angular/core';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import * as globalConstant from 'src/app/global_constant'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-interest',
  templateUrl: './interest.component.html',
  styleUrls: ['./interest.component.scss']
})

export class InterestComponent implements OnInit {
APP_URI = globalConstant.APP_URI;
	loading = false;
	submitted = false;
	pid:any;
	searchData:any;
	stepData:any;
	searchedKeyword: string;
	searched: string;
	constructor(private mainService: MainService,  private toastr: ToastrService,private router: Router, private confirmationDialogService: ConfirmationDialogService) { }
	downloadFile(f){		
		//return this.http.get(globalConstant.APP_URI + 'uploads/service_providers/' + f, { responseType: 'blob' });
		const a = document.createElement('a')
       // const objectUrl = window.URL.createObjectURL(globalConstant.APP_URI + 'uploads/service_providers/' + f)
        a.href = globalConstant.APP_URI + 'uploads/service_providers/' + f
        a.download = 'document.pdf';
        a.click();
      //  window.URL.revokeObjectURL(objectUrl);
   }
	 
	filterby() {
		this.searchedKeyword = this.searched ;
	}
	reject_interest(id){
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Wollen Sie wirklich ablehnen?','Ablehnen','Later')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.reject_interest(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.stepData	= response.data;
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
			
		
	}
	accept_interest(id){
		this.confirmationDialogService.confirm('Bitte bestätigen..', 'Willst du das wirklich akzeptieren?','Interessiert','Later')
			.then((confirmed) => { 
					 
					if(confirmed){
						let token =JSON.parse(localStorage.getItem('authUser')).token;
						let authData= JSON.parse(localStorage.getItem('authUser')).data;
						this.loading = true;
						this.mainService.accept_interest(token,id).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.stepData	= response.data;
								}
								else 
								{
									this.toastr.error(response.msg); 
								}
					   
							},
							(error) => {                              
								this.loading = false;
						
							   
							});
						
						
					}
				}
			);
		
	}
	tc(id){
		$('#accordion'+id).toggleClass('d-none');
		
	}
	getVal(item,forc,val) {
		if(forc=='C'){
			var optval = '';
			var doesnotmatter = this.searchData.c_static_features[item]["doesnotmatter"];
			if(this.searchData.c_static_features[item].type_of_feature ==7){					
				optval = val;
			}
			
			if(this.searchData.c_static_features[item].type_of_feature ==6){
					this.searchData.c_static_features[item].options.forEach(function(item1,index1){
						if(item1.id == val){
							optval = item1.name;
						}
					});
					if(val == -1){
						optval = doesnotmatter;
					}
				}
			if(this.searchData.c_static_features[item].type_of_feature ==5){
					this.searchData.c_static_features[item].options.forEach(function(item1,index1){
						if(val.length > 0 && val.indexOf('"'+item1.id + '"') >=0){
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
						
					});
					if(val.length > 0 && val.indexOf('"-1"') >=0){
						if(optval!=''){
							optval = optval + ', ' + doesnotmatter;
						}else{	
							optval = doesnotmatter;
						}
					}
				}
				
			
			
			return optval;
		}
		else {
			var optval = '';
			var doesnotmatter = this.searchData.a_static_features[item]["doesnotmatter"];
			if(this.searchData.a_static_features[item].type_of_feature ==7){					
				optval = val;
			}
			
			if(this.searchData.a_static_features[item].type_of_feature ==6){
					this.searchData.a_static_features[item].options.forEach(function(item1,index1){
						if(item1.id == val){
							optval = item1.name;
						}
					});
					if(val == -1){
						optval = doesnotmatter;
					}
				}
			if(this.searchData.a_static_features[item].type_of_feature ==5){
					this.searchData.a_static_features[item].options.forEach(function(item1,index1){
						if(val.length > 0 && val.indexOf('"'+item1.id + '"') >=0){
							if(optval!=''){
								optval = optval + ', ' + item1.name;
							}else{	
								optval = item1.name;
							}
						}
						
					});
					if(val.length > 0 && val.indexOf('"-1"') >=0){
						if(optval!=''){
							optval = optval + ', ' + doesnotmatter;
						}else{	
							optval = doesnotmatter;
						}
					}
				}
				
			
			
			return optval;
		}
	}
	
  
  
  ngOnInit(): void {
	   this.loading = true;
		let token =JSON.parse(localStorage.getItem('authUser')).token;
		const urlParams = new URLSearchParams(window.location.search);
		let id = urlParams.get('id');  
		if(id){
			this.pid = id;
		}
		else {
			this.pid = 0;
		}
		this.mainService.interests(token, this.pid).subscribe(
			response => { 
				this.loading = false;
				if(response.status == 'success'){	 
					this.stepData	= response.data;
					this.searchData	= response.searchData;
				}
				else 
				{
					this.toastr.error(response.msg); 
				}
       
			},
			(error) => {                              
			    
				this.loading = false;
			   
			});
			
  }

}
