import { Component, OnInit } from '@angular/core'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router , ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MainService } from 'src/app/main.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { CookieService  } from 'ngx-cookie-service'; 
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service'; 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
	EmployerSignup 	 = false;
	step = 0 ;
	apllicantForm :FormGroup;
	 
	user: SocialUser;
	loggedIn: boolean;
	submitted = false;
	loading = false;
	errors:any;
	stepData:any;
	checkCookie:any;
	constructor(private authService: SocialAuthService, private mainService: MainService, private formBuilder: FormBuilder,private toastr: ToastrService,private router: Router,private cookies: CookieService, private confirmationDialogService: ConfirmationDialogService) {}  
	signInWithGoogle(): void {	this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);	}
	signInWithFB(): void {	this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);	}
	signOut(): void {	this.authService.signOut();	}
	refreshToken(): void {	this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);	}
	get af() { return this.apllicantForm.controls; }
	  
	
	showPopup(slug){
	  this.loading=true;
	  let that = this;
	   this.mainService.getCmsContent(slug).subscribe(res => {
		   
		   that.confirmationDialogService.confirm(res.data.title, res.data.body,'Close','','lg');
			this.loading=false;  
    });
	return false;
	  
  }
	
	accept_cookie(){
	  this.cookies.set('socail','1');
	  this.checkCookie = 1;
	  window.location.reload();
	  
	}
	 

	ngOnInit(): void {
		 this.checkCookie = 0;
		 
		 
		 if(this.cookies.check('socail')){
			this.checkCookie =	this.cookies.get('socail');
		 }
		if(localStorage.getItem('authUser')){
			this.router.navigate(['/dashboard']);
		}
	  
		if( this.checkCookie == 1){
	   
			this.authService.authState.subscribe((user) => {
				if(user != null){
					this.loading = true;
					this.mainService.social_login(user).subscribe(
							response => { 
								this.loading = false;
								if(response.status == 'success'){
									this.signOut(); 
									if(response.data.icp == 0){
										this.step = 2 ;
										var d = response.data; d["cn"] = response.mst.countries;
										this.stepData 			= d;
									} 
									else 
									{
										var res = {};
										res['data'] 	= response.data;
										res['token'] 	= response.token;
										res['mst'] 		= response.mst;
										localStorage.setItem('authUser', JSON.stringify(res));
										this.router.navigate(['/dashboard']);
									}
								}
								else {
									this.errors = response.errors;
									if(response.msg){
										this.toastr.error(response.msg);  
									}
								}
						   
							},
							(error) => {                              
							   
							this.loading = false;
							   
							}); 
					 
				}
			});
		}
		 
		this.apllicantForm = this.formBuilder.group({
		  email: ['',Validators.required],
		  password : ['',Validators.required],
		 
		});
		 
		 
   
  }
  
 
  onSubmit(){
    if(this.apllicantForm.invalid){
	  this.submitted = true;
      return
     }
	 
	 
	 this.errors = false;
	 this.submitted = false;
	 this.loading = true;
	this.mainService.applicant_login(this.apllicantForm.value).subscribe(response=>{ 
		this.submitted = true;
		this.loading = false;
		if(response.status == 'success'){	  
			if(response.data.icp == 0){
				this.step = 1;
				var d = response.data;d["cn"] = response.mst.countries;
				this.stepData = d;
			}  
			 else {
				var res = {};
				res['data'] 	= response.data;
				res['token'] 	= response.token;
				res['mst'] 		= response.mst;
				localStorage.setItem('authUser', JSON.stringify(res));
				
				this.router.navigate(['/dashboard']);
			}
		}
		else {	 
			this.errors=response.errors;
			if(response.msg){
				this.toastr.error(response.msg);  
			}
		  }
       
    }
	,
        (error) => {                               
           
         
		  this.loading = false;
    
           
        }
	
	
	); 
   
  }
  
}
