<main class="dash-main">
    <!-- header setion start -->
    <app-admin-header></app-admin-header>
    <!-- header setion end -->
    <!-- side navbaar section start-->

    <app-sidebar></app-sidebar>
    <!-- side navbaar section end-->
    <!-- min page box  start-->
	<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
    <div class="main-page-box">
        <div class="container-fluid">
            <div class="row">

            

                <div class="col-12 paddingSmall searchFlexGroup mb-0">
                    <div class="heading_new mb-0">{{'Billing' | translate}}</div>

                    <div class="searchOrderBtnGroup">
                         <button class="newThemeBtn" *ngIf="userData.si == null" routerLink="/subscribe-plan">
                            <i class="fa fa-plus-circle"></i>

                           {{'Purchase New' | translate}}
                        </button>

                 
                  

                    </div>

                </div>

                <div class="col-12 paddingSmall">
				<ng-template #noRecordFound><div class="condidate_Table table-responsive" ><table class="table"><tr><td colspan="5" class="text-center"> {{ "Kein Eintrag gefunden!" | translate }}</td></tr></table> </div></ng-template>
                    <div class="condidate_Table table-responsive"  *ngIf="(stepData && stepData.length > 0) else noRecordFound" >
					
                        <table class="table">
                            <tr *ngFor="let item of stepData ">
                                <td>
                                    <div class="condidate_Prfile_name">

                                        <div class="condidate_name_block">
                                            {{item.plan_name}}
                                            <span>
                                                {{'subscriptions' | translate}}
                                            </span>
                                        </div>

                                    </div>
                                </td>
                                <td>
                                    <div class="condidate_Date_block">
                                       {{item.purchase_date |date}}
                                        <span>{{'Date' | translate}}</span>
                                    </div>
                                </td>

                                <td>
                                    <div class="condidate_Date_block validUpToDate">
                                       {{item.next_payment_date |date}}
                                        <span>{{'Valid upto' | translate}}</span>
                                    </div>
                                </td>

                                <td>
                                    <div class="condidate_Date_block">
                                        € {{item.amount}}
                                        <span>{{'Amount' | translate}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="condidate_interest">
                                        <span class="tags pending" *ngIf="item.status == 4">{{'Pending' | translate}}</span>
                                        <span class="tags success" *ngIf="item.status == 2">{{'Active' | translate}}</span>
                                        <span class="tags pending" *ngIf="item.status == 3">{{'Canceled' | translate}}</span>
                                        <span class="tags expire" *ngIf="item.status > 4">{{'Expired' | translate}}</span>
                                        <span>{{'Status' | translate}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="condidate_btn_group">
                                        <a href="javascript:void(0);" (click)="cancelPlan(item.id)"  *ngIf="item.status == 2" class="newThemeBtn newThemeBtnCancel">{{'Cancel' | translate}}</a>
                                        <a href="javascript:void(0);" (click)="view_invoice(item.id)" target="_blank" class="newThemeBtn"><i class="fa fa-download"></i></a>
                                    </div>

                                </td>
                            </tr>

 
                        </table>
                    </div>

                </div>
            </div>







        </div>
    </div>
    <!-- min page box  end-->
</main>