import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserList,UserRecentChat} from 'src/app/classes/user';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as globalConstant from '../global_constant'; 

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

 /* getUserList(){
    return this.http.get(this.url); 
  }*/
  user_list(users:UserList):Observable<any>{
   // return this.http.post('https://yjcns.stage02.obdemo.com/user-list',users); 
    return this.http.post(globalConstant.CHAT_URI +'/user-list',users); 
  }

  getRecentChats(recent_chat:UserRecentChat):Observable<any>{
    //return this.http.post("https://yjcns.stage02.obdemo.com/recent-chats",recent_chat);
    return this.http.post(globalConstant.CHAT_URI +"/recent-chats",recent_chat);
  }
  
  updateRoomStatus(recent_chat:any):Observable<any>{
    return this.http.post(globalConstant.CHAT_URI +"/update-room-status",recent_chat);
  }
}
