import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs/Observable';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
 /*  private url = 'http://yjcns.stage02.obdemo.com:6023';
  private socket; */

  constructor(private socket: Socket) {
	  
  }
  
  initSocket(__bool, message) {
	  
	
    //if (isPlatformBrowser(this.platformId)) {
      const $this = this;
      if (__bool == true) {
        this.socket.connect();
		console.log('manmohan',this.socket);
        this.socket.on("connect", function () {
          console.log('connected')
          $this.broadCastUserOnline(message);
        });
        this.socket.on("disconnect", function () {
          console.log('disconnected')
        });
      } else {
        this.socket.disconnect();
      }
    //}
  }

  sendMessage(data: any) {
	console.log(this.socket);
	
    this.socket.emit('message', data);
  }
  updateMessageCounter(data: any) {
    this.socket.emit('update_message_read', data);
  }
  userLogout(data: any) {
	  console.log("loout")
	  console.log(data)
    this.socket.emit('user_logout', data);
  }
  broadCastUserOnline(data: any) {
    this.socket.emit('broadcast_online_user', data);
  }
  getMessage() {
	  return Observable.create((observer) => {
          this.socket.on('message', (message) => {
			  //console.log(message);
              observer.next(message);
          });
      });
  }
}
