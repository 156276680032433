<div class="loader-wrapper" *ngIf="loading"><div class="loader"><img src="assets/images/logo-loader.png" alt=""/></div></div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
						
						<div class="radios-and-checks">
                            <div class="heading-top">
                                <h2>{{ 'Information about the job' | translate }}</h2>
                            </div>
							<div *ngFor="let option1 of special_features;">
								<div class="radios-and-checks-box" *ngIf="option1.type_of_feature ==1 || option1.type_of_feature ==2 || option1.type_of_feature ==3">
									<div class="heading">
										<h2>{{option1.name}}</h2>
									</div>
									<div class="row">
										<div class="col-12" >
											<ng-select2   [data]="option1.detail|splitComma" [width]="select2width" [options]="select2option1" formControlName="{{'feature' + option1.id}}"  ></ng-select2>
											<input type="hidden" [ngClass]="(submitted && ((errors && errors['feature' + option1.id])  || af['feature' + option1.id].errors )) ? 'is-invalid' : ''" />
											<div *ngIf="submitted && errors && errors['feature' + option1.id]" class="invalid-feedback">{{errors['feature' + option1.id][0]}}</div>
											<div *ngIf="submitted && af['feature' + option1.id].errors"  class="invalid-feedback">
												<div *ngIf="af['feature' + option1.id].errors.required">{{[option1.name]}} {{ 'is required.' | translate }}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="radios-and-checks-box" *ngIf="option1.type_of_feature ==4">
									<div class="heading">
									<h2>{{option1.name}}</h2>
									</div>
									<div class="row">
										<div class="col-12" >
											<ng-select2 [data]="option1.detail|splitComma" [width]="select2width" [options]="select2option2"  (valueChanged)="valueChanged($event, 'feature' + option1.id)" [value]="spf['feature' + option1.id]"   ></ng-select2>
											<input type="hidden" [ngClass]="(submitted && ((errors && errors['feature' + option1.id])  || af['feature' + option1.id].errors )) ? 'is-invalid' : ''" />
											<div *ngIf="submitted && errors && errors['feature' + option1.id]" class="invalid-feedback">{{errors['feature' + option1.id][0]}}</div>
											<div *ngIf="submitted && af['feature' + option1.id].errors"  class="invalid-feedback">
												<div *ngIf="af['feature' + option1.id].errors.required">{{[option1.name]}} {{ 'is required.' | translate }}</div>
											</div>
										</div>
									</div>
								</div>
								
								<div class="radios-and-checks-box" *ngIf="option1.type_of_feature ==5">
									<div class="heading">
									<h2>{{option1.name}}</h2>
									</div>
									<div class="row">
										<div class="col-6"  *ngFor="let opt of option1.detail|splitComma">
											<div class="form-check">
											<input type="checkbox" class="form-check-input" [checked]="isChecked(opt.id,'feature' + option1.id)" [value]="opt.id" id="{{'feature' + option1.id}}{{opt.id}}"  class="form-check-input" (change)="onCheckboxChange($event, 'feature' + option1.id)"  />
											<label class="form-check-label" for="{{'feature' + option1.id}}{{opt.id}}">{{opt.name}}</label>
											</div>
										</div>
										<div class="col-12" >
											<input type="hidden" [ngClass]="(submitted && ((errors && errors['feature' + option1.id])  || af['feature' + option1.id].errors )) ? 'is-invalid' : ''" />
											<div *ngIf="submitted && errors && errors['feature' + option1.id]" class="invalid-feedback">{{errors['feature' + option1.id][0]}}</div>
											<div *ngIf="submitted && af['feature' + option1.id].errors"  class="invalid-feedback">
												<div *ngIf="af['feature' + option1.id].errors.required">{{[option1.name]}} {{ 'is required.' | translate }}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="radios-and-checks-box" *ngIf="option1.type_of_feature ==6">
									<div class="heading">
									<h2>{{option1.name}}</h2>
									</div>
									<div class="row">
										<div class="col-6" *ngFor="let opt of option1.detail|splitComma" >
											<div class="custom-control custom-radio" >
											<input type="radio" [value]="opt.id" id="{{'feature' + option1.id}}{{opt.id}}" name="{{'feature' + option1.id}}" class="form-check-input" formControlName="{{'feature' + option1.id}}" />
											<label class="form-check-label" for="{{'feature' + option1.id}}{{opt.id}}">{{opt.name}}</label>
											</div>
										</div>
										<div class="col-12" >
											<input type="hidden" [ngClass]="(submitted && ((errors && errors['feature' + option1.id])  || af['feature' + option1.id].errors )) ? 'is-invalid' : ''" />
											<div *ngIf="submitted && errors && errors['feature' + option1.id]" class="invalid-feedback">{{errors['feature' + option1.id][0]}}</div>
											<div *ngIf="submitted && af['feature' + option1.id].errors"  class="invalid-feedback">
												<div *ngIf="af['feature' + option1.id].errors.required">{{[option1.name]}} {{ 'is required.' | translate }}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="radios-and-checks-box" *ngIf="option1.type_of_feature ==7">
									<div class="heading">
									<h2>{{option1.name}}</h2>
									</div>
									<div class="row">
										<div class="col-12" >
											<div class="form-group">
												<input type="text" [ngClass]="(submitted && ((errors && errors['feature' + option1.id])  || af['feature' + option1.id].errors )) ? 'form-control is-invalid' : 'form-control'" formControlName="{{'feature' + option1.id}}" />
												<div *ngIf="submitted && errors && errors['feature' + option1.id]" class="invalid-feedback">{{errors['feature' + option1.id][0]}}</div>
												<div *ngIf="submitted && af['feature' + option1.id].errors"  class="invalid-feedback">
													<div *ngIf="af['feature' + option1.id].errors.required">{{[option1.name]}} {{ 'is required.' | translate }}</div>
												</div>
											</div>
										</div>
									</div>
								</div>




						   </div>
									
									
                                    

							<div class="theme-btn">
							 
							<button class="back-btn" (click)="goBack()">{{ 'Return' | translate }}</button>&nbsp;&nbsp;<button type="submit" class="btn">{{ 'Further' | translate }}</button>
							</div>
									

                            
                        </div>
						
                           
                           
                            
                            
                        </form>